export default {
  CREATE_ORGANIZATION_APP: "CREATE_ORGANIZATION_APP",
  GET_EVENT_APPLICATIONS: "GET_EVENT_APPLICATIONS",
  GET_ALL_ORGANIZATION_APPS: "GET_ALL_ORGANIZATION_APPS",
  GET_ORGANIZATION_APPS: "GET_ORGANIZATION_APPS",
  GET_ORGANIZATION_APP: "GET_ORGANIZATION_APP",
  REFRESH_ORGANIZATION_APP: "REFRESH_ORGANIZATION_APP",
  SAVE_ORGANIZATION_APP: "SAVE_ORGANIZATION_APP",
  UPDATE_ORGANIZATION_APP: "UPDATE_ORGANIZATION_APP",
  DELETE_ORGANIZATION_APP: "DELETE_ORGANIZATION_APP",
  SAVE_APPLICATION_IMAGE: "SAVE_APPLICATION_IMAGE",
  DELETE_APPLICATION_IMAGE: "DELETE_APPLICATION_IMAGE"
};
