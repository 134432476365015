import types from "types/organizations";

export default (state = {}, action) => {
  switch (action.type) {
    case types.CREATE_ORGANIZATION:
      return action.payload;
    case types.GET_ORGANIZATION:
      return action.payload;
    case types.SAVE_ORGANIZATION:
      return action.payload;
    case types.REFRESH_ORGANIZATION:
      return action.payload;
    case types.UPDATE_ORGANIZATION:
      return action.payload;
    default:
      return state;
  }
};
