import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import withAuth from "components/hoc/with_auth";
import { getEvent, getEvents } from "actions/events";
import { getOrganizations, getOrganization } from "actions/organizations";
import EventReportList from "components/events/event/list/event_report_list";

export class EventReportsList extends Component {
  componentDidMount() {
    const fuzion_event_id = this.props.match.params.fuzion_event_id;
    this.props.getEvent(fuzion_event_id);
  }

  render() {
    return (
      <div className="container-fluid tabs-spacer">
        <div className="col-sm-9 col-sm-offset-3 col-md-10 col-md-offset-2 main">
          <h1 className="page-header">Event Reports</h1>
          <span className="header-sub-text">
            Event: {this.props.event.event_name}
          </span>
          <div className="body-content-partial">
            <EventReportList />
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = state => {
  return {
    permissions: state.activeUser.role_permissions,
    event: state.event,
    events: state.events,
    organizations: state.organizations
  };
};

export default connect(mapStateToProps, {
  getEvent,
  getEvents,
  getOrganizations,
  getOrganization
})(withAuth(withRouter(EventReportsList)));
