import React from "react";
import { Link } from "react-router-dom";

import backArrow from "assets/images/iconBackArrowLight.svg";

export default function MenuBackButton(props) {
  return (
    <Link to={props.to}>
      <img className="navbar-back-button" src={backArrow} alt="Back" />
    </Link>
  );
}
