import React, { Component } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { store } from "store";
import SideBar from "components/sidebar";
import Notifications from "lib/ui/notifications";
import Account from "components/account/account";
import Events from "components/events/events";
import MappingsIndex from "components/mappings/mappings_index";
import Apis from "components/apis/apis";
import Organizations from "components/organizations/organization";
import OrganizationAppKeyDetail from "components/organizations/organization/apps/keys/organization_app_key_detail";
import Admin from "components/admin/admin";
import AppCategoryDetails from "components/admin/app_categories/category_detail";
import RoleDetails from "components/admin/roles/role_detail";
import UserDetails from "components/admin/users/user_detail";
import Index from "components/";
import AppContainer from "app_container";
import Login from "components/login";
import ForgotPassword from "components/forgot_password";
import ErrorBoundary from "error_boundary";

class FuzionRouter extends Component {
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <>
            <SideBar />
            <ErrorBoundary>
              <Switch>
                <AppContainer>
                  <Route path="/login" component={Login} />
                  <Route path="/forgot-password" component={ForgotPassword} />
                  <Route path="/account" component={Account} />
                  <Route path="/events" component={Events} />
                  <Route path="/mappings" component={MappingsIndex} />
                  <Route path="/apis" component={Apis} />
                  <Route path="/organizations" component={Organizations} />
                  <Route
                    path="/organization/:fuzion_partner_id/apps/:fuzion_partner_app_id/organizations-app-key/:fuzion_partner_app_key_id"
                    component={OrganizationAppKeyDetail}
                  />
                  <Route
                    path="/admin-app-category/:fuzion_app_category_id/:tab?"
                    component={AppCategoryDetails}
                  />
                  <Route
                    path="/admin-app-role/:fuzion_security_role_id"
                    component={RoleDetails}
                  />
                  <Route
                    path="/admin-user-profile/:fuzion_user_profile_id"
                    component={UserDetails}
                  />
                  <Route path="/admin/:tab?" component={Admin} />
                  <Route path="/" exact component={Index} />
                </AppContainer>
              </Switch>
            </ErrorBoundary>
            <Notifications />
          </>
        </BrowserRouter>
      </Provider>
    );
  }
}
export default FuzionRouter;
