export default {
  CREATE_USER: "CREATE_USER",
  GET_USERS: "GET_USERS",
  GET_USERS_COUNT: "GET_USERS_COUNT",
  GET_USER: "GET_USER",
  SAVE_USER: "SAVE_USER",
  DELETE_USER: "DELETE_USER",
  UPDATE_USER: "UPDATE_USER",

  GET_ORGANIZATION_USERS: "GET_ORGANIZATION_USERS"
};
