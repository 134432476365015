import React, { useState } from "react";
import { Link } from "react-router-dom";
import logoQuant from "assets/images/logoFuzion.svg";
import ForgotPassword from "components/login/forgot_password";
import ForgotPasswordReset from "components/login/forgot_password_reset";

const FORM_STAGE = {
  first: "SEND_CODE",
  second: "RESET_PASSWORD"
};

export default function LoginPage() {
  const [formStage, setFormStage] = useState(FORM_STAGE.first);
  const [user, setUser] = useState();

  return (
    <div
      style={{
        position: "absolute",
        top: "5%",
        left: "50%",
        transform: "translate(-50%, 30%)",
        width: "400px",
        height: "515px"
      }}
    >
      <Link to={"/login"}>
        <img
          src={logoQuant}
          style={{ width: "400px", padding: "10px" }}
          alt="Quant Logo"
        />
      </Link>
      <div>
        {FORM_STAGE.first === formStage && (
          <ForgotPassword setFormStage={setFormStage} setUser={setUser} />
        )}
        {FORM_STAGE.second === formStage && (
          <ForgotPasswordReset setFormStage={setFormStage} user={user} />
        )}
      </div>
    </div>
  );
}
