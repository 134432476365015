import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "../../header/header";
import { getMapping } from "../../../actions/mappings";
import MappingEditForm from "./mapping_edit_form";
import "./mapping_details.css";

class MappingDetailsContainer extends Component {
  componentDidMount() {
    let { fuzion_mappings_id } = this.props.match.params;
    if (fuzion_mappings_id) {
      this.props.getMapping(fuzion_mappings_id);
    }
  }

  render() {
    return (
      <div className="container-fluid tabs-spacer">
        <Header detailView={true} to="/mappings" />
        <div className="mapping-details-container">
          {this.props.mapping && Object.keys(this.props.mapping).length > 0 && (
            <div>
              <MappingEditForm
                key={this.props.mapping.fuzion_mappings_id}
                operation="update"
                initialValues={this.props.mapping}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    sidebar: state.sidebar,
    mapping: state.mapping
  };
};

export default connect(mapStateToProps, {
  getMapping
})(MappingDetailsContainer);
