import React, { Component } from "react";
import { connect } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import withAuth from "components/hoc/with_auth";
import Header from "components/header/header";
import ApiSettings from "./api_settings";
import ApiRoutes from "./routes/api_category_routes";

class ApiCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: this.tabs.settings, //default tab
      detailView: true
    };
  }

  tabs = {
    settings: 0,
    routes: 1
  };

  componentDidMount() {
    const { tab } = this.props.match.params;

    if (tab) {
      this.setState({ index: this.tabs[tab] });
    }
  }

  render() {
    const tabListClass = `nav nav-tabs admin-tab-subheader ${this.props
      .sidebar && "sidebar-spacer"}`;
    return (
      <>
        <Header detailView={true} to="/apis" />
        <Tabs
          selectedIndex={this.state.index}
          onSelect={index => this.setState({ index: index })}
        >
          <TabList className={tabListClass}>
            <div className="tab-inner-container">
              <Tab className="tab">API SETTINGS</Tab>
              <Tab className="tab">API ROUTES</Tab>
            </div>
          </TabList>
          <TabPanel>
            <ApiSettings />
          </TabPanel>
          <TabPanel>
            <ApiRoutes />
          </TabPanel>
        </Tabs>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    sidebar: state.sidebar
  };
};

export default connect(mapStateToProps)(withAuth(ApiCategory));
