export default {
  CREATE_MAPPING: "CREATE_MAPPING",
  GET_MAPPINGS: "GET_MAPPINGS",
  GET_MAPPING: "GET_MAPPING",
  GET_MAPPINGS_COUNT: "GET_MAPPINGS_COUNT",
  SAVE_MAPPING: "SAVE_MAPPING",
  UPDATE_MAPPING: "UPDATE_MAPPING",
  DELETE_MAPPING: "DELETE_MAPPING",
  DISPLAY_MAPPING: "DISPLAY_MAPPING",
  VALIDATE_MAPPING: "VALIDATE_MAPPING"
};
