import React, { Component } from "react";
import { connect } from "react-redux";

import withAuth from "components/hoc/with_auth";
import Modal from "react-responsive-modal";
import {
  getApiRoute,
  getApiRoutes,
  getApiRoutesForCategory
} from "actions/api_routes";
import ApiRouteEditForm from "./api_route_edit_form";

class OrganizationAppEvents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      operation: "none",
      modalTitle: "none"
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    let { apiCategory, getApiRoute, getApiRoutesForCategory } = this.props;
    getApiRoutesForCategory(apiCategory.fuzion_api_category_id);
    getApiRoute(null);
  }

  onOpenModal = apiRoute => {
    this.props.getApiRoute(apiRoute);
    this.setState({
      open: true,
      operation: apiRoute === null ? "save" : "update",
      modalTitle: apiRoute === null ? "New Route" : "Edit Route"
    });
  };

  onCloseModal = () => {
    this.setState({ open: false });
    this.loadData();
  };

  renderApiRoute = apiRoute => {
    return (
      <div
        onClick={() => this.onOpenModal(apiRoute)}
        onKeyPress={e => {
          if (e.key === "Enter") {
            this.onOpenModal(apiRoute);
          }
        }}
        role="button"
        tabIndex={0}
        className="list-item-container"
        key={apiRoute.fuzion_api_route_id}
      >
        <div className="list-item-content">
          <div className="list-title">{apiRoute.api_route_name}</div>
          <div className="status-div">
            Last Updated: {apiRoute.last_mod_timestamp}
          </div>
        </div>
      </div>
    );
  };

  sortApiRoutes(apiRoutes) {
    return apiRoutes.sort((a, b) => {
      return a.api_route_name.toUpperCase() > b.api_route_name.toUpperCase();
    });
  }

  render() {
    let { permissions } = this.props;
    if (!permissions) return null;
    let canCreate =
      permissions.filter(p => p.permission_name === "API_CATEGORY_CREATE")
        .length > 0;
    const { apiCategory, apiRoutes } = this.props;

    if (!apiRoutes) {
      return <div>Loading Data...</div>;
    }

    const sortedApiRoutes = this.sortApiRoutes(apiRoutes);

    let activeItems = sortedApiRoutes
      .filter(k => k.status_flag === 1)
      .map(apiRoute => this.renderApiRoute(apiRoute));
    let inactiveItems = sortedApiRoutes
      .filter(k => k.status_flag === 0)
      .map(apiRoute => this.renderApiRoute(apiRoute));

    return (
      <div className="main-applications-container">
        <div className="container-fluid">
          <div className="col-sm-9 col-sm-offset-3 col-md-10 col-md-offset-2 main">
            <h1 className="page-header">API Routes</h1>
            <div className="body-content">
              <div>API: {apiCategory.api_cat_friendly_name}</div>
              {canCreate && (
                <button
                  onClick={() => this.onOpenModal(null)}
                  className="btn btn-primary body-content-btn"
                >
                  + ROUTE
                </button>
              )}
            </div>
            <div className="body-content-partial">
              <div className="list-sub-heading">
                {activeItems.length > 0 ? "Active" : ""}
              </div>
              {activeItems}
              <div className="list-sub-heading">
                {inactiveItems.length > 0 ? "Inactive" : ""}
              </div>
              {inactiveItems}
            </div>
          </div>
          <Modal
            open={this.state.open}
            onClose={this.onCloseModal}
            classNames={{ modal: "custom-modal" }}
            closeOnOverlayClick={false}
            showCloseIcon={false}
          >
            <ApiRouteEditForm
              title={this.state.modalTitle}
              onClose={this.onCloseModal}
              operation={this.state.operation}
              initialValues={this.props.apiRoute}
              apiCategory={this.props.apiCategory}
            />
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    permissions: state.activeUser.role_permissions,
    apiCategory: state.apiCategory,
    apiRoutes: state.apiRoutes
  };
};

export default connect(mapStateToProps, {
  getApiRoute,
  getApiRoutes,
  getApiRoutesForCategory
})(withAuth(OrganizationAppEvents));
