import { request } from "lib/data/api";
import types from "types/users";
import global from "types/global";

export const get_Users = users => ({
  type: types.GET_USERS,
  payload: users
});

export const get_User = user => ({
  type: types.GET_USER,
  payload: user
});

export const getUsers = () => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      let response = await request({
        method: "GET",
        state: state,
        route: "/v1/user-profiles/admin"
      });
      dispatch({
        type: types.GET_USERS,
        payload: response.payload
      });
      return response.payload;
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.users.getUsers failed"
      });
    }
  };
};

export const getUser = (
  fuzion_user_profile_id,
  fuzion_organization_id = ""
) => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      let response = await request({
        method: "GET",
        state: state,
        route: `/v1/user-profiles/${fuzion_user_profile_id}?fuzion_organization_id=${fuzion_organization_id}`
      });
      dispatch({
        type: types.GET_USER,
        payload: response.payload
      });
      return response.payload;
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.users.getUser failed"
      });
    }
  };
};

export const createUser = () => {
  return dispatch => {
    dispatch({
      type: types.CREATE_USER,
      payload: {}
    });
  };
};

export const getUsersCount = () => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "GET",
        state: state,
        route: "/user-profiles/count"
      });
      dispatch({
        type: types.GET_USERS_COUNT,
        payload: response.payload
      });
      return response.payload;
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.users.getUsersCount failed"
      });
    }
  };
};

export const saveUser = user => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "POST",
        state: state,
        route: "/v1/user-profiles",
        data: user
      });
      return dispatch({
        type: types.SAVE_USER,
        payload: response.payload
      });
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.users.saveUser failed"
      });
    }
  };
};

export const deleteUser = user => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "DELETE",
        state: state,
        route: `/v1/user-profiles/${user.fuzion_user_profile_id}`,
        data: user
      });
      return dispatch({
        type: types.DELETE_USER,
        payload: response.payload
      });
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.users.deleteUser failed"
      });
    }
  };
};

export const updateUser = user => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "PUT",
        state: state,
        route: `/v1/user-profiles/${user.fuzion_user_profile_id}`,
        data: user
      });
      dispatch({
        type: types.UPDATE_USER,
        payload: response.payload
      });
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.users.updateUser failed"
      });
    }
  };
};

export const getOrganizationUsers = fuzion_organization_id => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      let response = await request({
        method: "GET",
        state: state,
        route: `/v1/user-profiles/organization/${fuzion_organization_id}`
      });
      dispatch({
        type: types.GET_ORGANIZATION_USERS,
        payload: response.payload
      });
      return response.payload;
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.users.getOrganizationUsers failed"
      });
    }
  };
};
