import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import withAuth from "components/hoc/with_auth";
import { setNotification } from "actions/notifications";
import ConfirmationModal from "lib/ui/confirmationModal";
import { required, minLength3 } from "lib/validation/form_validation_rules";
import Select from "lib/ui/form/select_non_redux";
import {
  getApplicationKey,
  saveApplicationKey,
  updateApplicationKey,
  deleteApplicationKey,
  clearApplicationKey,
  getApplicationKeys,
  reloadApplicationKey
} from "actions/application_keys";
import { Loading } from "components/loading/loading";
import { imageAssets } from "fuzion-core-lib";
import formHelper from "lib/ui/form/form";
import renderClassNames from "lib/ui/render_class_names";

export class OrganizationAppKeyEditForm extends Component {
  constructor(props) {
    super(props);
    let { initialValues, operation, applicationKeys } = this.props;
    this.state = {
      updateFormValues: null,
      updateConfirmationOpen: false,
      deleteConfirmationOpen: false,
      fuzion_application_key_id: {
        key: "fuzion_application_key_id",
        value: (initialValues && initialValues.fuzion_application_key_id) || "",
        valid: true,
        errorMessage: "",
        validation: [required, minLength3]
      },
      fuzion_application_id: {
        key: "fuzion_application_id",
        value: (initialValues && initialValues.fuzion_application_id) || "",
        valid: true,
        errorMessage: ""
      },
      fuzion_application_secret_key: {
        key: "fuzion_application_secret_key",
        value:
          (initialValues && initialValues.fuzion_application_secret_key) || "",
        valid: true,
        errorMessage: ""
      },
      status_flag: {
        key: "status_flag",
        value:
          operation === "update"
            ? Object.keys(initialValues).length > 0 &&
              initialValues.status_flag.toString()
            : applicationKeys &&
              applicationKeys.filter(pak => pak.status_flag > 0).length < 2
            ? ""
            : "0",
        valid: true,
        errorMessage: "",
        validation: [required]
      }
    };
  }

  componentDidMount() {
    let { reloadApplicationKey, initialValues } = this.props;
    let {
      fuzion_partner_app_id,
      fuzion_partner_app_key_id
    } = this.props.match.params; // page works with refresh

    if (
      initialValues &&
      !Object.prototype.hasOwnProperty.call(
        initialValues,
        "fuzion_application_key_id"
      )
    ) {
      let params = {
        fuzion_application_id: fuzion_partner_app_id,
        fuzion_application_key_id: fuzion_partner_app_key_id
      };
      reloadApplicationKey(params);
    }
  }

  onSave = async () => {
    const {
      onClose,
      saveApplicationKey,
      setNotification,
      application
    } = this.props;
    const isFormValid = formHelper.isFormValid(this.state);
    if (!isFormValid.valid) {
      this.setState(isFormValid.formState);
      return;
    }
    let formValues = formHelper.getFormValues(this.state);
    let { fuzion_organization_app_id } = this.props.match.params;
    formValues.fuzion_application_id = fuzion_organization_app_id;
    formValues.status_flag = Number(formValues.status_flag);
    formValues.application = {
      fuzion_application_id: application.fuzion_application_id,
      status_flag: application.status_flag
    };
    delete formValues.fuzion_application_secret_key;
    formHelper.removeUnwantedValues(formValues);
    await saveApplicationKey(formValues);
    onClose();
    setNotification("good", "Added Application Key");
  };

  onUpdate = async () => {
    const { initialValues } = this.props;
    const isFormValid = formHelper.isFormValid(this.state);
    if (!isFormValid.valid) {
      this.setState(isFormValid.formState);
      return;
    }
    let formValues = formHelper.getFormValues(this.state);
    formValues.prior_status_flag = Number(initialValues.status_flag);
    formValues.status_flag = Number(formValues.status_flag);
    formValues.application = initialValues.application;
    this.setState({
      updateFormValues: formValues,
      updateConfirmationOpen: true
    });
    return;
  };

  continueUpdate = async () => {
    const { updateApplicationKey, setNotification, initialValues } = this.props;
    initialValues.status_flag = this.state.updateFormValues.status_flag;
    await updateApplicationKey(this.state.updateFormValues);
    setNotification("good", "Updated Organization App Key");
  };

  onDelete = async () => {
    let { deleteApplicationKey, initialValues } = this.props;
    await deleteApplicationKey(initialValues);
    this.props.history.push(
      `/organizations/${this.props.match.params.fuzion_partner_id}/app/${this.props.match.params.fuzion_partner_app_id}/keys`
    );
    this.props.setNotification("good", "Deleted Organization App Key");
  };

  onDownload = () => {
    let { initialValues, setNotification, application } = this.props;
    let fileDownload = require("js-file-download");
    const content = `Application,${application.application_name}\nFuzion API Key,${initialValues.fuzion_application_key_id}\nFuzion API Secret Key,${initialValues.fuzion_application_secret_key}`;
    fileDownload(content, "Fuzion_API_Key.csv");
    setNotification("good", "Application Key downloaded to Fuzion_API_Key.csv");
  };

  onChange = ({ target: { name, value } }) => {
    this.setState(formHelper.mergeNewState(this.state, name, { value }));
  };

  onBlur = e => {
    const {
      target: { name, value }
    } = e;
    const validation = formHelper.validate(this.state, name, value);
    this.setState(formHelper.mergeNewState(this.state, name, validation));
  };

  renderTitle() {
    return <h4 className="modal-title">{this.props.title}</h4>;
  }

  renderAvatar() {
    return (
      <div className="Organization-app-avatar-div">
        <img
          src={imageAssets.FUZION_AVATAR}
          className="organization-app-avatar-image"
          alt="Organization Avatar"
        />
      </div>
    );
  }

  renderOrganizationAppKey() {
    let { applicationKeys, initialValues } = this.props;
    const style = this.getStyle();
    //NOTE: only two application keys can be active at a time.
    let isDisabled = true;
    if (
      (initialValues && initialValues.status_flag === 1) ||
      applicationKeys.filter(pak => pak.status_flag > 0).length < 2
        ? true
        : false
    ) {
      isDisabled = false;
    }
    return (
      <div>
        {/* {this.renderAvatar()} */}
        <div className="form-group">
          <div className="field-label">Application Key (read-only)</div>
          <div>
            <input
              name={this.state.fuzion_application_key_id.key}
              type="text"
              value={this.state.fuzion_application_key_id.value}
              onChange={this.onChange}
              onBlur={this.onBlur}
              className={style.fuzionApplicationKeyIdErrStyle}
              placeholder="Name"
              disabled={true}
            />
          </div>
          {!this.state.fuzion_application_key_id.valid && (
            <div className="custom-text-danger">
              {this.state.fuzion_application_key_id.errorMessage}
            </div>
          )}
        </div>
        <div className="form-group">
          <div className="field-label">Status</div>
          <div>
            <Select
              name={this.state.status_flag.key}
              value={this.state.status_flag.value}
              onChange={this.onChange}
              onBlur={this.onBlur}
              disabled={isDisabled}
              options={{
                1: "Active",
                0: "Inactive"
              }}
              className={style.statusFlagErrStyle}
            ></Select>
          </div>
          {!this.state.status_flag.valid && (
            <div className="custom-text-danger">
              {this.state.status_flag.errorMessage}
            </div>
          )}
        </div>
      </div>
    );
  }

  renderSubmitButton(options) {
    let { permissions } = this.props;
    const onSubmit = this.isInsert() ? this.onSave : this.onUpdate;

    let canUpdate =
      permissions.filter(p => p.permission_name === "APP_KEY_UPDATE").length >
      0;
    if (canUpdate) {
      return (
        <button
          type="button"
          onClick={onSubmit}
          name="saveOrganizationAppButton"
          className={options.submitStyle}
        >
          {options.submitText}
        </button>
      );
    } else {
      return "";
    }
  }

  deleteCreatedKey = () => {
    let { onClose, clearApplicationKey } = this.props;
    clearApplicationKey({});
    onClose();
  };

  renderDeleteButton(options) {
    let { permissions } = this.props;
    let canDelete =
      permissions.filter(p => p.permission_name === "APP_KEY_DELETE").length >
      0;
    if (canDelete) {
      return (
        <button
          type="button"
          onClick={
            this.isInsert()
              ? this.deleteCreatedKey
              : () => this.setState({ deleteConfirmationOpen: true })
          }
          name="deleteOrganizationAppButton"
          className={options.otherStyle}
        >
          {options.deleteText}
        </button>
      );
    } else {
      return "";
    }
  }

  renderDownloadButton() {
    let { permissions } = this.props;
    let canDelete =
      permissions.filter(p => p.permission_name === "APP_KEY_DOWNLOAD").length >
      0;
    if (canDelete) {
      return (
        <button
          type="button"
          onClick={this.onDownload}
          name="downloadOrganizationAppKeyButton"
          className="btn-default-alt"
        >
          DOWNLOAD KEY
        </button>
      );
    } else {
      return "";
    }
  }

  renderButtons() {
    const style = this.getStyle();
    return (
      <div className="form-group">
        <div className={`formAddButtonContainer ${style.buttonsLeft}`}>
          {!this.isInsert() &&
            this.renderDownloadButton({
              otherStyle: style.otherStyle
            })}
          {this.renderDeleteButton({
            otherStyle: style.otherStyle,
            deleteText: style.deleteText
          })}
          {this.renderSubmitButton({
            submitStyle: style.submitStyle,
            submitText: style.submitText
          })}
        </div>
      </div>
    );
  }

  isInsert() {
    return this.props.operation === "save";
  }

  getStyle() {
    const isInsert = this.isInsert();
    const fuzionApplicationKeyIdErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !this.state.fuzion_application_key_id.valid
    });
    const statusFlagErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !this.state.status_flag.valid
    });
    return {
      otherStyle: isInsert ? "btn btn-default pull-right" : "btn btn-default",
      submitStyle: isInsert ? "btn btn-primary pull-right" : "btn btn-primary",
      buttonsLeft: isInsert ? "" : "buttonsLeft",
      submitText: isInsert ? "CREATE" : "SAVE",
      deleteText: isInsert ? "CANCEL" : "DELETE KEY",
      fuzionApplicationKeyIdErrStyle: fuzionApplicationKeyIdErrStyle,
      statusFlagErrStyle: statusFlagErrStyle
    };
  }

  render() {
    const { initialValues } = this.props;

    return (
      <>
        {this.props.title && this.renderTitle()}
        <div className="modal-form">
          {!this.isInsert() && initialValues && initialValues.length < 1 && (
            <div>
              <Loading />
            </div>
          )}
          <div>
            <form>
              {this.renderOrganizationAppKey()}
              {this.renderButtons()}
            </form>
          </div>
        </div>
        <ConfirmationModal
          open={this.state.updateConfirmationOpen}
          header="Save Application Key?"
          text="Changes have been made to the application key that will impact its behavior. Are you sure you want to save?"
          leftLabel="SAVE"
          onLeft={this.continueUpdate}
          rightLabel="CANCEL"
          onClose={() => this.setState({ updateConfirmationOpen: false })}
        />
        <ConfirmationModal
          open={this.state.deleteConfirmationOpen}
          header="Delete Application Key?"
          text="Deleting this key could limit the application's functionality. Are you sure you want to delete?"
          leftLabel="DELETE"
          onLeft={this.onDelete}
          rightLabel="CANCEL"
          onClose={() => this.setState({ deleteConfirmationOpen: false })}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    permissions: state.activeUser.role_permissions,
    organization: state.organization,
    applicationKeys: state.applicationKeys
  };
};

export default connect(mapStateToProps, {
  getApplicationKey,
  saveApplicationKey,
  updateApplicationKey,
  deleteApplicationKey,
  setNotification,
  clearApplicationKey,
  getApplicationKeys,
  reloadApplicationKey
})(withAuth(withRouter(OrganizationAppKeyEditForm)));
