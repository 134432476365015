import React, { useContext } from "react";
import { CognitoContext } from "components/cognito_provider";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import resolveJoiErrors from "lib/util/joi_error_resolver";

const formStage = {
  first: "PASSWORD",
  second: "FORCE_RESET",
  third: "COOKIE_NOTICE",
  fourth: "DATA_COLLECTION"
};

export default function NewPassword(props) {
  const { userAttributes, cognitoUser } = useContext(CognitoContext);

  // https://stackoverflow.com/questions/58767980/aws-cognito-password-regex
  const PASSWORD_REGEX = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$*.{}?\"!@#%&/,><':;|_~`^\\]\\[\\)\\(]).{8,}"
  );
  const schema = Joi.object({
    password: Joi.string()
      .pattern(PASSWORD_REGEX)
      .required()
  });
  const { register, handleSubmit, errors } = useForm({
    resolver: joiResolver(schema)
  });

  const onSubmit = ({ password }) => {
    cognitoUser.completeNewPasswordChallenge(password, userAttributes, {
      onSuccess: () => {
        props.setFormStage(formStage.third);
      }
      //   onFailure: (err) => {
      // console.error("onFailure:", err);
      //   },
    });
  };

  return (
    <div style={{ maxWidth: "400px", marginTop: "30px" }}>
      <h1 className="page-header">Please Enter New Password</h1>
      <p style={{ marginTop: "15px", marginBottom: "0px" }}>
        Your new password must contain at least{" "}
        <strong>
          one lower case character, one upper case character, one number, one
          special character
        </strong>{" "}
        and must be <strong>at least 8 characters</strong> in length.
      </p>
      <form onSubmit={handleSubmit(onSubmit)} style={{ paddingTop: "40px" }}>
        <div className="form-group">
          <div className="field-label">Password</div>
          <input
            placeholder="Enter Password"
            type="password"
            name="password"
            ref={register}
            className="form-control"
          />
          {errors.password && (
            <div className="text-danger">
              {resolveJoiErrors("Password", errors.password)}
            </div>
          )}
        </div>
        <div className="align-right">
          <button type="submit" className="btn btn-primary">
            Login
          </button>
        </div>
      </form>
    </div>
  );
}
