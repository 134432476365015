import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import renderClassNames from "./lib/ui/render_class_names";

class AppContainer extends Component {
  constructor(props) {
    super(props);
    this.mainContainerNode = React.createRef();
  }

  componentDidUpdate() {
    this.mainContainerNode.current.scrollTo(0, 0);
  }

  render() {
    const containerClass = renderClassNames({
      container: true,
      "container-open-sidebar": this.props.sidebar
    });
    return (
      <div className="main-container" ref={this.mainContainerNode}>
        <div className={containerClass}>{this.props.children}</div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    sidebar: state.sidebar
  };
};

export default connect(mapStateToProps)(withRouter(AppContainer));
