import React from "react";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });

    console.error("error_boundary.js:", error);
    console.info("error_boundary.js:", info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div
          style={{
            position: "absolute",
            top: "5%",
            left: "50%",
            transform: "translate(-50%, 30%)"
          }}
        >
          <h1>Something went wrong.</h1>{" "}
          <img
            src="https://media0.giphy.com/media/5xtDarmQmUC3Up5k0AU/200.gif"
            alt="something went wrong."
          />
        </div>
      );
    }
    return this.props.children;
  }
}
