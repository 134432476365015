import { request } from "lib/data/api";
import types from "types/event_applications";
import global from "types/global";

export const getEventApplication = fuzion_application_id => {
  let data = { fuzion_application_id: fuzion_application_id };
  return {
    type: types.GET_EVENT_APPLICATION,
    payload: data
  };
};

export const saveEventApplication = formValues => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "POST",
        state: state,
        route: "/v1/event-applications",
        data: formValues
      });
      return dispatch({
        type: types.SAVE_EVENT_APPLICATION,
        payload: response.payload
      });
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions,event_applications.saveEventApplication failed"
      });
    }
  };
};

export const deleteEventApplication = formValues => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "DELETE",
        state: state,
        route: "/v1/event-applications",
        data: formValues
      });
      return dispatch({
        type: types.DELETE_EVENT_APPLICATION,
        payload: response.payload
      });
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions,event_applications.deleteEventApplication failed"
      });
    }
  };
};
