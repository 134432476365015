import React, { Component } from "react";
import { connect } from "react-redux";

import { setNotification } from "actions/notifications";

import "./notifications.css";

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: { type: "", text: "" }
    };
  }

  componentDidUpdate() {
    if (this.props.notifications.text && this.props.notifications.type) {
      setTimeout(() => {
        this.props.setNotification(null, null);
      }, 5000);
    }
  }

  render() {
    return this.props.notifications.type && this.props.notifications.text ? (
      <div
        className={`fixed-bottom fade-in notification ${this.props.notifications.type}`}
      >
        <div className="notificationContainer">
          <p className="justify-content-center notificationText">
            {this.props.notifications.text}
          </p>
        </div>
      </div>
    ) : null;
  }
}

const mapStateToProps = state => {
  return {
    notifications: state.notifications
  };
};

export default connect(mapStateToProps, { setNotification })(Notifications);
