import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";

import withAuth from "components/hoc/with_auth";
import { getEvents, getEvent } from "actions/events";
import {
  getOrganizations,
  getOrganizationAppEventId
} from "actions/organizations";
import {
  getApplicationEvent,
  getApplicationEvents
} from "actions/application_events";
import OrganizationAppEventEditForm from "./organization_app_event_edit_form";
import { Loading } from "components/loading/loading";
import { getApplication } from "actions/applications";

import emptyState from "assets/images/graphicEmptyState.svg";

class OrganizationAppEvents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      operation: "none",
      modalTitle: "none"
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    let { getOrganizations, getEvents, getApplicationEvents } = this.props;
    let {
      fuzion_organization_app_id,
      fuzion_organization_id
    } = this.props.match.params;
    getApplication(fuzion_organization_app_id, fuzion_organization_id);
    getEvents();
    getOrganizations();
    getApplicationEvents(fuzion_organization_app_id);
  }

  onOpenModal = async applicationEvent => {
    if (applicationEvent) {
      await this.props.getEvent(applicationEvent.fuzion_event_id);
      this.setState({
        open: true,
        operation: "update",
        modalTitle: "Delete Application to Event"
      });
    } else {
      this.setState({
        open: true,
        operation: "save",
        modalTitle: "Create Application to Event"
      });
    }
  };

  onCloseModal = () => {
    this.setState({ open: false });
    this.loadData();
  };

  renderEmptyState(emptyMessage) {
    return (
      <div className="empty-state">
        <img
          className="empty-state-img"
          src={emptyState}
          alt="empty organization"
        />
        <div className="empty-state-text">{emptyMessage}</div>
      </div>
    );
  }

  renderLink(applicationEvent) {
    const { event_name } = applicationEvent;
    return (
      <div
        onClick={() => this.onOpenModal(applicationEvent)}
        className="list-item-container"
        key={applicationEvent.fuzion_event_id}
        onKeyPress={e => {
          if (e.key === "Enter") {
            this.onOpenModal(applicationEvent);
          }
        }}
        role="button"
        tabIndex={0}
      >
        <div className="list-item-content">
          <div className="list-title">{event_name}</div>
        </div>
      </div>
    );
  }

  renderApplicationEventsList = () => {
    const { applicationEvents } = this.props;
    if (applicationEvents.length < 1) {
      return this.renderEmptyState("Nothing in Application Events");
    }
    let activeItems = applicationEvents
      .filter(item => item.status_flag > 0)
      .map(item => this.renderLink(item));
    let inactiveItems = applicationEvents
      .filter(item => item.status_flag === 0)
      .map(item => this.renderLink(item));
    return (
      <div>
        <div className="list-sub-heading">
          {activeItems.length > 0 ? "Active" : ""}
        </div>
        {activeItems}
        <div className="list-sub-heading">
          {inactiveItems.length > 0 ? "Inactive" : ""}
        </div>
        {inactiveItems}
      </div>
    );
  };

  render() {
    let { permissions, application, applicationEvents } = this.props;
    if (!permissions) return null;
    let canCreate =
      permissions.filter(p => p.permission_name === "ORGANIZATION_APP_CREATE")
        .length > 0;
    if (!applicationEvents) {
      return (
        <div>
          <Loading />
        </div>
      );
    }
    return (
      <div className="main-applications-container">
        <div className="container-fluid tabs-spacer">
          <div className="col-sm-9 col-sm-offset-3 col-md-10 col-md-offset-2 main">
            <h1 className="page-header">Application Events</h1>
            <span className="header-sub-text">
              Application: {application.application_name}
            </span>
            <div className="body-content">
              {canCreate && (
                <button
                  onClick={() => this.onOpenModal(null)}
                  className="btn btn-primary body-content-btn"
                >
                  + APP EVENT
                </button>
              )}
            </div>
            <div className="body-content-partial">
              {this.renderApplicationEventsList()}
            </div>
          </div>
          <Modal
            open={this.state.open}
            onClose={this.onCloseModal}
            classNames={{ modal: "custom-modal" }}
            closeOnOverlayClick={false}
            showCloseIcon={false}
          >
            <OrganizationAppEventEditForm
              title={this.state.modalTitle}
              onClose={this.onCloseModal}
              operation={this.state.operation}
              application={this.props.application}
              event={this.props.event}
            />
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    permissions: state.activeUser.role_permissions,
    events: state.events, // All EVENTS
    organization: state.organization,
    organizations: state.organizations,
    application: state.application,
    applicationEvents: state.applicationEvents, // events associated with this application.
    event: state.event
  };
};

export default connect(mapStateToProps, {
  getEvent,
  getEvents,
  getOrganizations,
  getOrganizationAppEventId,
  getApplicationEvent,
  getApplicationEvents,
  getApplication
})(withAuth(OrganizationAppEvents));
