import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import TextArea from "lib/ui/form/text_area_non_redux";
import Select from "lib/ui/form/select_non_redux";
import ConfirmationModal from "lib/ui/confirmationModal";
import { setNotification } from "actions/notifications";
import { required, maxLength256 } from "lib/validation/form_validation_rules";
import {
  createEvent,
  getEvent,
  saveEvent,
  updateEvent,
  deleteEvent
} from "actions/events";
import { refreshOrganization, getOrganizations } from "actions/organizations";
import changes from "components/hoc/unsaved_changes";
import { Loading } from "components/loading/loading";
import formHelper from "lib/ui/form/form";
import renderClassNames from "lib/ui/render_class_names";
import Date from "lib/ui/form/date_non_redux";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

class EventEditForm extends Component {
  constructor(props) {
    super(props);
    let { initialValues, operation } = this.props;
    let initialStartDate =
      initialValues && initialValues.event_begin
        ? moment.utc(initialValues.event_begin)
        : null;
    let initialEndDate =
      initialValues && initialValues.event_end
        ? moment.utc(initialValues.event_end)
        : null;
    this.state = {
      appHasData: false, //GDJ - Add Testing for appHasData = No Delete
      updateConfirmationOpen: false,
      deleteConfirmationOpen: false,
      cantDeleteConfirmationOpen: false,
      updateFormValues: {},
      fuzion_event_id: {
        key: "fuzion_event_id",
        value: (initialValues && initialValues.fuzion_event_id) || "",
        valid: true,
        errorMessage: ""
      },
      event_name: {
        key: "event_name",
        value: (initialValues && initialValues.event_name) || "",
        valid: true,
        errorMessage: "",
        validation: [required]
      },
      fuzion_organization_id: {
        key: "fuzion_organization_id",
        value: (initialValues && initialValues.fuzion_organization_id) || "",
        valid: true,
        errorMessage: "",
        validation: [required]
      },
      freeman_job_id: {
        key: "freeman_job_id",
        value: (initialValues && initialValues.freeman_job_id) || "",
        valid: true,
        errorMessage: ""
      },
      event_description: {
        key: "event_description",
        value: (initialValues && initialValues.event_description) || "",
        valid: true,
        errorMessage: "",
        validation: [maxLength256]
      },
      event_begin: {
        key: "event_begin",
        value: initialStartDate,
        valid: true,
        errorMessage: "",
        validation: [required]
      },
      event_end: {
        key: "event_end",
        value: initialEndDate,
        valid: true,
        errorMessage: "",
        validation: [required]
      },
      location: {
        key: "location",
        value: (initialValues && initialValues.location) || "",
        valid: true,
        errorMessage: ""
      },
      timezone_offset: {
        key: "timezone_offset",
        value: (initialValues && initialValues.timezone_offset) || "",
        valid: true,
        errorMessage: ""
      },
      status_flag: {
        key: "status_flag",
        value:
          operation === "update" ? initialValues.status_flag.toString() : "1",
        valid: true,
        errorMessage: "",
        validation: [required]
      }
    };
  }

  componentDidMount() {
    let { getOrganizations } = this.props;
    getOrganizations();
  }

  getOrganizationOptions = () => {
    let options = {};
    this.props.organizations.forEach(organization => {
      let fuzionOrganizationId = organization.fuzion_organization_id.toUpperCase();
      options[fuzionOrganizationId] = organization.partner_name;
    });

    return options;
  };

  onChange = e => {
    const {
      target: { name, value }
    } = e;
    this.setState(formHelper.mergeNewState(this.state, name, { value }));
  };

  onBlur = e => {
    const {
      target: { name, value }
    } = e;
    const validation = formHelper.validate(this.state, name, value);
    this.setState(formHelper.mergeNewState(this.state, name, validation));
  };

  onDateChange = (name, value) => {
    this.setState(formHelper.mergeNewState(this.state, name, { value }));
  };

  onDateBlur = (name, value) => {
    const validation = formHelper.validate(this.state, name, value);
    this.setState(formHelper.mergeNewState(this.state, name, validation));
  };

  onSubmit = async () => {
    let {
      onClose,
      operation,
      saveEvent,
      initialValues,
      organizations,
      setNotification
    } = this.props;
    const isFormValid = formHelper.isFormValid(this.state);
    if (!isFormValid.valid) {
      this.setState(isFormValid.formState);
      return;
    }
    let formValues = formHelper.getFormValues(this.state);
    let currentOrg = organizations.filter(
      org => formValues.fuzion_organization_id === org.fuzion_organization_id
    );

    let dateStart = moment(formValues.event_begin).format("YYYY-MM-DD");
    formValues.event_begin = `${dateStart}`;
    let dateEnd = moment(formValues.event_end).format("YYYY-MM-DD");
    formValues.event_end = `${dateEnd}`;

    switch (operation) {
      case "insert":
        delete formValues.fuzion_event_id;
        formValues.status_flag = Number(formValues.status_flag);
        formValues.organization_name = currentOrg[0].partner_name;
        formHelper.removeUnwantedValues(formValues);
        await saveEvent(formValues);
        onClose();
        setNotification("good", "Event Created");
        break;
      case "update":
        formValues.prior_status_flag = Number(initialValues.status_flag);
        formValues.status_flag = Number(formValues.status_flag);
        formValues.organization_name = currentOrg[0].partner_name;
        this.setState({
          updateFormValues: formValues,
          updateConfirmationOpen: true
        });
        break;
      default:
        break;
    }
  };

  continueUpdate = async () => {
    let { setNotification, updateEvent } = this.props;
    const isFormValid = formHelper.isFormValid(this.state);
    if (!isFormValid.valid) {
      this.setState(isFormValid.formState);
      return;
    }
    await updateEvent(this.state.updateFormValues);
    setNotification("good", "Event Updated");
  };

  deleteEvent = async () => {
    let { history, deleteEvent, setNotification, initialValues } = this.props;
    await deleteEvent(initialValues);
    history.push("/events");
    setNotification("good", "Event Deleted");
  };

  getStyle = isInsert => {
    const eventNameErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !this.state.event_name.valid
    });
    const eventDescriptionErrStyle = renderClassNames({
      "form-control-textarea": true,
      "custom-danger-border": !this.state.event_description.valid
    });
    const fuzionEventIdErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !this.state.fuzion_event_id.valid
    });
    const freemanJobIdErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !this.state.freeman_job_id.valid
    });
    const fuzionOrganizationIdErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !this.state.fuzion_organization_id.valid
    });
    const eventStartDateErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !this.state.event_begin.valid
    });
    const eventEndDateErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !this.state.event_end.valid
    });
    const locationErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !this.state.location.valid
    });
    const timezoneOffsetErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !this.state.timezone_offset.valid
    });
    const statusFlagErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !this.state.status_flag.valid
    });
    return {
      otherStyle: isInsert ? "btn btn-default pull-right" : "btn btn-default",
      submitStyle: isInsert ? "btn btn-primary pull-right" : "btn btn-primary",
      submitText: isInsert ? "CREATE" : "SAVE",
      btnContainer: isInsert
        ? "formAddButtonContainer"
        : "formEditButtonContainer",
      requiredLabel: isInsert ? "required-label-jpom" : "required-label",
      eventNameErrStyle: eventNameErrStyle,
      fuzionEventIdErrStyle: fuzionEventIdErrStyle,
      freemanJobIdErrStyle: freemanJobIdErrStyle,
      fuzionOrganizationIdErrStyle: fuzionOrganizationIdErrStyle,
      statusFlagErrStyle: statusFlagErrStyle,
      eventDescriptionErrStyle: eventDescriptionErrStyle,
      eventStartDateErrStyle: eventStartDateErrStyle,
      eventEndDateErrStyle: eventEndDateErrStyle,
      locationErrStyle: locationErrStyle,
      timezoneOffsetErrStyle: timezoneOffsetErrStyle
    };
  };

  renderTitle() {
    return <h4 className="modal-title">Add Event</h4>;
  }

  renderSubmitButton(options) {
    let { permissions } = this.props;
    let canUpdate =
      permissions.filter(p => p.permission_name === "SHOW_UPDATE").length > 0;
    let submitText = options.isInsert ? "SAVE" : "UPDATE";
    if (canUpdate) {
      return (
        <button
          type="button"
          onClick={this.onSubmit}
          name="saveEventButton"
          className={options.submitStyle}
        >
          {submitText}
        </button>
      );
    } else {
      return "";
    }
  }

  renderCancelButton(options) {
    return (
      <button
        type="button"
        onClick={this.props.onSave}
        name="cancelEventButton"
        className={options.otherStyle}
      >
        CANCEL
      </button>
    );
  }

  renderDeleteButton(options) {
    const { appHasData } = this.state;
    const deleteConfirmState = appHasData
      ? { cantDeleteConfirmationOpen: true }
      : { deleteConfirmationOpen: true };
    let { permissions } = this.props;
    let canDelete =
      permissions.filter(p => p.permission_name === "SHOW_DELETE").length > 0;
    if (canDelete) {
      return (
        <button
          type="button"
          onClick={() => this.setState(deleteConfirmState)}
          name="deleteEventButton"
          className={options.otherStyle}
        >
          DELETE EVENT
        </button>
      );
    } else {
      return "";
    }
  }

  render() {
    const { initialValues, initialLength } = this.props;
    const isInsert = !initialValues;
    let style = this.getStyle(isInsert);
    return (
      <>
        {!isInsert && initialValues.length < 1 && (
          <div>
            <Loading />
          </div>
        )}
        <form className="modal-form">
          {isInsert && this.renderTitle()}
          <div className="form-group">
            <div className="field-label">Event Name *</div>
            <div>
              <input
                name={this.state.event_name.key}
                type="text"
                value={this.state.event_name.value}
                onChange={this.onChange}
                onBlur={this.onBlur}
                className={style.eventNameErrStyle}
                placeholder="Name"
              />
              {!this.state.event_name.valid && (
                <div className="custom-text-danger">
                  {this.state.event_name.errorMessage}
                </div>
              )}
            </div>
          </div>
          {!isInsert && (
            <div className="form-group">
              <div className="field-label">Event ID</div>
              <div>
                <input
                  name={this.state.fuzion_event_id.key}
                  type="text"
                  value={this.state.fuzion_event_id.value}
                  placeholder="Event Id"
                  disabled={true}
                  className={style.fuzionEventIdErrStyle}
                />
                {!this.state.fuzion_event_id.valid && (
                  <div className="custom-text-danger">
                    {this.state.fuzion_event_id.errorMessage}
                  </div>
                )}
              </div>
            </div>
          )}
          {!isInsert && (
            <div className="form-group">
              <div className="field-label">Freeman Job ID</div>
              <div>
                <input
                  name={this.state.freeman_job_id.key}
                  type="text"
                  value={this.state.freeman_job_id.value}
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                  className={style.freemanJobIdErrStyle}
                  placeholder="Name"
                />
                {!this.state.freeman_job_id.valid && (
                  <div className="custom-text-danger">
                    {this.state.freeman_job_id.errorMessage}
                  </div>
                )}
              </div>
            </div>
          )}
          <div className="form-group">
            <div className="field-label">Client Name *</div>
            <Select
              name={this.state.fuzion_organization_id.key}
              value={this.state.fuzion_organization_id.value}
              onChange={this.onChange}
              onBlur={this.onBlur}
              placeholder="Select a client..."
              options={this.getOrganizationOptions()}
              className={style.fuzionOrganizationIdErrStyle}
            ></Select>
            {!this.state.fuzion_organization_id.valid && (
              <div className="custom-text-danger">
                {this.state.fuzion_organization_id.errorMessage}
              </div>
            )}
          </div>
          <div className="form-group">
            <div className="field-label">Description (optional)</div>
            <div>
              <TextArea
                name={this.state.event_description.key}
                value={this.state.event_description.value}
                onChange={this.onChange}
                onBlur={this.onBlur}
                label="Description"
                rows={5}
                showLength={true}
                initialLength={initialLength}
                totalLength={256}
                className={style.eventDescriptionErrStyle}
              ></TextArea>
              {!this.state.event_description.valid && (
                <div className="custom-text-danger">
                  {this.state.event_description.errorMessage}
                </div>
              )}
            </div>
          </div>
          <div className="form-group">
            <div className="field-label">Start Date</div>
            <div>
              <Date
                name={this.state.event_begin.key}
                value={this.state.event_begin.value}
                label="Event Start Date"
                className={style.eventStartDateErrStyle}
                onChange={this.onDateChange}
                onBlur={this.onDateBlur}
                maxDate={this.state.event_end.value}
                normalize={value => moment(value).format("YYYY-MM-DD")}
                disabled={this.state.inputDisabled}
              />
              {!this.state.event_begin.valid && (
                <div className="custom-text-danger">
                  {this.state.event_begin.errorMessage}
                </div>
              )}
            </div>
          </div>
          <div className="form-group">
            <div className="field-label">End Date</div>
            <div>
              <Date
                name={this.state.event_end.key}
                value={this.state.event_end.value}
                label="Event End Date"
                className={style.eventEndDateErrStyle}
                onChange={this.onDateChange}
                onBlur={this.onDateBlur}
                minDate={this.state.event_begin.value}
                normalize={value => moment(value).format("YYYY-MM-DD")}
                disabled={this.state.inputDisabled}
              />
              {!this.state.event_end.valid && (
                <div className="custom-text-danger">
                  {this.state.event_end.errorMessage}
                </div>
              )}
            </div>
          </div>
          <div className="form-group">
            <div className="field-label">Location</div>
            <div>
              <input
                name={this.state.location.key}
                type="text"
                value={this.state.location.value}
                onChange={this.onChange}
                onBlur={this.onBlur}
                placeholder="Location"
                className={style.locationErrStyle}
              />
              {!this.state.location.valid && (
                <div className="custom-text-danger">
                  {this.state.location.errorMessage}
                </div>
              )}
            </div>
          </div>
          <div className="form-group">
            <div className="field-label">Time Zone</div>
            <div>
              <select
                name={this.state.timezone_offset.key}
                value={this.state.timezone_offset.value}
                onChange={this.onChange}
                onBlur={this.onBlur}
                className={style.timezoneOffsetErrStyle}
              >
                <option key="-1" value="" disabled>
                  Select an option
                </option>
                <option value="-11:00">
                  UTC-11:00 (Alaska, Tahiti,Pacific - Niue,Rarotonga,Marquesas
                </option>
                <option value="-09:30">UTC-09:30 Marquesas</option>
                <option value="-09:00">UTC-09:00 (Alaska Time, Gambier)</option>
                <option value="-08:00">
                  UTC-08:00 Pacific Standard Time: UTC-08:00
                </option>
                <option value="-07:00">
                  UTC-07:00 Mountain Standard Time: UTC-07:00
                </option>
                <option value="-06:00">
                  UTC-06:00 (Central Standard Time,Belize,Costa Rica,El
                  Salvador,Central Time,Guatemala,Managua,Galapagos)
                </option>
                <option value="-05:00">
                  UTC-05:00 (Eastern Standard Time,Bogota,Cayman,America
                  Cancun,Eastern Time,Jamaica)
                </option>
                <option value="-04:30">UTC-04:30 Caracas</option>
                <option value="-4:00">
                  UTC-4:00 (Barbados,Atlantic Time - Halifax,Port of Spain,Grand
                  Turk,Puerto Rico,Bermuda,Thule
                </option>
                <option value="-03:30">
                  UTC-3:30 Newfoundland Time - St. Johns
                </option>
                <option value="-03:00">
                  UTC-3:00
                  (Asuncion,Araguaina,Belem,Cayenne,Santiago,Rothera,Stanley)
                </option>
                <option value="-02:00">
                  UTC-02:00 (Sao Paulo,Noronha,South Georgia)
                </option>
                <option value="-01:00">
                  UTC-01:00 (Scoresbysund,Azores,Cape Verde)
                </option>
                <option value="+00:00">
                  UTC+00:00 (Abidjan,Casablanca,Canary
                  Islands,Dublin,Lisbon,London)
                </option>
                <option value="+01:00">
                  UTC+01:00 (Paris,Rome,Vienna,Zurich,Algiers,Amsterdam,Central
                  European Time - Belgrade/Prague,Berlin,Copenhagen)
                </option>
                <option value="+02:00">
                  UTC+02:00 (Windhoek,Johannesburg,Jerusalem,Athens,Istanbul:
                  UTC+02:00
                </option>
                <option value="+03:00">
                  UTC+03:00 (Khartoum,Nairobi,Baghdad,Qatar,Minsk,Riyadh)
                </option>
                <option value="+03:30">UTC+03:30 Tehran</option>
                <option value="+04:00">
                  UTC+04:00 (Baku,Dubai,Mauritius,Mahe)
                </option>
                <option value="+04:30">UTC+04:30 Kabul</option>
                <option value="+05:00">
                  UTC+05:00 (Mawson,Karachi,Maldives)
                </option>
                <option value="+05:30">
                  UTC+05:30 (India Standard Time,Colombo)
                </option>
                <option value="+05:45">UTC+05:45 Katmandu</option>
                <option value="+06:00">UTC+06:00 (Vostok,Dhaka,Chagos)</option>
                <option value="+06:30">UTC+06:30 (Rangoon,Cocos)</option>
                <option value="+07:00">
                  UTC+07:00 (Bangkok,Davis,Moscow-Krasnoyarsk: UTC+07:00
                </option>
                <option value="+08:00">
                  UTC+08:00 (Hong Kong,Kuala Lumpur,Macau,China
                  Time-Beijing,Singapore,Moscow-Irkutsk)
                </option>
                <option value="+08:30">UTC+08:30 (Pyongyang)</option>
                <option value="+09:00">
                  UTC+09:00 (Dili,Jayapura,Seoul,Tokyo,Palau,Moscow-Yakutsk)
                </option>
                <option value="+09:30">
                  UTC+09:30 (Central Time - Darwin)
                </option>
                <option value="+10:00">
                  UTC+10:00 (Dumont,Urville,Eastern Time - Brisbane,Moscow)
                </option>
                <option value="+10:30">
                  UTC+10:30 Central Time - Adelaide
                </option>
                <option value="+11:00">
                  UTC+11:00 (Eastern Time - Hobart,Eastern
                  Time-Melbourne,Eastern Time-Syndney
                </option>
                <option value="+12:00">
                  UTC+12:00 (Moscow,Majuro,Nauru,Wake,Walllis)
                </option>
                <option value="+13:00">
                  UTC+13:00 (Enderbury,Auckland,Fiji,Fakaofo,Tongatapu)
                </option>
                <option value="+14:00">UTC+14::00 (Apia,Kiritimati)</option>
              </select>
            </div>
          </div>
          <div className="form-group">
            <div className="field-label">Status</div>
            <div>
              <Select
                name={this.state.status_flag.key}
                value={this.state.status_flag.value}
                onChange={this.onChange}
                onBlur={this.onBlur}
                options={{
                  1: "Active",
                  0: "Inactive"
                }}
                className={style.statusFlagErrStyle}
              ></Select>
            </div>
            {!this.state.status_flag.valid && (
              <div className="custom-text-danger">
                {this.state.status_flag.errorMessage}
              </div>
            )}
          </div>
          <div className="form-group">
            <div className={style.btnContainer}>
              {isInsert &&
                this.renderCancelButton({
                  otherStyle: style.otherStyle
                })}
              {this.renderSubmitButton({
                isInsert: isInsert,
                submitStyle: style.submitStyle
              })}
              {!isInsert &&
                this.renderDeleteButton({
                  otherStyle: style.otherStyle
                })}
            </div>
            <div className={style.requiredLabel}>* Required</div>
          </div>

          <ConfirmationModal
            open={this.state.updateConfirmationOpen}
            header="Save Event?"
            text="Changes have been made to the event that will impact its behavior. Are you sure you want to save?"
            leftLabel="SAVE"
            onLeft={this.continueUpdate}
            rightLabel="CANCEL"
            onClose={() => this.setState({ updateConfirmationOpen: false })}
          />
          <ConfirmationModal
            open={this.state.cantDeleteConfirmationOpen}
            header="Can't Delete Event!"
            text="This event has data associated to it so it cannot be deleted."
            rightLabel="GOT IT"
            onClose={() => this.setState({ cantDeleteConfirmationOpen: false })}
          />
          <ConfirmationModal
            open={this.state.deleteConfirmationOpen}
            header="Delete Event?"
            text="If deleted, this event will no longer be accessible. Are you sure you want to delete?"
            leftLabel="DELETE"
            onLeft={this.deleteEvent}
            rightLabel="CANCEL"
            onClose={() => this.setState({ deleteConfirmationOpen: false })}
          />
        </form>
      </>
    );
  }
}

const mapStateToProps = state => {
  let values = {
    permissions: state.activeUser.role_permissions,
    organizations: state.organizations
  };
  return values;
};

export default connect(mapStateToProps, {
  createEvent,
  getEvent,
  saveEvent,
  updateEvent,
  refreshOrganization,
  deleteEvent,
  setNotification,
  getOrganizations
})(withRouter(changes(EventEditForm)));
