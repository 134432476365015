import React, { Component } from "react";
import { connect } from "react-redux";

import AdminUserProfileEditForm from "./organization_user_profile_edit_form";
import { setNotification } from "actions/notifications";
import { getUser } from "actions/users";
import { getOrganizationRoles } from "actions/roles";
import Header from "components/header/header";

class UserDetails extends Component {
  componentDidMount() {
    let {
      fuzion_user_profile_id,
      fuzion_organization_id
    } = this.props.match.params;
    this.props.getOrganizationRoles();
    if (fuzion_user_profile_id) {
      this.props.getUser(fuzion_user_profile_id, fuzion_organization_id);
    } else {
      this.props.createUser();
    }
  }

  onSave = () => {
    this.props.setNotification("good", "Save Successful");
  };

  render() {
    let { fuzion_organization_id } = this.props.match.params;
    return (
      <div className="user-details-page">
        <Header
          detailView={true}
          to={`/organizations/${fuzion_organization_id}/users`}
        />
        {Object.keys(this.props.user).length > 0 && (
          <AdminUserProfileEditForm
            key={this.props.user.fuzion_user_profile_id}
            onClose={this.onSave}
            initialValues={this.props.user}
            onSave={this.onSave}
            operation="update"
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  let values = {
    user: state.user
  };
  return values;
};

export default connect(mapStateToProps, {
  setNotification,
  getUser,
  getOrganizationRoles
})(UserDetails);
