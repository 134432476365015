// import Pool from "../../userPool";

import { CognitoUserPool } from "amazon-cognito-identity-js";

export default function Cognito() {
  const Pool = new CognitoUserPool({
    UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID
  });

  function login() {
    window.location = process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI;
  }

  function logout() {
    const user = Pool.getCurrentUser();
    if (user) {
      user.signOut();
      window.location =
        process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI + "/login";
    }
  }

  function haveUser() {
    const user = Pool.getCurrentUser();
    return user ? true : false;
  }

  async function getSession() {
    return await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      if (user) {
        user.getSession((err, session) => {
          if (err) {
            reject();
          } else {
            const currentTime = new Date();
            const isExpired =
              session.getIdToken().payload.exp > currentTime.getTime()
                ? true
                : false;
            let extUser = {
              source: "quant_ui",
              token: session.getIdToken().getJwtToken(),
              token_expiry: session.getIdToken().payload.exp,
              expired: isExpired,
              profile: {
                ImpersonatedUser: session.getIdToken().payload.email
              }
            };
            resolve(extUser);
          }
        });
      } else {
        console.log("cognito.js: we have no user");
        logout();
        reject("cognito.js:getSession:has no user in current user pool.");
      }
    });
  }

  return {
    getSession: getSession,
    login: login,
    logout: logout,
    haveUser: haveUser
  };
}
