import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import {
  required,
  minLength3,
  maxLength256
} from "lib/validation/form_validation_rules";
import { setNotification } from "actions/notifications";
import TextArea from "lib/ui/form/text_area_non_redux";
import Select from "lib/ui/form/select_non_redux";
import ConfirmationModal from "lib/ui/confirmationModal";
import changes from "components/hoc/unsaved_changes";
import {
  saveApiCategory,
  updateApiCategory,
  deleteApiCategory
} from "actions/api_categories";
import formHelper from "lib/ui/form/form";
import renderClassNames from "lib/ui/render_class_names";

class ApiSettings extends Component {
  constructor(props) {
    super(props);
    let { initialValues, operation } = props;
    this.state = {
      statusUpdated: false,
      updateConfirmationOpen: false,
      deleteConfirmationOpen: false,
      cantDeleteConfirmationOpen: false,
      updateFormValues: {},
      api_cat_friendly_name: {
        key: "api_cat_friendly_name",
        value: (initialValues && initialValues.api_cat_friendly_name) || "",
        valid: true,
        errorMessage: "",
        validation: [required, minLength3]
      },
      api_cat_name: {
        key: "api_cat_name",
        value: (initialValues && initialValues.api_cat_name) || "",
        valid: true,
        errorMessage: "",
        validation: [required, minLength3]
      },
      api_cat_description: {
        key: "api_cat_description",
        value: (initialValues && initialValues.api_cat_description) || "",
        valid: true,
        errorMessage: "",
        validation: [maxLength256]
      },
      status_flag: {
        key: "status_flag",
        value:
          operation === "update"
            ? initialValues && initialValues.status_flag.toString()
            : "",
        valid: true,
        errorMessage: "",
        validation: [required]
      }
    };
  }

  onSubmit = async () => {
    let { operation, saveApiCategory } = this.props;
    const isFormValid = formHelper.isFormValid(this.state);
    if (!isFormValid.valid) {
      this.setState(isFormValid.formState);
      return;
    }
    let formData = formHelper.getFormValues(this.state);
    if (operation === "insert") {
      formHelper.removeUnwantedValues(formData);
      await saveApiCategory(formData);
      this.props.setNotification("good", "API Created");
    }
    if (operation === "update") {
      if (this.state.statusUpdated) {
        this.setState({
          updateFormValues: formData,
          updateConfirmationOpen: true
        });
      } else {
        this.setState({
          updateFormValues: formData
        });
        this.continueUpdate();
      }
    }
  };

  continueUpdate = async () => {
    let { updateApiCategory } = this.props;
    updateApiCategory(this.state.updateFormValues);
    this.props.setNotification("good", "API Updated");
  };

  deleteApiCategory = async () => {
    let { history, deleteApiCategory, updateFormValues } = this.props;
    await deleteApiCategory(updateFormValues);
    history.push("/apis");
    this.props.setNotification("good", "API Deleted");
  };

  onStatusChange = e => {
    const {
      target: { name, value }
    } = e;
    this.setState(formHelper.mergeNewState(this.state, name, { value }));
    if (this.props.initialValues) {
      if (
        this.props.initialValues.status_flag ===
        parseInt(this.state.status_flag, 10)
      ) {
        this.setState({
          statusUpdated: true
        });
      }
    }
  };

  onChange = e => {
    const {
      target: { name, value }
    } = e;
    this.setState(formHelper.mergeNewState(this.state, name, { value }));
  };

  onBlur = e => {
    const {
      target: { name, value }
    } = e;
    const validation = formHelper.validate(this.state, name, value);
    this.setState(formHelper.mergeNewState(this.state, name, validation));
  };

  renderSubmitButton(options) {
    let { permissions } = this.props;
    let canUpdate =
      permissions.filter(p => p.permission_name === "API_CATEGORY_UPDATE")
        .length > 0;
    let submitText = options.isInsert ? "SAVE" : "UPDATE";
    if (canUpdate) {
      return (
        <button
          type="button"
          onClick={this.onSubmit}
          name="saveApiCategoryButton"
          className={options.submitStyle}
        >
          {submitText}
        </button>
      );
    } else {
      return "";
    }
  }

  renderCancelButton(options) {
    return (
      <button
        type="button"
        onClick={this.props.onSave}
        name="cancelButton"
        className={options.otherStyle}
      >
        CANCEL
      </button>
    );
  }

  renderDeleteButton(options) {
    let { permissions } = this.props;
    let canDelete =
      permissions.filter(p => p.permission_name === "API_CATEGORY_DELETE")
        .length > 0;
    if (canDelete) {
      return (
        <button
          type="button"
          onClick={() => this.setState({ deleteConfirmationOpen: true })}
          name="deleteApiCategoryButton"
          className={options.otherStyle}
        >
          DELETE API
        </button>
      );
    } else {
      return "";
    }
  }

  getStyle() {
    let { initialValues } = this.props;
    let isInsert = !initialValues;
    const categoryFriendlyNameErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !this.state.api_cat_friendly_name.valid
    });
    const categoryNameErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !this.state.api_cat_name.valid
    });
    const categoryDescErrStyle = renderClassNames({
      "form-control-textarea": true,
      "custom-danger-border": !this.state.api_cat_description.valid
    });
    const statusFlagErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !this.state.status_flag.valid
    });

    return {
      otherStyle: isInsert ? "btn btn-default pull-right" : "btn btn-default",
      submitStyle: isInsert ? "btn btn-primary pull-right" : "btn btn-primary",
      categoryFriendlyNameErrStyle: categoryFriendlyNameErrStyle,
      categoryNameErrStyle: categoryNameErrStyle,
      categoryDescErrStyle: categoryDescErrStyle,
      statusFlagErrStyle: statusFlagErrStyle
    };
  }

  render() {
    let { initialValues } = this.props;
    let isInsert = !initialValues;
    let initialLength =
      initialValues && initialValues.api_cat_description
        ? initialValues.api_cat_description.length
        : 0;
    const style = this.getStyle();

    return (
      <div
        ref={node => {
          this.node = node;
        }}
        className="main-admin-container"
      >
        <div className="col-md-12">
          <form className="form-horizontal modal-form">
            <div className="form-group">
              <div className="field-label">Name *</div>
              <div>
                <input
                  name={this.state.api_cat_friendly_name.key}
                  type="text"
                  value={this.state.api_cat_friendly_name.value}
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                  className={style.categoryFriendlyNameErrStyle}
                  placeholder="Name"
                />
              </div>
              <div className="field-label">This name is displayed in lists</div>
              {!this.state.api_cat_friendly_name.valid && (
                <div className="custom-text-danger">
                  {this.state.api_cat_friendly_name.errorMessage}
                </div>
              )}
            </div>
            <div className="form-group">
              <div className="field-label">Category Name *</div>
              <div>
                <input
                  name={this.state.api_cat_name.key}
                  type="text"
                  value={this.state.api_cat_name.value}
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                  className={style.categoryNameErrStyle}
                  placeholder="CATEGORY_NAME"
                />
                <div className="field-label">This name is used in code</div>
                {!this.state.api_cat_name.valid && (
                  <div className="custom-text-danger">
                    {this.state.api_cat_name.errorMessage}
                  </div>
                )}
              </div>
            </div>
            <div className="form-group">
              <div className="field-label">Description (optional)</div>
              <div>
                <TextArea
                  name={this.state.api_cat_description.key}
                  value={this.state.api_cat_description.value}
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                  label="Description"
                  rows={5}
                  showLength={true}
                  initialLength={initialLength}
                  totalLength={256}
                  className={style.categoryDescErrStyle}
                ></TextArea>
                {!this.state.api_cat_description.valid && (
                  <div className="custom-text-danger">
                    {this.state.api_cat_description.errorMessage}
                  </div>
                )}
              </div>
            </div>
            <div className="form-group">
              <div className="field-label">Status *</div>
              <div>
                <Select
                  name={this.state.status_flag.key}
                  value={this.state.status_flag.value}
                  onChange={this.onStatusChange}
                  onBlur={this.onBlur}
                  options={{
                    1: "Active",
                    0: "Inactive"
                  }}
                  className={style.statusFlagErrStyle}
                ></Select>
              </div>
              {!this.state.status_flag.valid && (
                <div className="custom-text-danger">
                  {this.state.status_flag.errorMessage}
                </div>
              )}
            </div>
            <div className="form-group">
              <div className="col-md-3 field-label">* Required</div>
              <div className="col-md-9">
                {isInsert &&
                  this.renderCancelButton({
                    otherStyle: style.otherStyle
                  })}
                {this.renderSubmitButton({
                  isInsert: isInsert,
                  submitStyle: style.submitStyle
                })}
                {!isInsert &&
                  this.renderDeleteButton({
                    otherStyle: style.otherStyle
                  })}
              </div>
            </div>
          </form>
          <ConfirmationModal
            open={this.state.updateConfirmationOpen}
            header="Save Event?"
            text="Changes have been made to the API that will impact its behavior. Are you sure you want to save?"
            leftLabel="SAVE"
            onLeft={this.continueUpdate}
            rightLabel="CANCEL"
            onClose={() => this.setState({ updateConfirmationOpen: false })}
          />
          <ConfirmationModal
            open={this.state.deleteConfirmationOpen}
            header="Delete API?"
            text="If deleted, this API will no longer be available to applications. Are you sure you want to delete?"
            leftLabel="DELETE"
            onLeft={this.deleteApiCategory}
            rightLabel="CANCEL"
            onClose={() => this.setState({ deleteConfirmationOpen: false })}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  let values = {
    permissions: state.activeUser.role_permissions
  };
  return values;
};

export default connect(mapStateToProps, {
  saveApiCategory,
  updateApiCategory,
  deleteApiCategory,
  setNotification
})(withRouter(changes(ApiSettings)));
