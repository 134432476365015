import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";

import withAuth from "components/hoc/with_auth";
import { getEvent, getEvents, filterEvents } from "actions/events";
import { getOrganizations } from "actions/organizations";
import EventList from "./event/list/event_list";
import ListSearch from "lib/ui/form/list_search";
import EventEditForm from "./event/event_edit_form";
import Header from "components/header/header";

// const PAGE_SIZE = 1000; // RESULTS_PAGING = 3;

class EventsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      eventsFiltered: false,
      filterString: ""
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    this.props.getEvents();
    this.props.getOrganizations();
  }

  filterEvents = () => {
    if (this.state.filterString !== "") {
      return filterEvents(this.state.filterString, this.props.events);
    } else {
      return this.props.events;
    }
  };

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
    this.loadData();
  };

  onListSearchChange = e => {
    this.setState({
      eventsFiltered: true,
      filterString: e.value
    });
  };

  render() {
    let { permissions, events, organizations } = this.props;
    if (!permissions || !events || !organizations) return null;
    let canCreate =
      permissions.filter(p => p.permission_name === "SHOW_CREATE").length > 0;
    events.forEach(event => {
      organizations.forEach(organization => {
        if (
          event.fuzion_organization_id.toUpperCase() ===
          organization.fuzion_organization_id.toUpperCase()
        ) {
          event["partner_name"] = organization.partner_name;
        }
      });
    });
    return (
      <div className="col-sm-9 col-sm-offset-3 col-md-10 col-md-offset-2 main">
        <Header />
        <div className="container-fluid">
          <h1 className="page-header">Events</h1>
          <div className="body-content">
            <div>
              {canCreate && (
                <button
                  onClick={this.onOpenModal}
                  className="btn btn-primary btn-add-event"
                >
                  + EVENT
                </button>
              )}
            </div>
            <ListSearch
              onChange={this.onListSearchChange}
              placeholder="Search for an event"
              autoComplete="off"
            ></ListSearch>
          </div>
          <div className="body-content-partial">
            <EventList
              item={this.filterEvents()}
              eventsFiltered={this.state.eventsFiltered}
            />
          </div>
        </div>
        <Modal
          open={this.state.open}
          onClose={this.onCloseModal}
          classNames={{ modal: "custom-modal" }}
          closeOnOverlayClick={false}
          showCloseIcon={false}
        >
          <EventEditForm
            title="New Event"
            onSave={this.onCloseModal}
            onClose={this.onCloseModal}
            loadData={this.loadData}
            operation="insert"
          />
        </Modal>
      </div>
    );
  }
}

let mapStateToProps = state => {
  return {
    permissions: state.activeUser.role_permissions,
    event: state.event,
    events: state.events,
    organizations: state.organizations
  };
};

export default connect(mapStateToProps, {
  getEvent,
  getEvents,
  getOrganizations
})(withAuth(EventsList));
