import React, { Component } from "react";
import { connect } from "react-redux";
import RolesEditForm from "./role_edit_form";

class Settings extends Component {
  render() {
    return (
      <div className="container-fluid tabs-spacer">
        <div className="col-sm-9 col-sm-offset-3 col-md-10 col-md-offset-2 main">
          <h1 className="page-header">Role Settings</h1>
          {this.props.selectedRole &&
            Object.keys(this.props.selectedRole).length > 0 && (
              <RolesEditForm
                key={this.props.selectedRole.fuzion_security_role_id}
                onClose={this.onSave}
                initialValues={this.props.selectedRole}
                onSave={this.onSave}
                operation="update"
              />
            )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedRole: state.role
  };
};

export default connect(mapStateToProps)(Settings);
