import React, { Component } from "react";
import { connect } from "react-redux";

import { setSideBarState } from "actions/sidebar";
import MenuButton from "./menu_button";
import MenuBackButton from "./menu_back_button";
import iconSearchLight from "assets/images/iconSearchLight.svg";
import iconBackArrowLight from "assets/images/iconBackArrowLight.svg";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detailView: false,
      to: null
    };
  }

  toggleSideBar = () => {
    if (this.props.sidebar === true) {
      this.props.setSideBarState(false);
    } else {
      this.props.setSideBarState(true);
    }
  };

  render() {
    let button = null;

    if (this.props.detailView) {
      button = <MenuBackButton to={this.props.to} />;
    } else {
      button = <MenuButton onClick={this.toggleSideBar} />;
    }

    const navbarClass = `navbar navbar-inverse ${this.props.sidebar &&
      "sidebar-spacer"}`;

    return (
      <nav className={navbarClass}>
        <div className="fuzion-logo-header">
          <div className="navbar-left navbar-container">{button}</div>
          <div id="navbar" className="navbar-collapse collapse">
            <form className="navbar-form navbar-right"></form>
          </div>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = state => {
  return {
    sidebar: state.sidebar
  };
};

export default connect(mapStateToProps, {
  setSideBarState,
  iconBackArrowLight,
  iconSearchLight
})(Header);
