import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";

import { getApplications } from "actions/applications";
import emptyState from "assets/images/graphicEmptyState.svg";
import { imageAssets } from "fuzion-core-lib";

class ApplicationList extends Component {
  renderEmptyState(emptyMessage) {
    return (
      <div className="empty-state">
        <img
          className="empty-state-img"
          src={emptyState}
          alt="empty organization"
        />
        <div className="empty-state-text">{emptyMessage}</div>
      </div>
    );
  }

  renderAvatar(application) {
    const image =
      application.application_image_file || imageAssets.FUZION_AVATAR;
    return (
      <div className="list-avatar-div">
        <img src={image} className="list-avatar-image" alt="user avatar" />
      </div>
    );
  }

  renderList(application, index) {
    let to = `/organizations/${application.fuzion_organization_id}/app/${application.fuzion_application_id}/settings`;
    let lastUpdateFormatted = moment
      .utc(application.last_mod_timestamp, "YYYY-MM-DD hh:mm:ss")
      .local()
      .format("MMMM Do, h:mm a");
    return (
      <Link
        key={index}
        to={to}
        className="list-item-container"
        onClick={() => console.log()}
      >
        {this.renderAvatar(application)}
        <div className="list-item-content">
          <div className="list-title">{application.application_name}</div>
          <div className="status-div">
            <div>Last Updated: {lastUpdateFormatted}</div>
          </div>
        </div>
      </Link>
    );
  }

  render() {
    if (this.props.applications.length < 1) {
      return (
        <div>
          {this.renderEmptyState("Nothing in Organization Applications")}
        </div>
      );
    }
    let active = this.props.applications
      .filter(application => application.status_flag > 0)
      .map((application, index) => this.renderList(application, index));
    let inactive = this.props.applications
      .filter(application => application.status_flag === 0)
      .map((application, index) => this.renderList(application, index));

    return (
      <div>
        <div className="list-sub-heading">
          {active.length > 0 ? "Active" : ""}
        </div>
        {active}
        <div className="list-sub-heading">
          {inactive.length > 0 ? "Inactive" : ""}
        </div>
        {inactive}
      </div>
    );
  }
}

let mapStateToProps = state => {
  return {
    application: state.application,
    applications: state.applications
  };
};

export default connect(mapStateToProps, { getApplications })(ApplicationList);
