import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import withAuth from "components/hoc/with_auth";
import { required, email } from "lib/validation/form_validation_rules";
import formHelper from "lib/ui/form/form";
import ConfirmationModal from "lib/ui/confirmationModal";
import changes from "components/hoc/unsaved_changes";
import { createUser, updateUser, saveUser, deleteUser } from "actions/users";
import { setNotification } from "actions/notifications";
import UserAvatar from "lib/ui/user_avatar";
import asyncValidate from "lib/validation/asyncValidateEmail";
import Select from "lib/ui/form/select_non_redux";
import renderClassNames from "lib/ui/render_class_names";

export class AdminUserProfileEditForm extends Component {
  constructor(props) {
    super(props);
    let { initialValues, operation } = this.props;
    this.state = {
      deleteConfirmationOpen: false,
      first_name: {
        key: "first_name",
        value: (initialValues && initialValues.first_name) || "",
        valid: true,
        errorMessage: "",
        validation: [required]
      },
      last_name: {
        key: "last_name",
        value: (initialValues && initialValues.last_name) || "",
        valid: true,
        errorMessage: "",
        validation: [required]
      },
      email: {
        key: "email",
        value: (initialValues && initialValues.email) || "",
        valid: true,
        errorMessage: "",
        validation: [required, email]
      },
      fuzion_security_role_id: {
        key: "fuzion_security_role_id",
        valid: true,
        value: (initialValues && initialValues.fuzion_security_role_id) || "",
        validation: [required]
      },
      status_flag: {
        key: "status_flag",
        valid: true,
        value: operation === "update" ? initialValues.status_flag : ""
      }
    };
  }

  renderSelectOptions = (key, index) => {
    return (
      <option key={index} value={key}>
        {this.selectRoles()[key]}
      </option>
    );
  };

  selectRoles = () => {
    let options = {};
    this.props.roles.forEach(role => {
      options[role.fuzion_security_role_id] = role.role_friendly_name;
    });
    return options;
  };

  onSubmit = async () => {
    let {
      setNotification,
      onSave,
      operation,
      saveUser,
      updateUser,
      initialValues
    } = this.props;
    const isFormValid = formHelper.isFormValid(this.state);
    if (!isFormValid.valid) {
      this.setState(isFormValid.formState);
      return;
    }
    if (!this.state.email.valid) return;
    let form = formHelper.getFormValues(this.state);
    switch (operation) {
      case "save":
        delete form.status_flag;
        delete form.fuzion_user_profile_id;
        formHelper.removeUnwantedValues(form);
        await saveUser(form);
        onSave();
        setNotification("good", "User Created");
        break;
      case "update":
        form.prior_email = initialValues.email;
        form.prior_status_flag = Number(initialValues.status_flag);
        form.status_flag = Number(form.status_flag);
        form.fuzion_user_profile_id = initialValues.fuzion_user_profile_id;
        form.fuzion_organization_id = initialValues.fuzion_organization_id;
        await updateUser(form);
        onSave();
        break;
      default:
        console.log(`Invalid Role operation: "${operation}"`);
        break;
    }
  };

  onCloseDeleteUserModal = () => {
    this.setState({ deleteConfirmationOpen: false });
  };

  onOpenModal = () => {
    this.setState({ deleteConfirmationOpen: true });
  };

  onDelete = async () => {
    let { history, deleteUser, initialValues } = this.props;
    await deleteUser(initialValues);
    this.setState({ deleteConfirmationOpen: false });
    history.push("/admin/users");
    this.props.setNotification("good", "Delete Successful");
  };

  onChange = ({ target: { name, value } }) => {
    this.setState(formHelper.mergeNewState(this.state, name, { value }));
  };

  onBlur = e => {
    const {
      target: { name, value }
    } = e;
    const validation = formHelper.validate(this.state, name, value);
    this.setState(formHelper.mergeNewState(this.state, name, validation));
  };

  onEmailBlur = async e => {
    const {
      target: { name, value }
    } = e;
    let validation = formHelper.validate(this.state, name, value);
    if (validation.errorMessage === "") {
      try {
        await asyncValidate({ email: value });
      } catch (t) {
        validation = { valid: false, errorMessage: "Email already in use." };
      }
    }
    this.setState(formHelper.mergeNewState(this.state, name, validation));
  };

  renderNameAndAvatar() {
    const { first_name, last_name } = this.props.initialValues;
    return (
      <div>
        <h1>{first_name + " " + last_name}</h1>
        <UserAvatar />
      </div>
    );
  }

  renderSubmitButton(options) {
    let { permissions } = this.props;
    let canUpdate =
      permissions.filter(p => p.permission_name === "USER_ADMIN_UPDATE")
        .length > 0;
    let submitText = options.isInsert ? "SAVE" : "UPDATE";
    if (canUpdate) {
      return (
        <button
          type="button"
          name="saveUserButton"
          onClick={this.onSubmit}
          className={options.submitStyle}
        >
          {submitText}
        </button>
      );
    } else {
      return "";
    }
  }

  renderDeleteButton(options) {
    let { permissions, activeUser, initialValues } = this.props;
    let canDelete =
      permissions.filter(p => p.permission_name === "USER_ADMIN_DELETE")
        .length > 0;

    let notMe =
      activeUser.email.toLowerCase() !== initialValues.email.toLowerCase();

    if (canDelete && notMe) {
      return (
        <button
          type="button"
          onClick={this.onOpenModal}
          name="deleteUserButton"
          className={options.otherStyle}
        >
          DELETE USER
        </button>
      );
    } else {
      return "";
    }
  }

  getStyle = isInsert => {
    const userFirstNameErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !this.state.first_name.valid
    });
    const userLastNameErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !this.state.last_name.valid
    });
    const userEmailErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !this.state.email.valid
    });
    const userRoleErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !this.state.fuzion_security_role_id.valid
    });
    const statusFlagErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !this.state.status_flag.valid
    });
    return {
      otherStyle: isInsert ? "btn btn-default pull-right" : "btn btn-default",
      submitStyle: isInsert ? "btn btn-primary pull-right" : "btn btn-primary",
      submitText: isInsert ? "CREATE" : "SAVE",
      spacing: isInsert ? "user-insert-form" : "container-fluid tabs-spacer",
      btnContainer: isInsert
        ? "formAddButtonContainer"
        : "formEditButtonContainer",
      requiredLabel: isInsert ? "required-label-jpom" : "required-label",
      userFirstNameErrStyle: userFirstNameErrStyle,
      userLastNameErrStyle: userLastNameErrStyle,
      userEmailErrStyle: userEmailErrStyle,
      userRoleErrStyle: userRoleErrStyle,
      statusFlagErrStyle: statusFlagErrStyle
    };
  };

  render() {
    let { operation } = this.props;
    let isInsert = operation === "save";
    let style = this.getStyle(isInsert);
    let emailDisabled = operation === "update" ? true : false;
    return (
      <div className={style.spacing}>
        {!isInsert ? (
          this.renderNameAndAvatar()
        ) : (
          <h4 className="modal-title">Invite User</h4>
        )}
        <form className="modal-form">
          {isInsert && (
            <div className="form-group">
              <div className="field-label">Name *</div>
              <div>
                <input
                  name={this.state.first_name.key}
                  type="text"
                  value={this.state.first_name.value}
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                  className={style.userFirstNameErrStyle}
                  placeholder="First Name"
                />
                {!this.state.first_name.valid && (
                  <div className="custom-text-danger">
                    {this.state.first_name.errorMessage}
                  </div>
                )}
                <br />
                <input
                  name={this.state.last_name.key}
                  type="text"
                  value={this.state.last_name.value}
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                  className={style.userLastNameErrStyle}
                  placeholder="Last Name"
                />
                {!this.state.last_name.valid && (
                  <div className="custom-text-danger">
                    {this.state.last_name.errorMessage}
                  </div>
                )}
              </div>
            </div>
          )}
          <div className="form-group">
            <div className="field-label">Email Address *</div>
            <div>
              <input
                name={this.state.email.key}
                type="text"
                value={this.state.email.value}
                onChange={this.onChange}
                onBlur={this.onEmailBlur}
                disabled={emailDisabled}
                className={style.userEmailErrStyle}
                placeholder="email@address.com"
              />
              {!this.state.email.valid && (
                <div className="custom-text-danger">
                  {this.state.email.errorMessage}
                </div>
              )}
            </div>
          </div>
          <div className="form-group">
            <div className="field-label">Role *</div>
            <select
              name={this.state.fuzion_security_role_id.key}
              className={style.userRoleErrStyle}
              value={this.state.fuzion_security_role_id.value}
              onChange={this.onChange}
              onBlur={this.onBlur}
            >
              <option key="-1" value="" disabled>
                Select a security role...
              </option>{" "}
              {Object.keys(this.selectRoles()).map(this.renderSelectOptions)}
            </select>
            {!this.state.fuzion_security_role_id.valid && (
              <div className="custom-text-danger">
                {this.state.fuzion_security_role_id.errorMessage}
              </div>
            )}
          </div>
          {!isInsert ? (
            <div className="form-group">
              <div className="field-label">Status *</div>
              <div>
                <Select
                  name={this.state.status_flag.key}
                  value={this.state.status_flag.value}
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                  options={{
                    1: "Active",
                    0: "Inactive"
                  }}
                  className={style.statusFlagErrStyle}
                ></Select>
              </div>
              {!this.state.status_flag.valid && (
                <div className="custom-text-danger">
                  {this.state.status_flag.errorMessage}
                </div>
              )}
            </div>
          ) : null}
          <div className="form-group">
            <div className={style.btnContainer}>
              {isInsert && (
                <button
                  type="button"
                  onClick={this.props.onSave}
                  name="cancelUserButton"
                  className={style.otherStyle}
                >
                  CANCEL
                </button>
              )}
              {this.renderSubmitButton({
                isInsert: isInsert,
                submitStyle: style.submitStyle
              })}
              {!isInsert &&
                this.renderDeleteButton({
                  otherStyle: style.otherStyle
                })}
            </div>
            <div className={style.requiredLabel}>* Required</div>
          </div>
        </form>
        <ConfirmationModal
          open={this.state.deleteConfirmationOpen}
          header="Delete User?"
          text="This user will no longer have access to the application."
          leftLabel="DELETE"
          onLeft={this.onDelete}
          rightLabel="CANCEL"
          onClose={() => this.setState({ deleteConfirmationOpen: false })}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  let values = {
    activeUser: state.activeUser,
    permissions: state.activeUser.role_permissions,
    roles: state.roles
  };
  return values;
};

export default connect(mapStateToProps, {
  createUser,
  saveUser,
  updateUser,
  deleteUser,
  setNotification
})(withAuth(withRouter(changes(AdminUserProfileEditForm))));
