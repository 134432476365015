import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import avatarEvent from "assets/images/avatarEvent.svg";
import avatarApp from "assets/images/avatarApp.svg";

import "./mapping_list_item.css";

class MappingListItem extends Component {
  render() {
    let { mapping } = this.props;
    if (!mapping) return null;
    let to = `/mappings/${mapping.fuzion_mappings_id}`;
    let lastUpdateFormatted = moment
      .utc(mapping.last_mod_timestamp, "YYYY-MM-DD hh:mm:ss")
      .local()
      .format("MMM D, YYYY h:mm A");
    let apiCategory = null;
    if (mapping.fuzion_api_category) {
      apiCategory = mapping.fuzion_api_category.replace(/^\w/, c =>
        c.toUpperCase()
      );
    }
    return (
      <Link to={to} className="mapping-item">
        <div
          className={
            "mapping-item-tag" + (mapping.status === 0 ? " drafttag" : "")
          }
        >
          {mapping.status === 0 ? "DRAFT" : "PUBLISHED"}
        </div>
        <div className="mapping-item-title">
          {apiCategory}: {mapping.name}
        </div>
        <div className="listsubtext">{lastUpdateFormatted}</div>
        <div className="batchinfodiv mapping">
          <div className="mappingappdiv">
            <img src={avatarEvent} className="mapping-icon" alt="Event Icon" />
            <div className="div-block-3">
              <div className="appnametext">{mapping.event_name}</div>
              <div className="supporttext">{mapping.partner_name}</div>
            </div>
          </div>
          <div className="mappingappdiv">
            <img src={avatarApp} className="mapping-icon" alt="App Icon" />
            <div className="div-block-3">
              <div className="appnametext">{mapping.application_name}</div>
              <div className="supporttext">{mapping.partner_name}</div>
            </div>
          </div>
        </div>
      </Link>
    );
  }
}

export default MappingListItem;
