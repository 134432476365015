import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { Loading } from "components/loading/loading";
import withAuth from "components/hoc/with_auth";
import changes from "components/hoc/unsaved_changes";
import {
  required,
  minLength2,
  minLength3,
  maxLength256,
  email
} from "lib/validation/form_validation_rules";
import TextArea from "lib/ui/form/text_area_non_redux";
import Select from "lib/ui/form/select_non_redux";
import ConfirmationModal from "lib/ui/confirmationModal";
import {
  createOrganization,
  saveOrganization,
  deleteOrganization,
  updateOrganization
} from "actions/organizations";
import { setNotification } from "actions/notifications";
import { saveImage, deleteImage } from "actions/organization_image";
import { imageAssets } from "fuzion-core-lib";
import formHelper from "lib/ui/form/form";
import asyncValidate from "lib/validation/asyncValidateEmail";
import renderClassNames from "lib/ui/render_class_names";

export class OrganizationEditForm extends Component {
  constructor(props) {
    super(props);
    this.fileUploader = React.createRef();
    let { initialValues } = props;
    const isInsert = this.props.operation === "save";
    const stateFields = {
      isInsert: isInsert,
      deleteConfirmationOpen: false,
      fuzion_organization_id: this.props.match.params.fuzion_organization_id,
      organizationImage: null,
      imageButtonsDisabled: false
    };
    const insertStateFields = {
      partner_name: {
        key: "partner_name",
        value: (initialValues && initialValues.partner_name) || "",
        valid: true,
        errorMessage: "",
        validation: [required, minLength3]
      },
      partner_description: {
        key: "partner_description",
        value: (initialValues && initialValues.partner_description) || "",
        valid: true,
        errorMessage: "",
        validation: [maxLength256]
      },
      status_flag: {
        key: "status_flag",
        value:
          !isInsert && Object.keys(initialValues).length > 0
            ? initialValues.status_flag.toString()
            : "0",
        valid: true,
        errorMessage: "",
        validation: [required]
      }
    };
    const updateStateFields = {
      contact_first_name: {
        key: "contact_first_name",
        value:
          (initialValues &&
            initialValues.contact &&
            initialValues.contact.first_name) ||
          "",
        valid: true,
        errorMessage: "",
        validation: [required, minLength3]
      },
      contact_last_name: {
        key: "contact_last_name",
        value:
          (initialValues &&
            initialValues.contact &&
            initialValues.contact.last_name) ||
          "",
        valid: true,
        errorMessage: "",
        validation: [required, minLength3]
      },
      contact_email: {
        key: "contact_email",
        value:
          (initialValues &&
            initialValues.contact &&
            initialValues.contact.email) ||
          "",
        valid: true,
        errorMessage: "",
        validation: [required, email]
      },
      contact_phone_number: {
        key: "contact_phone_number",
        value:
          (initialValues &&
            initialValues.contact &&
            initialValues.contact.phone_number) ||
          "",
        valid: true,
        errorMessage: "",
        validation: [minLength3]
      },
      address_address_line_one: {
        key: "address_address_line_one",
        value:
          (initialValues &&
            initialValues.address &&
            initialValues.address.address_line_one) ||
          "",
        valid: true,
        errorMessage: "",
        validation: [minLength3]
      },
      address_address_line_two: {
        key: "address_address_line_two",
        value:
          (initialValues &&
            initialValues.address &&
            initialValues.address.address_line_two) ||
          "",
        valid: true,
        errorMessage: "",
        validation: [minLength3]
      },
      address_address_line_three: {
        key: "address_address_line_three",
        value:
          (initialValues &&
            initialValues.address &&
            initialValues.address.address_line_three) ||
          "",
        valid: true,
        errorMessage: "",
        validation: [minLength3]
      },
      address_city: {
        key: "address_city",
        value:
          (initialValues &&
            initialValues.address &&
            initialValues.address.city) ||
          "",
        valid: true,
        errorMessage: "",
        validation: [minLength3]
      },
      address_state_province: {
        key: "address_state_province",
        value:
          (initialValues &&
            initialValues.address &&
            initialValues.address.state_province) ||
          "",
        valid: true,
        errorMessage: "",
        validation: [minLength2]
      },
      address_postal_code: {
        key: "address_postal_code",
        value:
          (initialValues &&
            initialValues.address &&
            initialValues.address.postal_code) ||
          "",
        valid: true,
        errorMessage: "",
        validation: [minLength3]
      },
      address_country: {
        key: "address_country",
        value:
          (initialValues &&
            initialValues.address &&
            initialValues.address.country) ||
          "",
        valid: true,
        errorMessage: "",
        validation: [minLength2]
      }
    };
    if (isInsert) {
      this.state = {
        ...stateFields,
        ...insertStateFields
      };
    } else {
      this.state = {
        ...stateFields,
        ...insertStateFields,
        ...updateStateFields
      };
    }
  }

  componentDidMount() {
    this._isMounted = true; //used to prevent memory if redirected after loadData() fetch
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadData = async () => {
    let { initialValues } = this.props;
    if (
      !this.state.isInsert &&
      this._isMounted &&
      initialValues &&
      Object.keys(initialValues).length > 0
    ) {
      this.setImage(initialValues.organization_image_file);
    }
  };

  createOrganization = async organization => {
    const { setNotification, saveOrganization, history } = this.props;
    organization.status_flag = Number(organization.status_flag);
    let value = await saveOrganization(organization);
    setNotification("good", "Organization Created");
    history.push(`/organizations/${value.payload.fuzion_organization_id}`);
  };

  updateOrganization = async organization => {
    let { updateOrganization, initialValues } = this.props;
    organization.prior_status_flag = Number(initialValues.status_flag);
    organization.status_flag = Number(organization.status_flag);
    organization.fuzion_organization_id = initialValues.fuzion_organization_id;
    organization.contact = {
      first_name: organization.contact_first_name,
      last_name: organization.contact_last_name,
      phone_number: organization.contact_phone_number,
      email: organization.contact_email
    };
    organization.address = {
      address_line_one: organization.address_address_line_one,
      address_line_two: organization.address_address_line_two,
      address_line_three: organization.address_address_line_three,
      city: organization.address_city,
      state_province: organization.address_state_province,
      country: organization.address_country,
      postal_code: organization.address_postal_code
    };
    await updateOrganization(organization);
    this.props.setNotification("good", "Organization Information Updated");
    this.loadData();
  };

  onSubmit = async () => {
    const isFormValid = formHelper.isFormValid(this.state);
    if (!isFormValid.valid) {
      this.setState(isFormValid.formState);
      return;
    }
    let formValues = formHelper.getFormValues(this.state);
    let { operation, organizations, initialValues } = this.props;
    let newOrganizationName =
      operation === "update"
        ? initialValues.partner_name.toLowerCase() !==
          formValues.partner_name.toLowerCase()
        : true;
    const nameAlreadyExists = organizations.some(org => {
      return (
        org.partner_name.toLowerCase().trim() ===
          formValues.partner_name.toLowerCase().trim() && newOrganizationName
      );
    });
    if (nameAlreadyExists) {
      const newOrgName = Object.assign(
        {},
        {
          ...this.state.partner_name,
          valid: false,
          errorMessage: "This organization name already exists"
        }
      );
      this.setState({ partner_name: newOrgName });
      return;
    }
    switch (operation) {
      case "save":
        formHelper.removeUnwantedValues(formValues);
        this.createOrganization(formValues);
        break;
      case "update":
        this.updateOrganization(formValues);
        break;
      default:
        console.log(`Unknown operation: ${operation}`);
        break;
    }
  };

  onDelete = async () => {
    let { history, deleteOrganization, initialValues } = this.props;
    initialValues.fuzion_organization_id = this.props.match.params.fuzion_organization_id;
    await deleteOrganization(initialValues);
    history.push("/organizations");
    this.props.setNotification("good", "Organization Information Deleted");
  };

  renderTitle() {
    return <h4 className="modal-title">{this.props.title}</h4>;
  }

  handleNewImage = e => {
    e.stopPropagation();
    e.preventDefault();
    this.fileUploader.current.click();
  };

  setImage(organizationImage) {
    if (this._isMounted) {
      this.setState({ organizationImage });
    }
  }

  onEmailBlur = async e => {
    let {
      target: { name, value }
    } = e;
    value = value.trim();
    let validation = formHelper.validate(this.state, name, value);
    if (validation.errorMessage === "") {
      try {
        await asyncValidate({ email: value });
      } catch (t) {
        validation = { valid: false, errorMessage: "Email already in use." };
      }
    }
    this.setState(formHelper.mergeNewState(this.state, name, validation));
  };

  onChange = ({ target: { name, value } }) => {
    if (!this.state[name].valid) {
      const { valid, errorMessage } = formHelper.validate(
        this.state,
        name,
        value
      );
      this.setState(
        formHelper.mergeNewState(this.state, name, {
          value,
          valid,
          errorMessage
        })
      );
    } else {
      this.setState(formHelper.mergeNewState(this.state, name, { value }));
    }
  };

  onBlur = e => {
    let {
      target: { name, value }
    } = e;
    if (typeof value === "string") value = value.trim();
    const { valid, errorMessage } = formHelper.validate(
      this.state,
      name,
      value
    );
    this.setState(
      formHelper.mergeNewState(this.state, name, {
        value,
        valid,
        errorMessage
      })
    );
  };

  onDeleteImage = async () => {
    let {
      deleteImage,
      initialValues,
      setNotification,
      updateOrganization
    } = this.props;
    this.setState({ imageButtonsDisabled: true });
    const orgWithoutImage = await deleteImage(
      initialValues.fuzion_organization_id
    );
    if (!orgWithoutImage) {
      console.log("Failed to delete image");
    } else {
      await updateOrganization(orgWithoutImage);
      this.setImage(null);
      setNotification("good", "Delete Successful");
    }
    this.setState({ imageButtonsDisabled: false });
  };

  onChangeFile = async e => {
    let {
      initialValues,
      setNotification,
      saveImage,
      updateOrganization
    } = this.props;
    this.setState({ imageButtonsDisabled: true });
    if (!e) return;
    let file = e.target.files[0];
    e.target.value = null;
    if (!file) return;
    let acceptFileTypes = /(\.|\/)(gif|jpe?g|png)$/i;
    let fileSizeMB = file.size / (1024 * 1024);
    if (file.type.length && !acceptFileTypes.test(file.type)) {
      setNotification("good", "Not an image");
      console.log("Image must be of type jpg, png, or gif!");
      return false;
    }
    if (fileSizeMB > 10) {
      setNotification("good", "Image is too large");
      return false;
    }
    let formData = new FormData();
    formData.append("fuzion_organization_image", file);
    formData.append("content_type", file.type);
    formData.append("image_name", file.name);

    const orgWithImage = await saveImage(
      formData,
      initialValues.fuzion_organization_id
    );
    if (!orgWithImage) {
      console.log("Failed to update image");
    } else {
      await updateOrganization(orgWithImage);
      const localImageUrl = window.URL.createObjectURL(file);
      this.setImage(localImageUrl);
      setNotification("good", "Update Successful");
    }
    this.setState({ imageButtonsDisabled: false });
  };

  renderImageUpdateButton() {
    return (
      <button
        type="button"
        disabled={this.state.imageButtonsDisabled}
        name="updateApplicationImageButton"
        onClick={this.handleNewImage}
        className="btn btn-default-alt"
      >
        UPDATE
      </button>
    );
  }

  renderImageRemoveButton() {
    return (
      <button
        type="button"
        disabled={this.state.imageButtonsDisabled}
        name="removeApplicationImageButton"
        onClick={this.onDeleteImage}
        className="btn btn-default"
      >
        REMOVE
      </button>
    );
  }

  renderAvatar() {
    const { organizationImage } = this.state;
    let image = organizationImage || imageAssets.FUZION_AVATAR;
    return (
      <div className="form-group">
        <div className="field-label">Organization Image</div>
        <img src={image} className="avatar-upload" alt="Organization Profile" />
        <input
          type="file"
          name="organization_image"
          style={{ display: "none" }}
          ref={this.fileUploader}
          onChange={this.onChangeFile}
          accept="image/gif, image/png, image/jpeg"
        />
        <div className="avatar-button-container">
          {this.renderImageUpdateButton()}
          {organizationImage && this.renderImageRemoveButton()}
        </div>
        <div className="field-label">
          PNG, JPG or GIF format. Recommended dimensions: 300px x 300px.
        </div>
      </div>
    );
  }

  renderOrganization() {
    const { initialValues } = this.props;
    const { isInsert } = this.state;
    const style = this.getStyle(isInsert);
    const initialLength =
      initialValues && initialValues.partner_description
        ? initialValues.partner_description.length
        : 0;

    return (
      <div>
        {!isInsert && this.renderAvatar()}
        <div className="form-group">
          <div className="field-label">Name *</div>
          <div>
            <input
              name={this.state.partner_name.key}
              type="text"
              value={this.state.partner_name.value}
              onChange={this.onChange}
              onBlur={this.onBlur}
              className={style.partnerNameErrStyle}
              placeholder="Name"
            />
          </div>
          <div className="field-label">This name is used in the UI</div>
          {!this.state.partner_name.valid && (
            <div className="custom-text-danger">
              {this.state.partner_name.errorMessage}
            </div>
          )}
        </div>
        <div className="form-group">
          <div className="field-label">Description (optional)</div>
          <div>
            <TextArea
              name={this.state.partner_description.key}
              value={this.state.partner_description.value}
              onChange={this.onChange}
              onBlur={this.onBlur}
              label="Description"
              rows={5}
              showLength={true}
              initialLength={initialLength}
              totalLength={256}
              className={style.partnerDescriptionErrStyle}
            ></TextArea>
            {!this.state.partner_description.valid && (
              <div className="custom-text-danger">
                {this.state.partner_description.errorMessage}
              </div>
            )}
          </div>
        </div>
        <div className="form-group">
          <div className="field-label">Status</div>
          <div>
            <Select
              name={this.state.status_flag.key}
              value={this.state.status_flag.value}
              onChange={this.onChange}
              onBlur={this.onBlur}
              disabled={isInsert ? true : undefined}
              options={{
                1: "Active",
                0: "Inactive"
              }}
              className={style.statusFlagErrStyle}
            ></Select>
          </div>
          <div className="field-label">
            All new organizations are inactive until contact info has been added
          </div>
          {!this.state.status_flag.valid && (
            <div className="custom-text-danger">
              {this.state.status_flag.errorMessage}
            </div>
          )}
        </div>
      </div>
    );
  }

  renderContact() {
    const style = this.getStyle(this.state.isInsert);
    return (
      <div>
        <div>Point of Contact</div>
        <div className="form-group">
          <div className="field-label">Name *</div>
          <div>
            <div>
              <input
                name={this.state.contact_first_name.key}
                type="text"
                value={this.state.contact_first_name.value}
                onChange={this.onChange}
                onBlur={this.onBlur}
                className={style.contactFirstNameErrStyle}
                placeholder="First Name"
              />
              {!this.state.contact_first_name.valid && (
                <div className="custom-text-danger">
                  {this.state.contact_first_name.errorMessage}
                </div>
              )}
              <br />
              <input
                name={this.state.contact_last_name.key}
                type="text"
                value={this.state.contact_last_name.value}
                onChange={this.onChange}
                onBlur={this.onBlur}
                className={style.contactLastNameErrStyle}
                placeholder="Last Name"
              />
              {!this.state.contact_last_name.valid && (
                <div className="custom-text-danger">
                  {this.state.contact_last_name.errorMessage}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="field-label">Email Address *</div>
          <div>
            <input
              name={this.state.contact_email.key}
              type="text"
              value={this.state.contact_email.value}
              onChange={this.onChange}
              onBlur={this.onEmailBlur}
              className={style.contactEmailErrStyle}
              placeholder="email@address.com"
            />
            {!this.state.contact_email.valid && (
              <div className="custom-text-danger">
                {this.state.contact_email.errorMessage}
              </div>
            )}
          </div>
        </div>
        <div className="form-group">
          <div className="field-label">Phone Number (optional)</div>
          <div>
            <input
              name={this.state.contact_phone_number.key}
              type="text"
              value={this.state.contact_phone_number.value}
              onChange={this.onChange}
              onBlur={this.onBlur}
              className={style.contactPhoneNumberErrStyle}
              placeholder="+1-###-###-####"
            />
            {!this.state.contact_phone_number.valid && (
              <div className="custom-text-danger">
                {this.state.contact_phone_number.errorMessage}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  renderAddress() {
    const style = this.getStyle(this.state.isInsert);
    return (
      <div>
        <div>Address</div>
        <div className="form-group">
          <div className="field-label">Address (optional)</div>
          <div>
            <input
              name={this.state.address_address_line_one.key}
              type="text"
              value={this.state.address_address_line_one.value}
              onChange={this.onChange}
              onBlur={this.onBlur}
              className={style.addressLineOneErrStyle}
              placeholder="Address 1"
            />
            {!this.state.address_address_line_one.valid && (
              <div className="custom-text-danger">
                {this.state.address_address_line_one.errorMessage}
              </div>
            )}
            <br />
            <input
              name={this.state.address_address_line_two.key}
              type="text"
              value={this.state.address_address_line_two.value}
              onChange={this.onChange}
              onBlur={this.onBlur}
              className={style.addressLineTwoErrStyle}
              placeholder="Address 2"
            />
            {!this.state.address_address_line_two.valid && (
              <div className="custom-text-danger">
                {this.state.address_address_line_two.errorMessage}
              </div>
            )}
            <br />
            <input
              name={this.state.address_address_line_three.key}
              type="text"
              value={this.state.address_address_line_three.value}
              onChange={this.onChange}
              onBlur={this.onBlur}
              className={style.addressLineThreeErrStyle}
              placeholder="Address 3"
            />
            {!this.state.address_address_line_three.valid && (
              <div className="custom-text-danger">
                {this.state.address_address_line_three.errorMessage}
              </div>
            )}
          </div>
        </div>
        <div className="form-group">
          <div className="field-label">City (optional)</div>
          <div>
            <input
              name={this.state.address_city.key}
              type="text"
              value={this.state.address_city.value}
              onChange={this.onChange}
              onBlur={this.onBlur}
              className={style.addressCityErrStyle}
              placeholder="City"
            />
            {!this.state.address_city.valid && (
              <div className="custom-text-danger">
                {this.state.address_city.errorMessage}
              </div>
            )}
          </div>
        </div>
        <div className="form-group">
          <div className="field-label">State / Province (optional)</div>
          <div>
            <input
              name={this.state.address_state_province.key}
              type="text"
              value={this.state.address_state_province.value}
              onChange={this.onChange}
              onBlur={this.onBlur}
              className={style.addressStateProvinceErrStyle}
              placeholder="State"
            />
            {!this.state.address_state_province.valid && (
              <div className="custom-text-danger">
                {this.state.address_state_province.errorMessage}
              </div>
            )}
          </div>
        </div>
        <div className="form-group">
          <div className="field-label">Postal / Zip Code (optional)</div>
          <div>
            <input
              name={this.state.address_postal_code.key}
              type="text"
              value={this.state.address_postal_code.value}
              onChange={this.onChange}
              onBlur={this.onBlur}
              className={style.addressPostalCodeErrStyle}
              placeholder="Postal Code"
            />
            {!this.state.address_postal_code.valid && (
              <div className="custom-text-danger">
                {this.state.address_postal_code.errorMessage}
              </div>
            )}
          </div>
        </div>
        <div className="form-group">
          <div className="field-label">Country (optional)</div>
          <div>
            <input
              name={this.state.address_country.key}
              type="text"
              value={this.state.address_country.value}
              onChange={this.onChange}
              onBlur={this.onBlur}
              className={style.addressCountryErrStyle}
              placeholder="Country"
            />
            {!this.state.address_country.valid && (
              <div className="custom-text-danger">
                {this.state.address_country.errorMessage}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  renderSubmitButton(options) {
    let { permissions } = this.props;

    let canUpdate =
      permissions.filter(p => p.permission_name === "ORGANIZATION_UPDATE")
        .length > 0;
    let submitText = options.isInsert ? "SAVE" : "UPDATE";
    if (canUpdate) {
      return (
        <button
          type="button"
          onClick={this.onSubmit}
          name="saveOrganizationButton"
          className={options.submitStyle}
        >
          {submitText}
        </button>
      );
    } else {
      return null;
    }
  }

  renderCancelButton(options) {
    const { onSave } = this.props;
    return (
      <button
        type="button"
        onClick={onSave}
        name="cancelOrganizationButton"
        className={options.otherStyle}
      >
        CANCEL
      </button>
    );
  }

  renderDeleteButton(options) {
    let { permissions } = this.props;
    let canDelete =
      permissions.filter(p => p.permission_name === "ORGANIZATION_DELETE")
        .length > 0;
    if (canDelete) {
      return (
        <button
          type="button"
          onClick={() => {
            this.setState({ deleteConfirmationOpen: true });
          }} //{this.onDelete}
          name="deleteOrganizationButton"
          className={options.otherStyle}
        >
          DELETE ORGANIZATION
        </button>
      );
    } else {
      return null;
    }
  }

  renderButtons() {
    const { isInsert } = this.state;
    const style = this.getStyle(isInsert);
    return (
      <div className="form-group">
        <div className={style.btnContainer}>
          {isInsert &&
            this.renderCancelButton({
              otherStyle: style.otherStyle
            })}
          {this.renderSubmitButton({
            isInsert: isInsert,
            submitStyle: style.submitStyle
          })}
          {!isInsert &&
            this.renderDeleteButton({
              otherStyle: style.otherStyle
            })}
        </div>
        <div className={style.requiredLabel}>* Required</div>
      </div>
    );
  }

  getStyle(isInsert) {
    const partnerNameErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !this.state.partner_name.valid
    });
    const partnerDescriptionErrStyle = renderClassNames({
      "form-control-textarea": true,
      "custom-danger-border": !this.state.partner_description.valid
    });
    const statusFlagErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !this.state.status_flag.valid
    });
    const contactFirstNameErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !isInsert && !this.state.contact_first_name.valid
    });
    const contactLastNameErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !isInsert && !this.state.contact_last_name.valid
    });
    const contactEmailErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !isInsert && !this.state.contact_email.valid
    });
    const contactPhoneNumberErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border":
        !isInsert && !this.state.contact_phone_number.valid
    });
    const addressLineOneErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border":
        !isInsert && !this.state.address_address_line_one.valid
    });
    const addressLineTwoErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border":
        !isInsert && !this.state.address_address_line_two.valid
    });
    const addressLineThreeErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border":
        !isInsert && !this.state.address_address_line_three.valid
    });
    const addressCityErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !isInsert && !this.state.address_city.valid
    });
    const addressStateProvinceErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border":
        !isInsert && !this.state.address_state_province.valid
    });
    const addressPostalCodeErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !isInsert && !this.state.address_postal_code.valid
    });
    const addressCountryErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !isInsert && !this.state.address_country.valid
    });
    return {
      otherStyle: isInsert ? "btn btn-default pull-right" : "btn btn-default",
      submitStyle: isInsert ? "btn btn-primary pull-right" : "btn btn-primary",
      submitText: isInsert ? "CREATE" : "SAVE",
      btnContainer: isInsert
        ? "formAddButtonContainer"
        : "formEditButtonContainer",
      requiredLabel: isInsert ? "required-label-jpom" : "required-label",
      partnerNameErrStyle: partnerNameErrStyle,
      partnerDescriptionErrStyle: partnerDescriptionErrStyle,
      statusFlagErrStyle: statusFlagErrStyle,
      contactFirstNameErrStyle: contactFirstNameErrStyle,
      contactLastNameErrStyle: contactLastNameErrStyle,
      contactEmailErrStyle: contactEmailErrStyle,
      contactPhoneNumberErrStyle: contactPhoneNumberErrStyle,
      addressLineOneErrStyle: addressLineOneErrStyle,
      addressLineTwoErrStyle: addressLineTwoErrStyle,
      addressLineThreeErrStyle: addressLineThreeErrStyle,
      addressCityErrStyle: addressCityErrStyle,
      addressStateProvinceErrStyle: addressStateProvinceErrStyle,
      addressPostalCodeErrStyle: addressPostalCodeErrStyle,
      addressCountryErrStyle: addressCountryErrStyle
    };
  }

  render() {
    let { permissions } = this.props;
    const { initialValues } = this.props;
    if (!permissions) return null;
    const { isInsert } = this.state;
    return (
      <div>
        {this.props.title && this.renderTitle()}
        <div className="modal-form">
          <div>
            <form>
              {!isInsert && initialValues.length < 0 && (
                <div>
                  <Loading />
                </div>
              )}
              {this.renderOrganization()}
              {isInsert || this.renderContact()}
              {isInsert || this.renderAddress()}
              {this.renderButtons()}
            </form>
          </div>
        </div>
        <ConfirmationModal
          open={this.state.deleteConfirmationOpen}
          header="Delete Organization?"
          text="By deleting this organization all applications associated to the organization will be deactivated."
          leftLabel="DELETE"
          onLeft={this.onDelete}
          rightLabel="CANCEL"
          onClose={() => this.setState({ deleteConfirmationOpen: false })}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    permissions: state.activeUser.role_permissions,
    organizations: state.organizations
  };
};

export default connect(mapStateToProps, {
  createOrganization,
  saveOrganization,
  deleteOrganization,
  updateOrganization,
  setNotification,
  saveImage,
  deleteImage
})(withAuth(withRouter(changes(OrganizationEditForm))));
