import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ConfirmationModal from "lib/ui/confirmationModal";
import { setNotification } from "actions/notifications";
import {
  required,
  minLength3,
  maxLength256
} from "lib/validation/form_validation_rules";
import TextArea from "lib/ui/form/text_area_non_redux";
import Select from "lib/ui/form/select_non_redux";
import changes from "components/hoc/unsaved_changes";
import {
  saveCategory,
  updateCategory,
  deleteCategory
} from "actions/categories";
import formHelper from "lib/ui/form/form";
import renderClassNames from "lib/ui/render_class_names";

export class AppCategoriesEditForm extends Component {
  constructor(props) {
    super(props);
    let { initialValues, operation } = props;
    this.state = {
      deleteConfirmationOpen: false,
      fuzion_application_category_id: {
        key: "fuzion_application_category_id",
        value:
          (initialValues && initialValues.fuzion_application_category_id) || "",
        valid: true,
        errorMessage: ""
      },
      application_category_friendly_name: {
        key: "application_category_friendly_name",
        value:
          (initialValues && initialValues.application_category_friendly_name) ||
          "",
        valid: true,
        errorMessage: "",
        validation: [required, minLength3]
      },
      application_category_name: {
        key: "application_category_name",
        value: (initialValues && initialValues.application_category_name) || "",
        valid: true,
        errorMessage: "",
        validation: [required, minLength3]
      },
      application_category_description: {
        key: "application_category_description",
        value:
          (initialValues && initialValues.application_category_description) ||
          "",
        valid: true,
        errorMessage: "",
        validation: [maxLength256]
      },
      status_flag: {
        key: "status_flag",
        value:
          operation === "update" ? initialValues.status_flag.toString() : "",
        valid: true,
        errorMessage: "",
        validation: [required]
      }
    };
  }

  onSubmit = async () => {
    let { operation, saveCategory, updateCategory, initialValues } = this.props;
    const isFormValid = formHelper.isFormValid(this.state);
    if (!isFormValid.valid) {
      this.setState(isFormValid.formState);
      return;
    }
    let form = formHelper.getFormValues(this.state);
    form.status_flag = Number(form.status_flag);
    if (operation === "insert") {
      delete form.fuzion_application_category_id;
      formHelper.removeUnwantedValues(form);
      await saveCategory(form);
      this.props.setNotification("good", "Category Created");
      this.props.onSave();
    }
    if (operation === "update") {
      form.prior_status_flag = initialValues.status_flag;
      await updateCategory(form);
      this.props.setNotification("good", "Category Updated");
    }
  };

  onChange = e => {
    const {
      target: { name, value }
    } = e;
    this.setState(formHelper.mergeNewState(this.state, name, { value }));
  };

  onBlur = e => {
    const {
      target: { name, value }
    } = e;
    const validation = formHelper.validate(this.state, name, value);
    this.setState(formHelper.mergeNewState(this.state, name, validation));
  };

  deleteCategory = async () => {
    let { history, deleteCategory, initialValues } = this.props;
    initialValues.fuzion_app_category_id = this.props.match.params.fuzion_app_category_id;
    await deleteCategory(initialValues);
    history.push("/admin");
    this.props.setNotification("good", "Delete Successful");
  };

  renderTitle() {
    return <h4 className="modal-title">{this.props.title}</h4>;
  }

  renderSubmitButton(options) {
    let { permissions } = this.props;
    let canUpdate =
      permissions.filter(p => p.permission_name === "APP_CATEGORY_UPDATE")
        .length > 0;
    let submitText = options.isInsert ? "SAVE" : "UPDATE";
    if (canUpdate) {
      return (
        <button
          type="button"
          onClick={this.onSubmit}
          name="saveCategoryButton"
          className={options.submitStyle}
        >
          {submitText}
        </button>
      );
    } else {
      return "";
    }
  }

  renderDeleteButton(options) {
    let { permissions } = this.props;
    let canDelete =
      permissions.filter(p => p.permission_name === "APP_CATEGORY_DELETE")
        .length > 0;
    if (canDelete) {
      return (
        <button
          type="button"
          onClick={() => this.setState({ deleteConfirmationOpen: true })}
          name="deleteCategoryButton"
          className={options.otherStyle}
        >
          DELETE CATEGORY
        </button>
      );
    } else {
      return "";
    }
  }

  getStyle() {
    let { initialValues } = this.props;
    let isInsert = !initialValues;
    const appCategoryFriendlyNameErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !this.state.application_category_friendly_name
        .valid
    });
    const appCategoryNameErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !this.state.application_category_name.valid
    });
    const appCategoryDescErrStyle = renderClassNames({
      "form-control-textarea": true,
      "custom-danger-border": !this.state.application_category_description.valid
    });
    const appCategoryStatusErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !this.state.status_flag.valid
    });
    return {
      otherStyle: isInsert ? "btn btn-default pull-right" : "btn btn-default",
      submitStyle: isInsert ? "btn btn-primary pull-right" : "btn btn-primary",
      btnContainer: isInsert
        ? "formAddButtonContainer"
        : "formEditButtonContainer",
      requiredLabel: isInsert ? "required-label-jpom" : "required-label",
      appCategoryFriendlyNameErrStyle: appCategoryFriendlyNameErrStyle,
      appCategoryNameErrStyle: appCategoryNameErrStyle,
      appCategoryDescErrStyle: appCategoryDescErrStyle,
      appCategoryStatusErrStyle: appCategoryStatusErrStyle
    };
  }

  render() {
    let { permissions } = this.props;
    if (!permissions) return "";
    let { initialValues } = this.props;
    let isInsert = !initialValues;
    let initialLength = 0;
    if (!isInsert && initialValues.application_category_description) {
      //descr is optional - needs null-check
      initialLength = initialValues.application_category_description.length;
    }
    const style = this.getStyle();
    return (
      <div
        ref={node => {
          this.node = node;
        }}
        className="main-admin-container"
      >
        {this.props.title && this.renderTitle()}
        <form className="modal-form">
          <div className="form-group">
            <div className="field-label">Category Friendly Name *</div>
            <div>
              <input
                name={this.state.application_category_friendly_name.key}
                type="text"
                value={this.state.application_category_friendly_name.value}
                onChange={this.onChange}
                onBlur={this.onBlur}
                className={style.appCategoryFriendlyNameErrStyle}
                placeholder="Friendly Name"
              />
              <div className="field-label">This name is displayed in lists</div>
              {!this.state.application_category_friendly_name.valid && (
                <div className="custom-text-danger">
                  {this.state.application_category_friendly_name.errorMessage}
                </div>
              )}
            </div>
          </div>
          <div className="form-group">
            <div className="field-label">Category Name *</div>
            <div>
              <input
                name={this.state.application_category_name.key}
                type="text"
                value={this.state.application_category_name.value}
                onChange={this.onChange}
                onBlur={this.onBlur}
                className={style.appCategoryNameErrStyle}
                placeholder="CATEGORY_NAME"
              />
              <div className="field-label">This name is used in code</div>
              {!this.state.application_category_name.valid && (
                <div className="custom-text-danger">
                  {this.state.application_category_name.errorMessage}
                </div>
              )}
            </div>
          </div>
          <div className="form-group">
            <div className="field-label">Description (optional)</div>
            <div>
              <TextArea
                name={this.state.application_category_description.key}
                value={this.state.application_category_description.value}
                onChange={this.onChange}
                onBlur={this.onBlur}
                label="Description"
                rows={5}
                showLength={true}
                initialLength={initialLength}
                totalLength={256}
                className={style.appCategoryDescErrStyle}
              ></TextArea>
              {!this.state.application_category_description.valid && (
                <div className="custom-text-danger">
                  {this.state.application_category_description.errorMessage}
                </div>
              )}
            </div>
          </div>
          <div className="form-group">
            <div className="field-label">Status *</div>
            <div>
              <Select
                name={this.state.status_flag.key}
                value={this.state.status_flag.value}
                onChange={this.onChange}
                onBlur={this.onBlur}
                className={style.appCategoryStatusErrStyle}
                options={{
                  1: "Active",
                  0: "Inactive"
                }}
              ></Select>
            </div>
            {!this.state.status_flag.valid && (
              <div className="custom-text-danger">
                {this.state.status_flag.errorMessage}
              </div>
            )}
          </div>
          <div className="form-group">
            <div className={style.btnContainer}>
              {isInsert && (
                <button
                  type="button"
                  onClick={this.props.onSave}
                  name="cancelButton"
                  className={style.otherStyle}
                >
                  CANCEL
                </button>
              )}
              {this.renderSubmitButton({
                isInsert: isInsert,
                submitStyle: style.submitStyle
              })}
              {!isInsert &&
                this.renderDeleteButton({
                  otherStyle: style.otherStyle
                })}
            </div>
            <div className={style.requiredLabel}>* Required</div>
          </div>
        </form>
        <ConfirmationModal
          open={this.state.deleteConfirmationOpen}
          header="Delete Category?"
          text="If deleted, this category will no longer be accessible. Are you sure you want to delete?"
          leftLabel="DELETE"
          onLeft={this.deleteCategory}
          rightLabel="CANCEL"
          onClose={() => this.setState({ deleteConfirmationOpen: false })}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  let values = {
    permissions: state.activeUser.role_permissions
  };
  return values;
};

export default connect(mapStateToProps, {
  saveCategory,
  updateCategory,
  deleteCategory,
  setNotification
})(withRouter(changes(AppCategoriesEditForm)));
