import React from "react";

import userAvatar from "../../../assets/images/avatarUser.png";

import "./style.css";

const UserAvatar = ({ src }) => {
  return (
    <div>
      <img src={src || userAvatar} className="user-avatar" alt="user avatar" />
    </div>
  );
};

export default UserAvatar;
