import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { setNotification } from "actions/notifications";
import ConfirmationModal from "lib/ui/confirmationModal";
import {
  required,
  minLength3,
  maxLength256
} from "lib/validation/form_validation_rules";
import TextArea from "lib/ui/form/text_area_non_redux";
import Select from "lib/ui/form/select_non_redux";
import changes from "components/hoc/unsaved_changes";
import {
  createRole,
  getRole,
  saveRole,
  deleteRole,
  updateRole
} from "actions/roles";
import { Loading } from "components/loading/loading";
import formHelper from "lib/ui/form/form";
import renderClassNames from "lib/ui/render_class_names";

export class RolesEditForm extends Component {
  constructor(props) {
    super(props);
    let { initialValues, operation } = props;
    this.state = {
      deleteConfirmationOpen: false,
      fuzion_security_role_id:
        (initialValues && initialValues.fuzion_security_role_id) || "",
      role_friendly_name: {
        key: "role_friendly_name",
        value: (initialValues && initialValues.role_friendly_name) || "",
        valid: true,
        errorMessage: "",
        validation: [required, minLength3]
      },
      role_description: {
        key: "role_description",
        value: (initialValues && initialValues.role_description) || "",
        valid: true,
        errorMessage: "",
        validation: [maxLength256]
      },
      status_flag: {
        key: "status_flag",
        value:
          operation === "update" ? initialValues.status_flag.toString() : "",
        valid: true,
        errorMessage: "",
        validation: [required]
      }
    };
  }

  onChange = ({ target: { name, value } }) => {
    this.setState(formHelper.mergeNewState(this.state, name, { value }));
  };

  onBlur = e => {
    const {
      target: { name, value }
    } = e;
    const validation = formHelper.validate(this.state, name, value);
    this.setState(formHelper.mergeNewState(this.state, name, validation));
  };

  onSubmit = async () => {
    let { operation, saveRole, updateRole, initialValues } = this.props;
    const isFormValid = formHelper.isFormValid(this.state);
    if (!isFormValid.valid) {
      this.setState(isFormValid.formState);
      return;
    }
    let form = formHelper.getFormValues(this.state);
    form.status_flag = Number(form.status_flag);
    switch (operation) {
      case "save":
        formHelper.removeUnwantedValues(form);
        await saveRole(form);
        this.props.onSave();
        this.props.setNotification("good", "Role Created");
        break;
      case "update":
        form.prior_status_flag = Number(initialValues.status_flag);
        form.fuzion_security_role_id = initialValues.fuzion_security_role_id;
        await updateRole(form);
        this.props.setNotification("good", "Role Updated");
        break;
      default:
        console.log(`Invalid Role operation: "${operation}"`);
        break;
    }
  };

  deleteRole = async () => {
    let { history, deleteRole, initialValues } = this.props;
    initialValues.fuzion_security_role_id = this.props.match.params.fuzion_security_role_id;
    await deleteRole(initialValues);
    history.push("/admin/roles");
    this.props.setNotification("good", "Delete Successful");
  };

  renderTitle() {
    return <h4 className="modal-title">{this.props.title}</h4>;
  }

  renderSubmitButton(options) {
    let { permissions } = this.props;
    let canUpdate =
      permissions.filter(p => p.permission_name === "SECURITY_ROLE_UPDATE")
        .length > 0;
    let submitText = options.isInsert ? "SAVE" : "UPDATE";
    if (canUpdate) {
      return (
        <button
          type="button"
          name="saveRoleButton"
          onClick={this.onSubmit}
          className={options.submitStyle}
        >
          {submitText}
        </button>
      );
    } else {
      return "";
    }
  }

  renderDeleteButton(options) {
    let { permissions } = this.props;
    let canDelete =
      permissions.filter(p => p.permission_name === "SECURITY_ROLE_DELETE")
        .length > 0;
    if (canDelete) {
      return (
        <button
          type="button"
          onClick={() => this.setState({ deleteConfirmationOpen: true })}
          name="deleteRoleButton"
          className={options.otherStyle}
        >
          DELETE ROLE
        </button>
      );
    } else {
      return "";
    }
  }

  getStyle() {
    let { initialValues } = this.props;
    let isInsert = !initialValues;
    const roleFriendlyNameErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !this.state.role_friendly_name.valid
    });
    const roleDescErrStyle = renderClassNames({
      "form-control-textarea": true,
      "custom-danger-border": !this.state.role_description.valid
    });
    const statusFlagErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !this.state.status_flag.valid
    });
    return {
      otherStyle: isInsert ? "btn btn-default pull-right" : "btn btn-default",
      submitStyle: isInsert ? "btn btn-primary pull-right" : "btn btn-primary",
      bgColor: isInsert
        ? { backgroundColor: "#f4f4f4" }
        : { backgroundColor: "#ffffff" },
      btnContainer: isInsert
        ? "formAddButtonContainer"
        : "formEditButtonContainer",
      requiredLabel: isInsert ? "required-label-jpom" : "required-label",
      roleFriendlyNameErrStyle: roleFriendlyNameErrStyle,
      roleDescErrStyle: roleDescErrStyle,
      statusFlagErrStyle: statusFlagErrStyle
    };
  }

  render() {
    let { permissions, initialValues } = this.props;
    if (!permissions) return "";
    let { operation } = this.props;
    const style = this.getStyle();
    let isInsert = operation === "save";
    let initialLength =
      initialValues && initialValues.role_description
        ? initialValues.role_description.length
        : 0;

    return (
      <div
        ref={node => {
          this.node = node;
        }}
        className="main-admin-container"
      >
        {this.props.title && this.renderTitle()}
        {!isInsert && initialValues && initialValues.length < 1 && (
          <div>
            <Loading />
          </div>
        )}
        <div className="col-md-12">
          <form className="form-horizontal modal-form">
            <div className="form-group">
              <div className="field-label">Role Name *</div>
              <div>
                <input
                  name={this.state.role_friendly_name.key}
                  type="text"
                  value={this.state.role_friendly_name.value}
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                  className={style.roleFriendlyNameErrStyle}
                  placeholder="ROLE_NAME"
                  style={style.backgroundColor}
                />
                {!this.state.role_friendly_name.valid && (
                  <div className="custom-text-danger">
                    {this.state.role_friendly_name.errorMessage}
                  </div>
                )}
              </div>
              <div className="field-label">This name is used in the UI</div>
            </div>
            <div className="form-group">
              <div className="field-label">Description (optional)</div>
              <div>
                <TextArea
                  name={this.state.role_description.key}
                  value={this.state.role_description.value}
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                  label="Description"
                  bgColor={style.bgColor}
                  rows={5}
                  showLength={true}
                  initialLength={initialLength}
                  className={style.roleDescErrStyle}
                  totalLength={256}
                ></TextArea>
                {!this.state.role_description.valid && (
                  <div className="custom-text-danger">
                    {this.state.role_description.errorMessage}
                  </div>
                )}
              </div>
            </div>
            <div className="form-group">
              <div className="field-label">Status *</div>
              <div>
                <Select
                  name={this.state.status_flag.key}
                  value={this.state.status_flag.value}
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                  style={style.backgroundColor}
                  className={style.statusFlagErrStyle}
                  options={{
                    1: "Active",
                    0: "Inactive"
                  }}
                ></Select>
              </div>
              {!this.state.status_flag.valid && (
                <div className="custom-text-danger">
                  {this.state.status_flag.errorMessage}
                </div>
              )}
            </div>
            <div className="form-group">
              <div className={style.btnContainer}>
                {isInsert && (
                  <button
                    type="button"
                    onClick={this.props.onSave}
                    name="cancelButton"
                    className={style.otherStyle}
                  >
                    CANCEL
                  </button>
                )}
                {this.renderSubmitButton({
                  isInsert: isInsert,
                  submitStyle: style.submitStyle
                })}
                {!isInsert &&
                  this.renderDeleteButton({
                    otherStyle: style.otherStyle
                  })}
              </div>
              <div className={style.requiredLabel}>* Required</div>
            </div>
          </form>
          <ConfirmationModal
            open={this.state.deleteConfirmationOpen}
            header="Delete Role?"
            text="If deleted, this role will no longer be accessible. Are you sure you want to delete?"
            leftLabel="DELETE"
            onLeft={this.deleteRole}
            rightLabel="CANCEL"
            onClose={() => this.setState({ deleteConfirmationOpen: false })}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  let values = {
    permissions: state.activeUser.role_permissions
  };

  return values;
};

export default connect(mapStateToProps, {
  createRole,
  getRole,
  saveRole,
  deleteRole,
  updateRole,
  setNotification
})(withRouter(changes(RolesEditForm)));
