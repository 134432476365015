import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";

import withAuth from "components/hoc/with_auth";
import Header from "components/header/header";
import ApiCategoriesList from "./list/api_category_list";
import ApiCategoriesEditForm from "./api_category_edit_form";

import { getApiCategory, getApiCategories } from "actions/api_categories";

const PAGE_SIZE = 1000;

class ApiCategoryList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      start: 0,
      pageSize: PAGE_SIZE
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    let { start, pageSize } = this.state;
    this.props.getApiCategories(start, pageSize);
  }

  onOpenModal = () => {
    this.setState({ open: true });
    this.props.getApiCategory(null); //clear for insert
  };

  onCloseModal = () => {
    this.setState({ open: false });
    this.loadData();
  };

  render() {
    let { permissions } = this.props;
    if (!permissions) return null;
    let canCreate =
      permissions.filter(p => p.permission_name === "API_CATEGORY_CREATE")
        .length > 0;
    return (
      <>
        <Header />
        <div className="container-fluid">
          <div className="col-sm-9 col-sm-offset-3 col-md-10 col-md-offset-2 main">
            <h1 className="page-header">APIs</h1>
            <div className="body-content">
              {canCreate && (
                <button
                  onClick={this.onOpenModal}
                  className="btn btn-primary body-content-btn"
                >
                  + API
                </button>
              )}
            </div>
            <div className="body-content-partial">
              <ApiCategoriesList />
              {/* RESULTS_PAGING <p><a href="#" onClick={this.onLastClick}>&lt;Last</a>&nbsp;<a href="#" onClick={this.onNextClick}>Next&gt;</a></p> */}
            </div>
          </div>
          <Modal
            open={this.state.open}
            onClose={this.onCloseModal}
            classNames={{ modal: "custom-modal" }}
            closeOnOverlayClick={false}
            showCloseIcon={false}
          >
            <ApiCategoriesEditForm
              title="New API Category"
              onSave={this.onCloseModal}
              operation="insert"
              initialValues={this.props.apiCategory}
            />
          </Modal>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    apiCategories: state.apiCategories,
    apiCategory: state.apiCategory,
    permissions: state.activeUser.role_permissions
  };
};

export default connect(mapStateToProps, {
  getApiCategory,
  getApiCategories
})(withAuth(ApiCategoryList));
