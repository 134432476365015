import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import OrganizationAppEditForm from "./organization_app_edit_form";
import { getApplication } from "actions/applications";
import { getCategories } from "actions/categories";

class OrganizationAppSettings extends Component {
  componentDidMount() {
    let { getApplication, getCategories } = this.props;
    let {
      fuzion_organization_app_id,
      fuzion_organization_id
    } = this.props.match.params;
    if (fuzion_organization_app_id) {
      getApplication(fuzion_organization_app_id, fuzion_organization_id);
    }
    getCategories(0, 100);
  }

  render() {
    return (
      <div className="container-fluid tabs-spacer">
        <div className="col-sm-9 col-sm-offset-3 col-md-10 col-md-offset-2 main">
          <h1 className="page-header">Organization Application Settings</h1>
          {this.props.application &&
            Object.keys(this.props.application).length > 0 && (
              <OrganizationAppEditForm
                key={this.props.application.fuzion_application_id}
                operation="update"
                initialValues={this.props.application}
              />
            )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    categories: state.categories,
    application: state.application
  };
};

export default connect(mapStateToProps, {
  getApplication,
  getCategories
})(withRouter(OrganizationAppSettings));
