import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";

import withAuth from "components/hoc/with_auth";
import RoleList from "./roles_list";

import { getRole, getRoles } from "actions/roles";
import RoleEditForm from "./roles/role_edit_form";

export class Roles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    this.props.getRoles(0, 1000);
  }

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
    this.loadData();
  };

  render() {
    let { permissions } = this.props;
    if (!permissions) return "";
    let canCreate =
      permissions.filter(p => p.permission_name === "SECURITY_ROLE_CREATE")
        .length > 0;
    return (
      <div className="container-fluid tabs-spacer">
        <div className="col-sm-9 col-sm-offset-3 col-md-10 col-md-offset-2 main">
          <h1 className="page-header">Roles</h1>
          <div className="body-content">
            {canCreate && (
              <button
                onClick={this.onOpenModal}
                className="btn btn-primary body-content-btn"
              >
                + ROLE
              </button>
            )}
          </div>
          <div className="body-content-partial">
            <RoleList />
          </div>
        </div>
        <Modal
          open={this.state.open}
          onClose={this.onCloseModal}
          classNames={{ modal: "custom-modal" }}
          closeOnOverlayClick={false}
          showCloseIcon={false}
        >
          <RoleEditForm
            title="New Role"
            onSave={this.onCloseModal}
            operation="save"
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    permissions: state.activeUser.role_permissions,
    roles: state.roles
  };
};

export default connect(mapStateToProps, {
  getRole,
  getRoles
})(withAuth(Roles));
