import React, { Component } from "react";
import Modal from "react-responsive-modal";

const ConfirmationModalDefaults = {
  open: false,
  header: "Comfirming something?",
  text: "You are about to change something!",
  leftLabel: null,
  rightLabel: null,
  onLeft: () => console.log("ConfirmationModalDefaults:onLeft"),
  onRight: () => console.log("ConfirmationModalDefaults:onRight"),
  onClose: () => console.log("ConfirmationModalDefaults:onClose")
};

class ConfirmationModal extends Component {
  constructor(props) {
    super(props);
    this.state = { ...ConfirmationModalDefaults, ...props };
  }

  leftClick = () => {
    const { onLeft, onClose } = this.state;
    onLeft();
    onClose();
  };

  rightClick = () => {
    const { onRight, onClose } = this.state;
    onRight();
    onClose();
  };

  render = () => {
    const {
      header,
      text,
      leftLabel,
      rightLabel,
      onClose,
      leftStyle
    } = this.state;
    const open = this.props.open ? this.props.open : this.state.open;
    let leftButtonClassNames = "btn btn-primary pull-right";
    if (leftStyle) {
      leftButtonClassNames = leftStyle + " " + leftButtonClassNames;
    }
    return (
      <Modal
        open={open}
        classNames={{ modal: "custom-modal" }}
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        <div className="custom-modal-dialog" role="document">
          <div className="">
            <div className="custom-modal-header">
              <h5 className="custom-modal-header-text">{header}</h5>
            </div>
            <div className="custom-modal-text">
              <p>{text}</p>
            </div>
            <div className="custom-modal-footer">
              {leftLabel === null ? null : (
                <button
                  type="button"
                  className={leftButtonClassNames}
                  onClick={this.leftClick}
                  name="leftUserButton"
                >
                  {leftLabel}
                </button>
              )}
              {rightLabel === null ? null : (
                <button
                  type="button"
                  className="btn btn-secondary cancelButton pull-right"
                  onClick={this.rightClick}
                  name="rightUserButton"
                  data-dismiss="modal"
                >
                  {rightLabel}
                </button>
              )}
            </div>
          </div>
        </div>
      </Modal>
    );
  };
}

export default ConfirmationModal;
