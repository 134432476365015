export default {
  CREATE_APPLICATION_KEY: "CREATE_APPLICATION_KEY",
  CLEAR_APPLICATION_KEY: "CLEAR_APPLICATION_KEY",
  GET_APPLICATION_KEYS: "GET_APPLICATION_KEYS",
  GET_APPLICATION_KEY: "GET_APPLICATION_KEY",
  SAVE_APPLICATION_KEY: "SAVE_APPLICATION_KEY",
  UPDATE_APPLICATION_KEY: "UPDATE_APPLICATION_KEY",
  DELETE_APPLICATION_KEY: "DELETE_APPLICATION_KEY",
  RELOAD_APPLICATION_KEY: "RELOAD_APPLICATION_KEY"
};
