import React, { Component } from "react";

import iconExitDark from "assets/images/iconExitDark.svg";
import "./clear_entry.css";

class ClearEntry extends Component {
  render() {
    return (
      <div
        className="clear-entry"
        onClick={this.props.clear}
        onKeyPress={e => {
          if (e.key === "Enter") {
            this.props.clearr();
          }
        }}
        role="button"
        tabIndex={0}
      >
        <img src={iconExitDark} alt="Clear Entry" />
      </div>
    );
  }
}

export default ClearEntry;
