import React, { Component } from "react";

import deleteIcon from "assets/images/iconDeleteDark.svg";
import downloadIcon from "assets/images/iconDownloadDark.svg";

class ReportControls extends Component {
  renderDownloadIcon() {
    return (
      <button
        className="list-image-section-button"
        onClick={() => this.props.onDownload(this.props.id)}
      >
        <img src={downloadIcon} className="list-icon" alt="List Item" />
      </button>
    );
  }

  renderDeleteIcon() {
    return (
      <button
        className="list-image-section-button"
        onClick={() => this.props.onDelete(this.props.id)}
      >
        <img src={deleteIcon} className="list-icon" alt="List Item" />
      </button>
    );
  }

  render() {
    let { match } = this.props;
    return (
      <div className="list-image-section">
        {this.renderDownloadIcon(match)}
        {this.renderDeleteIcon(match)}
      </div>
    );
  }
}

export default ReportControls;
