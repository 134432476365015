import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { setNotification } from "../../../actions/notifications";
import { displayMapping } from "../../../actions/mappings";
import {
  required,
  maxLength256,
  minLength2,
  commaSeparatedString
} from "../../../lib/validation/form_validation_rules";
import changes from "../../hoc/unsaved_changes";
import { Loading } from "../../loading/loading";
import formHelper from "../../../lib/ui/form/form";
import renderClassNames from "../../../lib/ui/render_class_names";
import TextArea from "../../../lib/ui/form/text_area_non_redux";
import Select from "../../../lib/ui/form/select_non_redux";
import {
  lodashSet,
  lodashFind,
  lodashFindIndex,
  lodashPullAt,
  lodashRemove
} from "../../../lib/data/lodash";
import { jsonParser } from "fuzion-core-lib";

class MappingEditFieldForm extends Component {
  constructor(props) {
    super(props);
    let { insert, isDelete, initialSelectedField } = this.props;
    this.state = {
      isInsert: insert,
      isDelete: isDelete,
      deleteConfirmationOpen: isDelete,
      field_name: {
        key: "field_name",
        value: (initialSelectedField && initialSelectedField.field) || "",
        valid: true,
        errorMessage: "",
        validation: [required, minLength2, maxLength256]
      },
      maps_to: {
        key: "maps_to",
        value: (initialSelectedField && initialSelectedField.maps_to) || "",
        valid: true,
        errorMessage: "",
        validation: [maxLength256]
      },
      field_type: {
        key: "field_type",
        value: (initialSelectedField && initialSelectedField.type) || "",
        valid: true,
        errorMessage: "",
        validation: [required, maxLength256]
      },
      field_value: {
        key: "field_value",
        value: (initialSelectedField && initialSelectedField.value) || "",
        valid: true,
        errorMessage: "",
        validation: [maxLength256]
      },
      dictionary: {
        key: "dictionary",
        value:
          (initialSelectedField &&
            this.getFormattedPossibleValues(initialSelectedField.dictionary)) ||
          "",
        valid: true,
        errorMessage: "",
        validation: [maxLength256, commaSeparatedString]
      }
    };
  }

  getFormattedPossibleValues(array) {
    let result = "";
    result = (array && array.join(",")) || "";
    return result;
  }

  getPossibleValuesArray(commaSeparatedString) {
    let result = [];
    result =
      commaSeparatedString &&
      commaSeparatedString
        .split(",")
        .map(item => item.replace(/\n|\r/g, "").trim());
    return result;
  }

  getFormattedFieldJSON() {
    let {
      field_name,
      maps_to,
      dictionary,
      field_value,
      field_type
    } = this.state;
    let fieldJSON = {
      field: field_name.value.toLowerCase(),
      maps_to: maps_to.value || null,
      type: field_type.value,
      dictionary:
        (dictionary.value && this.getPossibleValuesArray(dictionary.value)) ||
        null,
      value: field_value.value,
      conditions: null
    };
    return fieldJSON;
  }

  onChange = e => {
    const {
      target: { name, value }
    } = e;
    this.setState(formHelper.mergeNewState(this.state, name, { value }));
  };

  onBlur = e => {
    const {
      target: { name, value }
    } = e;
    const validation = formHelper.validate(this.state, name, value);
    this.setState(formHelper.mergeNewState(this.state, name, validation));
  };

  onFieldNameBlur = e => {
    const {
      target: { name, value }
    } = e;
    let validation = formHelper.validate(this.state, name, value.toLowerCase());
    this.setState(
      formHelper.mergeNewState(this.state, name, {
        ...validation,
        value: value.toLowerCase()
      })
    );
  };

  validateFieldValue() {
    let { dictionary, field_type, field_value } = this.state;
    let value = field_value.value;
    let dictionaryArray = this.getPossibleValuesArray(dictionary.value);
    if (
      field_type.value === "integer" &&
      field_value.value &&
      isNaN(Number(field_value.value))
    ) {
      const newFieldValue = Object.assign(
        {},
        {
          ...this.state.field_value,
          valid: false,
          value: value.trim(),
          errorMessage: "Value must be an integer."
        }
      );
      this.setState({ field_value: newFieldValue });
      return false;
    } else if (
      field_value.value &&
      dictionaryArray.length > 0 &&
      !dictionaryArray.includes(value.trim())
    ) {
      const newFieldValue = Object.assign(
        {},
        {
          ...this.state.field_value,
          valid: false,
          value: value.trim(),
          errorMessage: "Value does not exist in possible values."
        }
      );
      this.setState({ field_value: newFieldValue });
      return false;
    } else {
      return true;
    }
  }

  onSave = async () => {
    let {
      operation,
      initialMapping,
      selectedType,
      setNotification,
      selectedFieldIndex
    } = this.props;
    let mapping =
      jsonParser(initialMapping.mappings) || initialMapping.mappings;
    let updatedJson = {};
    let newFieldIndex = 0;

    const isFormValid = formHelper.isFormValid(this.state);
    if (!isFormValid.valid) {
      this.setState(isFormValid.formState);
      return;
    }

    let typeIndex = lodashFindIndex(mapping.types, {
      type: selectedType
    });
    let mappingType = lodashFind(mapping.types, { type: selectedType });
    let formattedFieldJSON = this.getFormattedFieldJSON();

    if (
      mappingType.fields &&
      !this.validateDuplicateFields(mappingType.fields, selectedFieldIndex)
    ) {
      const newFieldName = Object.assign(
        {},
        {
          ...this.state.field_name,
          valid: false,
          errorMessage: "This field name already exists with no conditions."
        }
      );
      this.setState({ field_name: newFieldName });
      return;
    }

    if (!this.validateFieldValue()) {
      return;
    }

    switch (operation) {
      case "insert":
        newFieldIndex = (mappingType.fields && mappingType.fields.length) || 0;
        updatedJson = lodashSet(
          mapping,
          "types[" + typeIndex + "].fields[" + newFieldIndex + "]",
          formattedFieldJSON
        );
        initialMapping.mappings = updatedJson;
        this.props.displayMapping(initialMapping);
        this.onClose();
        setNotification("good", "Mapping Field added");
        break;
      case "update":
        updatedJson = lodashSet(
          mapping,
          "types[" + typeIndex + "].fields[" + selectedFieldIndex + "]",
          formattedFieldJSON
        );
        initialMapping.mappings = updatedJson;
        this.props.displayMapping(initialMapping);
        this.onClose();
        setNotification("good", "Mapping Field Updated");
        break;
      default:
        break;
    }
  };

  validateDuplicateFields(fields, selectedFieldIndex) {
    let validateFields = [];
    fields.forEach(field => {
      validateFields.push(field);
    });
    if (this.props.operation !== "insert") {
      validateFields.splice(selectedFieldIndex, 1);
    }
    let result = validateFields.filter(
      m =>
        m.field.trim().toLowerCase() ===
          this.state.field_name.value.trim().toLowerCase() &&
        (m.conditions === null || (m.conditions && m.conditions.length === 0))
    );
    if (result && result.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  onDelete = () => {
    let {
      initialMapping,
      setNotification,
      selectedType,
      selectedFieldIndex
    } = this.props;
    let mapping =
      jsonParser(initialMapping.mappings) || initialMapping.mappings;
    let mappingType = lodashFind(mapping.types, { type: selectedType });
    let mappingTypeIndex = lodashFindIndex(mapping.types, {
      type: selectedType
    });
    let removeType = mappingType.fields.length === 1;
    //Using lodash to remove based on index
    lodashPullAt(mappingType.fields, selectedFieldIndex);
    let updatedJson = {};
    //Set mappings JSON with the current field removed andr/Remove Type if the current field getting deleted is the last field
    if (removeType) {
      lodashRemove(mapping.types, { type: selectedType });
      initialMapping.mappings = mapping;
    } else {
      updatedJson = lodashSet(
        mapping,
        "types[" + mappingTypeIndex + "]",
        mappingType
      );
      initialMapping.mappings = updatedJson;
    }

    this.props.displayMapping(initialMapping);
    this.onClose();
    setNotification("good", "Mapping Field Deleted");
  };

  onClose = () => {
    this.props.onClose();
  };

  getStyle = isInsert => {
    const fieldNameErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !this.state.field_name.valid
    });
    const mapsToErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !this.state.maps_to.valid
    });
    const typeErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !this.state.field_type.valid
    });
    const dictionaryErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !this.state.dictionary.valid
    });
    const fieldValueErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !this.state.field_value.valid
    });
    return {
      fieldNameErrStyle: fieldNameErrStyle,
      mapsToErrStyle: mapsToErrStyle,
      typeErrStyle: typeErrStyle,
      dictionaryErrStyle: dictionaryErrStyle,
      fieldValueErrStyle: fieldValueErrStyle,
      otherStyle: "btn btn-default",
      deleteStyle: "btn btn-primary btn-delete",
      submitStyle: isInsert ? "btn btn-primary pull-right" : "btn btn-primary",
      submitText: isInsert ? "ADD" : "SAVE",
      btnContainer: "formAddButtonContainer",
      requiredLabel: isInsert ? "required-label-jpom" : "required-label"
    };
  };

  renderSaveButton(options) {
    let { permissions } = this.props;
    let canUpdate =
      permissions.filter(p => p.permission_name === "SHOW_UPDATE").length > 0;
    if (canUpdate) {
      return (
        <button
          type="button"
          onClick={this.onSave}
          name="saveFieldButton"
          className={options.submitStyle}
        >
          {options.submitText}
        </button>
      );
    } else {
      return "";
    }
  }

  renderDeleteButton(options) {
    let { permissions } = this.props;
    let canDelete =
      permissions.filter(p => p.permission_name === "SHOW_DELETE").length > 0;
    if (canDelete) {
      return (
        <button
          type="button"
          onClick={() => this.onDelete()}
          name="deleteFieldButton"
          className={options.deleteStyle}
        >
          DELETE
        </button>
      );
    } else {
      return "";
    }
  }

  renderCancelButton(options) {
    return (
      <button
        type="button"
        onClick={() => this.onClose(null)}
        name="cancelFieldButton"
        className={options.otherStyle}
      >
        CANCEL
      </button>
    );
  }

  renderButtons = (isInsert, isDelete) => {
    const style = this.getStyle(isInsert);
    return (
      <div className={style.btnContainer}>
        {this.renderCancelButton({
          otherStyle: style.otherStyle
        })}
        {!isDelete &&
          this.renderSaveButton({
            submitStyle: style.submitStyle,
            submitText: style.submitText
          })}
        {isDelete &&
          this.renderDeleteButton({
            deleteStyle: style.deleteStyle
          })}
      </div>
    );
  };

  render() {
    const {
      initialMapping,
      initialField,
      displayConditionsWarning,
      selectedType
    } = this.props;
    const { isInsert, isDelete, isPublished, dictionary } = this.state;
    let style = this.getStyle(isInsert);
    let mapping =
      jsonParser(initialMapping.mappings) || initialMapping.mappings;
    let mappingType = lodashFind(mapping.types, { type: selectedType });
    let deleteFieldText = displayConditionsWarning
      ? " Any conditions associated to this type will be deleted as well."
      : "";
    let deleteTypeText =
      mappingType && mappingType.fields && mappingType.fields.length === 1
        ? " Type will be removed with the field."
        : "";
    const initialLength =
      initialField && dictionary.value ? dictionary.value.length : 0;
    return (
      (!isDelete && (
        <div>
          <form className="modal-form">
            <h4 className="modal-title">
              {isInsert ? "Add Field" : "Edit Field"}
            </h4>
            {!isInsert && initialMapping.length < 1 && (
              <div>
                <Loading />
              </div>
            )}
            <div className="form-group">
              <div className="field-label">Field name *</div>
              <div>
                <input
                  name={this.state.field_name.key}
                  type="text"
                  value={this.state.field_name.value}
                  placeholder="Field"
                  className={style.fieldNameErrStyle}
                  onChange={this.onChange}
                  onBlur={this.onFieldNameBlur}
                  disabled={isPublished}
                />
                {!this.state.field_name.valid && (
                  <div className="custom-text-danger">
                    {this.state.field_name.errorMessage}
                  </div>
                )}
              </div>
            </div>
            <div className="form-group">
              <div className="field-label">Maps to</div>
              <div>
                <input
                  name={this.state.maps_to.key}
                  type="text"
                  value={this.state.maps_to.value}
                  placeholder="Maps to"
                  className={style.mapsToErrStyle}
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                  disabled={isPublished}
                />
                {!this.state.maps_to.valid && (
                  <div className="custom-text-danger">
                    {this.state.maps_to.errorMessage}
                  </div>
                )}
              </div>
            </div>
            <div className="form-group">
              <div className="field-label">Field type</div>
              <div>
                <Select
                  name={this.state.field_type.key}
                  value={this.state.field_type.value}
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                  style={style.backgroundColor}
                  className={style.typeErrStyle}
                  options={{
                    text: "text",
                    integer: "integer"
                  }}
                ></Select>
              </div>
              {!this.state.field_type.valid && (
                <div className="custom-text-danger">
                  {this.state.field_type.errorMessage}
                </div>
              )}
            </div>
            <div className="form-group">
              <div className="field-label">
                Possible Value(s) - Separate each value with a comma
              </div>
              <div>
                <TextArea
                  name={this.state.dictionary.key}
                  value={this.state.dictionary.value}
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                  placeholder="Values"
                  rows={5}
                  showLength={true}
                  initialLength={initialLength}
                  totalLength={500}
                  className={style.dictionaryErrStyle}
                ></TextArea>
                {!this.state.dictionary.valid && (
                  <div className="custom-text-danger">
                    {this.state.dictionary.errorMessage}
                  </div>
                )}
              </div>
            </div>
            <div className="form-group">
              <div className="field-label">Value</div>
              <div>
                <input
                  name={this.state.field_value.key}
                  type="text"
                  placeholder="Value"
                  value={this.state.field_value.value}
                  className={style.fieldValueErrStyle}
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                  disabled={isPublished}
                />
                {!this.state.field_value.valid && (
                  <div className="custom-text-danger">
                    {this.state.field_value.errorMessage}
                  </div>
                )}
              </div>
            </div>
            <div className="form-group">
              <div className={style.requiredLabel}>* Required</div>
              {this.renderButtons(isInsert, false)}
            </div>
          </form>
        </div>
      )) ||
      (isDelete && (
        <div className="">
          <form className="modal-form">
            <h4 className="modal-title">Delete Field</h4>
            <div>
              {"Are you sure you want to delete this field?" +
                deleteFieldText +
                deleteTypeText}
            </div>
            <div className="form-group">{this.renderButtons(false, true)}</div>
          </form>
        </div>
      ))
    );
  }
}

const mapStateToProps = state => {
  let values = {
    permissions: state.activeUser.role_permissions,
    initialMapping: state.mapping
  };
  return values;
};

export default connect(mapStateToProps, {
  displayMapping,
  setNotification
})(withRouter(changes(MappingEditFieldForm)));
