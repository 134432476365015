import { request } from "lib/data/api";
import global from "types/global";
import types from "types/event_reports";

const sortEventReports = event => {
  let sorted = event.sort((a, b) => {
    return a.last_modified < b.last_modified
      ? 1
      : a.last_modified > b.last_modified
      ? -1
      : 0;
  });
  return sorted;
};

export const getExhibitorList = location => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      let response = await request({
        method: "GET",
        state: state,
        route: `/v1/event-reports/${location}`
      });
      let sortedReports = sortEventReports(response.payload);
      return dispatch({
        type: types.GET_REPORT_EXHIBITOR_LIST,
        payload: sortedReports
      });
    } catch (e) {
      return dispatch({
        type: global.ERROR,
        error: "ERROR: actions.event_reports.getExhibitorList failed"
      });
    }
  };
};

export const getExhibitorReport = location => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      let response = await request({
        method: "GET",
        state: state,
        route: `/v1/event-reports/${location}`
      });
      return dispatch({
        type: types.GET_REPORT_EXHIBITOR,
        payload: response.payload
      });
    } catch (e) {
      return dispatch({
        type: global.ERROR,
        error: "ERROR: actions.event_reports.getExhibitorReport failed"
      });
    }
  };
};

export const saveExhibitorReport = formValues => {
  return async (dispatch, getState) => {
    try {
      let state = getState();

      const response = await request({
        method: "POST",
        state: state,
        route: "/v1/event-reports",
        data: formValues
      });

      return dispatch({
        type: types.SAVE_REPORT_EXHIBITOR,
        payload: response.payload
      });
    } catch (e) {
      return dispatch({
        type: global.ERROR,
        error: "ERROR: actions.event_reports.saveExhibitorReport failed"
      });
    }
  };
};

export const deleteExhibitorReport = fuzion_event_id => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      let response = await request({
        method: "DELETE",
        state: state,
        route: `/v1/event-reports/${fuzion_event_id}`
      });
      return dispatch({
        type: types.DELETE_REPORT_EXHIBITOR,
        payload: response.payload
      });
    } catch (e) {
      return dispatch({
        type: global.ERROR,
        error: "ERROR: actions.event_reports.deleteExhibitorReport failed"
      });
    }
  };
};
