import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { setNotification } from "../../../actions/notifications";
import { displayMapping } from "../../../actions/mappings";
import {
  required,
  minLength2,
  maxLength256
} from "../../../lib/validation/form_validation_rules";
import changes from "../../hoc/unsaved_changes";
import { Loading } from "../../loading/loading";
import formHelper from "../../../lib/ui/form/form";
import renderClassNames from "../../../lib/ui/render_class_names";
import Select from "../../../lib/ui/form/select_non_redux";
import {
  lodashSet,
  lodashFind,
  lodashFindIndex,
  lodashPullAt
} from "../../../lib/data/lodash";
import { jsonParser } from "fuzion-core-lib";

class MappingEditConditionForm extends Component {
  constructor(props) {
    super(props);
    let { insert, isDelete, initialSelectedCondition } = this.props;
    let { 1: evalOperator } = Object.keys(initialSelectedCondition);
    let { 1: evalValue } = Object.values(initialSelectedCondition);
    this.state = {
      isInsert: insert,
      isDelete: isDelete,
      deleteConfirmationOpen: isDelete,
      cantDeleteConfirmationOpen: false,
      condition_field: {
        key: "condition_field",
        value:
          (initialSelectedCondition && initialSelectedCondition.field) || "",
        valid: true,
        errorMessage: "",
        validation: [required, minLength2, maxLength256]
      },
      operator: {
        key: "operator",
        value: (initialSelectedCondition && evalOperator) || "",
        valid: true,
        errorMessage: "",
        validation: [required]
      },
      condition_value: {
        key: "condition_value",
        value: (initialSelectedCondition && evalValue) || "",
        valid: true,
        errorMessage: "",
        validation: [required, maxLength256]
      }
    };
  }

  getFormattedConditionJSON() {
    let { condition_field, operator, condition_value } = this.state;
    let conditionJSON = {
      field: condition_field.value.toLowerCase()
    };
    conditionJSON[operator.value] = condition_value.value;
    return conditionJSON;
  }

  onChange = e => {
    const {
      target: { name, value }
    } = e;
    this.setState(formHelper.mergeNewState(this.state, name, { value }));
  };

  onBlur = e => {
    const {
      target: { name, value }
    } = e;
    const validation = formHelper.validate(this.state, name, value);
    this.setState(formHelper.mergeNewState(this.state, name, validation));
  };

  onSave = async () => {
    let {
      operation,
      initialMapping,
      selectedType,
      setNotification,
      selectedFieldIndex,
      selectedConditionIndex
    } = this.props;

    let mapping =
      jsonParser(initialMapping.mappings) || initialMapping.mappings;
    let updatedJson = {};
    let newConditionIndex = 0;

    const isFormValid = formHelper.isFormValid(this.state);
    if (!isFormValid.valid) {
      this.setState(isFormValid.formState);
      return;
    }

    let typeIndex = lodashFindIndex(mapping.types, {
      type: selectedType
    });
    let mappingType = lodashFind(mapping.types, { type: selectedType });
    let mappingField = mappingType.fields[selectedFieldIndex];
    let formattedConditionJSON = this.getFormattedConditionJSON();

    if (
      !this.validateDuplicateConditions(
        mappingType.fields,
        mappingField.field,
        selectedFieldIndex,
        selectedConditionIndex
      )
    ) {
      const newConditionField = Object.assign(
        {},
        {
          ...this.state.condition_field,
          valid: false,
          errorMessage: "Condition already exists for this field"
        }
      );
      this.setState({ condition_field: newConditionField });
      return;
    }

    switch (operation) {
      case "insert":
        newConditionIndex =
          (mappingField.conditions && mappingField.conditions.length) || 0;
        updatedJson = lodashSet(
          mapping,
          "types[" +
            typeIndex +
            "].fields[" +
            selectedFieldIndex +
            "].conditions[" +
            newConditionIndex +
            "]",
          formattedConditionJSON
        );
        initialMapping.mappings = updatedJson;
        this.props.displayMapping(initialMapping);
        this.onClose();
        setNotification("good", "Mapping Condition added");
        break;
      case "update":
        updatedJson = lodashSet(
          mapping,
          "types[" +
            typeIndex +
            "].fields[" +
            selectedFieldIndex +
            "].conditions[" +
            selectedConditionIndex +
            "]",
          formattedConditionJSON
        );
        initialMapping.mappings = updatedJson;
        this.props.displayMapping(initialMapping);
        this.onClose();
        setNotification("good", "Mapping Condition Updated");
        break;
      default:
        break;
    }
  };

  validateDuplicateConditions(
    fields,
    selectedFieldName,
    selectedFieldIndex,
    selectedConditionIndex
  ) {
    let validateFields = [];
    let { condition_field, operator, condition_value } = this.state;
    let duplicateFields = [];

    fields.forEach(field => {
      validateFields.push(field);
    });

    if (this.props.operation !== "insert") {
      validateFields[selectedFieldIndex].conditions.splice(
        selectedConditionIndex,
        1
      );
    }
    validateFields = validateFields.filter(
      f =>
        f.field.trim().toLowerCase() === selectedFieldName.trim().toLowerCase()
    );

    if (validateFields) {
      validateFields.forEach(function(field) {
        let { conditions } = field;
        let duplicateConditions =
          conditions &&
          conditions.filter(function(c) {
            let { 1: evalOperator } = Object.keys(c);
            let { 1: evalValue } = Object.values(c);
            if (
              c.field.trim().toLowerCase() ===
                condition_field.value.trim().toLowerCase() &&
              evalOperator === operator.value &&
              evalValue.trim().toLowerCase() ===
                condition_value.value.trim().toLowerCase()
            ) {
              return true;
            } else {
              return false;
            }
          });
        if (duplicateConditions && duplicateConditions.length > 0) {
          duplicateFields.push(field);
        }
      });
    }

    if (duplicateFields && duplicateFields.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  validateDuplicateFieldsForConditions(fields, selectedFieldIndex, fieldName) {
    let validateFields = [];
    fields.forEach(field => {
      validateFields.push(field);
    });
    if (this.props.operation !== "insert") {
      validateFields.splice(selectedFieldIndex, 1);
    }
    let result = validateFields.filter(
      m =>
        m.field.trim().toLowerCase() === fieldName.trim().toLowerCase() &&
        (m.conditions === null || (m.conditions && m.conditions.length === 0))
    );
    if (result && result.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  onDelete = () => {
    let {
      initialMapping,
      setNotification,
      selectedType,
      selectedFieldIndex,
      selectedConditionIndex
    } = this.props;
    let mapping =
      jsonParser(initialMapping.mappings) || initialMapping.mappings;
    let mappingType = lodashFind(mapping.types, { type: selectedType });
    let mappingTypeIndex = lodashFindIndex(mapping.types, {
      type: selectedType
    });
    let mappingField = mappingType.fields[selectedFieldIndex];
    //Using lodash to remove condition based on index
    let deleteCondition = true;
    if (
      mappingField.conditions.length === 1 &&
      !this.validateDuplicateFieldsForConditions(
        mappingType.fields,
        selectedFieldIndex,
        mappingField.field
      )
    ) {
      deleteCondition = false;
    }
    if (!deleteCondition) {
      this.setState({ isDelete: false });
      this.setState({ cantDeleteConfirmationOpen: true });
      return;
    }
    lodashPullAt(mappingField.conditions, selectedConditionIndex);
    let updatedJson = {};
    updatedJson = lodashSet(
      mapping,
      "types[" + mappingTypeIndex + "].fields[" + selectedFieldIndex + "]",
      mappingField
    );
    initialMapping.mappings = updatedJson;

    this.props.displayMapping(initialMapping);
    this.onClose();
    setNotification("good", "Mapping Condition Deleted");
  };

  onClose = () => {
    this.props.onClose();
  };

  getStyle = isInsert => {
    const conditionFieldErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !this.state.condition_field.valid
    });
    const operatorErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !this.state.operator.valid
    });
    const conditionValueErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !this.state.condition_value.valid
    });
    return {
      conditionFieldErrStyle: conditionFieldErrStyle,
      operatorErrStyle: operatorErrStyle,
      conditionValueErrStyle: conditionValueErrStyle,
      otherStyle: "btn btn-default",
      deleteStyle: "btn btn-primary btn-delete",
      submitStyle: isInsert ? "btn btn-primary pull-right" : "btn btn-primary",
      submitText: isInsert ? "ADD" : "SAVE",
      btnContainer: "formAddButtonContainer",
      requiredLabel: isInsert ? "required-label-jpom" : "required-label"
    };
  };

  renderSaveButton(options) {
    let { permissions } = this.props;
    let canUpdate =
      permissions.filter(p => p.permission_name === "SHOW_UPDATE").length > 0;
    if (canUpdate) {
      return (
        <button
          type="button"
          onClick={this.onSave}
          name="saveConditionButton"
          className={options.submitStyle}
        >
          {options.submitText}
        </button>
      );
    } else {
      return "";
    }
  }

  renderDeleteButton(options) {
    let { permissions } = this.props;
    let canDelete =
      permissions.filter(p => p.permission_name === "SHOW_DELETE").length > 0;
    if (canDelete) {
      return (
        <button
          type="button"
          onClick={() => this.onDelete()}
          name="deleteConditionButton"
          className={options.deleteStyle}
        >
          DELETE
        </button>
      );
    } else {
      return "";
    }
  }

  renderCancelButton(options) {
    return (
      <button
        type="button"
        onClick={() => this.onClose(null)}
        name="cancelConditionButton"
        className={options.otherStyle}
      >
        {options.cantDeleteConfirmation ? "GOT IT" : "CANCEL"}
      </button>
    );
  }

  renderButtons = (isInsert, isDelete, cantDeleteConfirmation) => {
    const style = this.getStyle(isInsert);
    return (
      <div className={style.btnContainer}>
        {this.renderCancelButton({
          otherStyle: style.otherStyle,
          cantDeleteConfirmation: cantDeleteConfirmation
        })}
        {!isDelete &&
          !cantDeleteConfirmation &&
          this.renderSaveButton({
            submitStyle: style.submitStyle,
            submitText: style.submitText
          })}
        {isDelete &&
          this.renderDeleteButton({
            deleteStyle: style.deleteStyle
          })}
      </div>
    );
  };

  render() {
    const { initialMapping } = this.props;
    const {
      isInsert,
      isDelete,
      isPublished,
      cantDeleteConfirmationOpen
    } = this.state;
    let style = this.getStyle(isInsert);
    return (
      (!isDelete && !cantDeleteConfirmationOpen && (
        <div>
          <form className="modal-form">
            <h4 className="modal-title">
              {isInsert ? "Add Condition" : "Edit Condition"}
            </h4>
            {!isInsert && initialMapping.length < 1 && (
              <div>
                <Loading />
              </div>
            )}
            <div className="form-group">
              <div className="field-label">Field *</div>
              <div>
                <input
                  name={this.state.condition_field.key}
                  type="text"
                  value={this.state.condition_field.value}
                  placeholder="Field"
                  className={style.conditionFieldErrStyle}
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                  disabled={isPublished}
                />
                {!this.state.condition_field.valid && (
                  <div className="custom-text-danger">
                    {this.state.condition_field.errorMessage}
                  </div>
                )}
              </div>
            </div>
            <div className="form-group">
              <div className="field-label">Operator *</div>
              <div>
                <Select
                  name={this.state.operator.key}
                  value={this.state.operator.value}
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                  style={style.backgroundColor}
                  className={style.operatorErrStyle}
                  options={{
                    eq: "Equals",
                    neq: "Does Not Equal",
                    lt: "Less Than",
                    gt: "Greater Than",
                    lte: "Less Than or Equal to",
                    gte: "Greater Than or Equal to",
                    contains: "Contains",
                    ncontains: "Does Not Contain",
                    starts: "Starts With",
                    ends: "Ends With"
                  }}
                ></Select>
              </div>
              {!this.state.operator.valid && (
                <div className="custom-text-danger">
                  {this.state.operator.errorMessage}
                </div>
              )}
            </div>
            <div className="form-group">
              <div className="field-label">Value *</div>
              <div>
                <input
                  name={this.state.condition_value.key}
                  type="text"
                  value={this.state.condition_value.value}
                  placeholder="Value"
                  className={style.conditionValueErrStyle}
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                  disabled={isPublished}
                />
                {!this.state.condition_value.valid && (
                  <div className="custom-text-danger">
                    {this.state.condition_value.errorMessage}
                  </div>
                )}
              </div>
            </div>
            <div className="form-group">
              <div className={style.requiredLabel}>* Required</div>
              {this.renderButtons(isInsert, false, false)}
            </div>
          </form>
        </div>
      )) ||
      (isDelete && !cantDeleteConfirmationOpen && (
        <div className="">
          <form className="modal-form">
            <h4 className="modal-title">Delete Condition</h4>
            <div>{"Are you sure you want to delete this condition?"}</div>
            <div className="form-group">
              {this.renderButtons(false, true, false)}
            </div>
          </form>
        </div>
      )) ||
      (!isDelete && cantDeleteConfirmationOpen && (
        <div className="">
          <form className="modal-form">
            <h4 className="modal-title">Can&apos;t Delete Condition!</h4>
            <div>
              {
                "This condition cannot be deleted as a field with empty conditions already exists in the mapping."
              }
            </div>
            <div className="form-group">
              {this.renderButtons(false, false, true)}
            </div>
          </form>
        </div>
      ))
    );
  }
}

const mapStateToProps = state => {
  let values = {
    permissions: state.activeUser.role_permissions,
    initialMapping: state.mapping
  };
  return values;
};

export default connect(mapStateToProps, {
  displayMapping,
  setNotification
})(withRouter(changes(MappingEditConditionForm)));
