import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getCategory } from "actions/categories";
import emptyState from "assets/images/graphicEmptyState.svg";

class Applications extends Component {
  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    let { getCategory } = this.props;
    getCategory(this.props.match.params.fuzion_app_category_id);
  };

  renderEmptyState(emptyMessage) {
    return (
      <div className="empty-state">
        <img
          className="empty-state-img"
          src={emptyState}
          alt="empty organization"
        />
        <div className="empty-state-text">{emptyMessage}</div>
      </div>
    );
  }

  render() {
    return (
      <div className="container-fluid tabs-spacer">
        <div className="col-sm-9 col-sm-offset-3 col-md-10 col-md-offset-2 main">
          <h1 className="page-header">App Category Applications</h1>
          <span className="header-sub-text">
            Category:{" "}
            {this.props.category &&
              this.props.category.application_category_friendly_name}
          </span>
          {this.renderEmptyState("Nothing in App Category Application")}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    category: state.category
  };
};

export default connect(mapStateToProps, {
  getCategory
})(withRouter(Applications));
