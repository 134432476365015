import React, { Component } from "react";
import { Route } from "react-router-dom";

import withAuth from "components/hoc/with_auth";
import EventsList from "./events_list";
import EventDetails from "./event/event_details";
import EventAppEditForm from "./event/apps/event_app_edit_form";
import EventReportExhibitor from "./event/reports/event_report_exhibitor";

class Events extends Component {
  render() {
    return (
      <span className="fuzion-nested-router">
        <Route exact path="/events" component={EventsList} />
        <Route
          exact
          path="/events/:fuzion_event_id/:tab?"
          component={EventDetails}
        />
        <Route path="/events/app-edit-form" component={EventAppEditForm} />
        <Route
          path="/events/:fuzion_event_id/reports/exhibitors_list"
          component={EventReportExhibitor}
        />
      </span>
    );
  }
}

export default withAuth(Events);
