import { request } from "lib/data/api";
import types from "types/applications";
import global from "types/global";

export const saveImage = (
  formData,
  fuzion_application_id,
  fuzion_organization_id
) => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "POST",
        state: state,
        route: "/v1/fuzion-application-image",
        data: formData,
        header: {
          fuzion_application_id,
          fuzion_organization_id
        }
      });
      dispatch({
        type: types.SAVE_APPLICATION_IMAGE,
        payload: response.payload
      });
      return response.payload;
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.application_image.saveImage failed"
      });
    }
  };
};

export const deleteImage = (fuzion_application_id, fuzion_organization_id) => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "DELETE",
        state: state,
        route: "/v1/fuzion-application-image",
        header: {
          fuzion_application_id,
          fuzion_organization_id
        }
      });
      dispatch({
        type: types.DELETE_APPLICATION_IMAGE,
        payload: response.payload
      });
      return response.payload;
    } catch (e) {
      return dispatch({
        type: global.ERROR,
        error: "ERROR: actions.application_image.deleteImage failed"
      });
    }
  };
};
