import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { connect } from "react-redux";

import Settings from "./settings";
import Header from "components/header/header";
import { getRole } from "actions/roles";

class RoleDetails extends Component {
  componentDidMount() {
    let { fuzion_security_role_id } = this.props.match.params;
    if (fuzion_security_role_id) {
      this.props.getRole(fuzion_security_role_id);
    }
  }

  render() {
    const tabListClass = `nav nav-tabs admin-tab-subheader ${this.props
      .sidebar && "sidebar-spacer"}`;
    return (
      <>
        <Header detailView={true} to="/admin/roles" />
        <div>
          <Tabs defaultIndex={0} onSelect={index => console.log(index)}>
            <TabList className={tabListClass}>
              <div className="tab-inner-container">
                <Tab className="tab">SETTINGS</Tab>
              </div>
            </TabList>
            <TabPanel>
              {this.props.role && Object.keys(this.props.role).length > 0 && (
                <Settings key={this.props.role.fuzion_security_role_id} />
              )}
            </TabPanel>
          </Tabs>
        </div>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    sidebar: state.sidebar,
    role: state.role
  };
};

export default connect(mapStateToProps, {
  getRole
})(RoleDetails);
