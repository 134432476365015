import React, { Component } from "react";
import { connect } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import withAuth from "components/hoc/with_auth";
import Header from "components/header/header";
import OrganizationEvents from "./organization_events";
import OrganizationApps from "./apps/organization_apps";
import OrganizationSettings from "./organization_settings";
import OrganizationUsers from "./organization_users";

class Organization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: this.tabs.settings, //default tab
      canViewOrganizationUsers:
        this.props.permissions.filter(
          p => p.permission_name === "USER_ORGANIZATION_VIEW"
        ).length > 0,
      canViewOrganizationApps:
        this.props.permissions.filter(
          p => p.permission_name === "ORGANIZATION_APP_VIEW"
        ).length > 0
    };
  }

  tabs = {
    events: 0,
    applications: 1,
    settings: 2,
    users: 3
  };

  indexes = ["events", "applications", "settings", "users"];

  loadTabArray = () => {
    if (!this.state.canViewOrganizationUsers) {
      let usersIndex = this.indexes.indexOf("users");
      if (usersIndex > -1) {
        this.indexes.splice(usersIndex, 1);
      }
    }

    if (!this.state.canViewOrganizationApps) {
      let appsIndex = this.indexes.indexOf("applications");
      if (appsIndex > -1) {
        this.indexes.splice(appsIndex, 1);
      }
    }

    return this.indexes;
  };

  loadTabObj = () => {
    if (!this.state.canViewOrganizationUsers) {
      delete this.tabs["users"];
    }

    if (!this.state.canViewOrganizationApps) {
      delete this.tabs["applications"];
    }

    let index = 0;
    for (let tab in this.tabs) {
      this.tabs[tab] = index;
      index++;
    }

    return this.tabs;
  };

  selectTab = () => {
    const { tab } = this.props.match.params;

    if (tab) {
      this.loadTabObj();
      const index = this.tabs[tab];
      if (index !== this.state.index) {
        this.setState({ index: index });
      }
    }
  };

  componentDidMount = this.selectTab;
  componentDidUpdate = this.selectTab;

  changeTab = index => {
    this.loadTabArray();
    const target = `/organizations/${this.props.match.params.fuzion_organization_id}/${this.indexes[index]}`;
    this.props.history.push(target);
  };

  render() {
    const tabListClass = `nav nav-tabs admin-tab-subheader ${this.props
      .sidebar && "sidebar-spacer"}`;
    return (
      <>
        <Header detailView={true} to="/organizations" />
        <Tabs selectedIndex={this.state.index} onSelect={this.changeTab}>
          <TabList className={tabListClass}>
            <div className="tab-inner-container">
              <Tab className="tab">EVENTS</Tab>
              {this.state.canViewOrganizationApps && (
                <Tab className="tab">APPLICATIONS</Tab>
              )}
              <Tab className="tab">SETTINGS</Tab>
              {this.state.canViewOrganizationUsers && (
                <Tab className="tab">USERS</Tab>
              )}
            </div>
          </TabList>
          <TabPanel>
            <OrganizationEvents />
          </TabPanel>
          {this.state.canViewOrganizationApps && (
            <TabPanel>
              <OrganizationApps />
            </TabPanel>
          )}
          <TabPanel>
            <OrganizationSettings
              key={this.props.match.params.fuzion_organization_id}
            />
          </TabPanel>
          {this.state.canViewOrganizationUsers && (
            <TabPanel>
              <OrganizationUsers />
            </TabPanel>
          )}
        </Tabs>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    sidebar: state.sidebar,
    permissions: state.activeUser.role_permissions
  };
};

export default connect(mapStateToProps)(withAuth(Organization));

//export default Organization;
