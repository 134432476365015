import { request } from "lib/data/api";
import types from "types/mappings";
import global from "types/global";

const sortMappingsByAPICategory = mappings => {
  let sorted = mappings.sort((a, b) => {
    if (
      a.fuzion_api_category.toLowerCase() > b.fuzion_api_category.toLowerCase()
    )
      return 1;
    else if (
      a.fuzion_api_category.toLowerCase() < b.fuzion_api_category.toLowerCase()
    )
      return -1;
    else {
      if (a.last_mod_timestamp > b.last_mod_timestamp) return -1;
      else if (a.last_mod_timestamp < b.last_mod_timestamp) return 1;
      else return 0;
    }
  });
  return sorted;
};

export const filterMappings = (filter, status, api, mappings) => {
  let filtered = [...mappings];
  if (filter) {
    let filterLower = filter.toLowerCase();
    filtered = filtered.filter(
      mapping =>
        (mapping.name && mapping.name.toLowerCase().includes(filterLower)) ||
        mapping.application_name.toLowerCase().includes(filterLower) ||
        mapping.event_name.toLowerCase().includes(filterLower) ||
        mapping.partner_name.toLowerCase().includes(filterLower) ||
        (mapping.description &&
          mapping.description.toLowerCase().includes(filterLower))
    );
  }
  if (status) {
    filtered = filtered.filter(mapping => mapping.status === Number(status));
  }
  if (api) {
    filtered = filtered.filter(mapping =>
      mapping.fuzion_api_category.toLowerCase().includes(api)
    );
  }
  return filtered;
};

export const getMappings = () => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "GET",
        state: state,
        route: "/v1/admin-mappings/all"
      });
      let sortedMappings = sortMappingsByAPICategory(response.payload);
      dispatch({
        type: types.GET_MAPPINGS,
        payload: sortedMappings
      });
      return sortedMappings;
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.mappings.getMappings failed"
      });
    }
  };
};

export const displayMapping = mapping => {
  return dispatch => {
    dispatch({
      type: types.DISPLAY_MAPPING,
      payload: mapping
    });
  };
};

const sortMappingFields = mapping => {
  if (mapping.mappings === null || mapping.mappings === undefined)
    return mapping;
  let jsonMappings = JSON.parse(mapping.mappings);
  jsonMappings.types &&
    jsonMappings.types.map(type => {
      let { fields } = type;
      let sortedFields =
        fields &&
        fields.sort((a, b) => {
          if (a.field.toLowerCase() > b.field.toLowerCase()) return 1;
          else if (a.field.toLowerCase() < b.field.toLowerCase()) return -1;
          else {
            return 0;
          }
        });
      if (sortedFields) {
        type.fields = sortedFields;
      }
    });
  mapping.mappings = JSON.stringify(jsonMappings);
  return mapping;
};

export const getMapping = fuzion_mappings_id => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "GET",
        state: state,
        route: `/v1/admin-mappings/${fuzion_mappings_id}`
      });
      let sortedMapping = sortMappingFields(response.payload);
      dispatch({
        type: types.GET_MAPPING,
        payload: sortedMapping
      });
      return sortedMapping;
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.mappings.getMapping failed"
      });
    }
  };
};

export const createMapping = mapping => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "POST",
        state: state,
        route: `/v1/admin-mappings`,
        data: mapping
      });
      dispatch({
        type: types.CREATE_MAPPING,
        payload: response.payload
      });
      return response.payload;
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.mappings.createMapping failed"
      });
    }
  };
};

export const updateMapping = mapping => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "PUT",
        state: state,
        route: `/v1/admin-mappings/${mapping.fuzion_mappings_id}`,
        data: mapping
      });
      dispatch({
        type: types.UPDATE_MAPPING,
        payload: response.payload
      });
      return response.payload;
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.mappings.updateMapping failed"
      });
    }
  };
};

export const validateMappings = mapping => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "PUT",
        state: state,
        route: `/v1/admin-mappings/validate/${mapping.fuzion_mappings_id}`,
        data: mapping
      });
      dispatch({
        type: types.VALIDATE_MAPPING,
        payload: response.payload
      });
      return response.payload;
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.mappings.validateMappings failed"
      });
    }
  };
};

export const deleteMapping = mapping => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "DELETE",
        state: state,
        route: `/v1/admin-mappings/${mapping.fuzion_mappings_id}`,
        data: mapping
      });
      dispatch({
        type: types.DELETE_MAPPING,
        payload: response.payload
      });
      return response.payload;
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.mappings.deleteMapping failed"
      });
    }
  };
};
