import { SET_NOTIFICATION } from "actions/notifications";

export default (state = {}, action) => {
  switch (action.type) {
    case SET_NOTIFICATION: {
      const { payload } = action;
      return payload;
    }
    default:
      return state;
  }
};
