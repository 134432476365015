import React, { Component } from "react";
import { connect } from "react-redux";

import emptyState from "assets/images/graphicEmptyState.svg";

class OrganizationEvents extends Component {
  render() {
    return (
      <div className="container-fluid tabs-spacer">
        <div className="col-sm-9 col-sm-offset-3 col-md-10 col-md-offset-2 main">
          <h1 className="page-header">Organization Events</h1>
          <span className="header-sub-text">
            Organization:{" "}
            {this.props.organization && this.props.organization.partner_name}
          </span>
          <div className="empty-state">
            <img
              className="empty-state-img"
              src={emptyState}
              alt="empty organization"
            />
            <div className="empty-state-text">
              Nothing in Organization Events
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    organization: state.organization
  };
};

export default connect(mapStateToProps)(OrganizationEvents);
