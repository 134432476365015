import types from "types/active_user";

export default (state = {}, action) => {
  switch (action.type) {
    case types.GET_ACTIVE_USER:
      return action.payload;
    default:
      return state;
  }
};
