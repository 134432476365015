import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { Loading } from "components/loading/loading";
import { getApiCategories } from "actions/api_categories";

class ApiCategoriesList extends Component {
  renderList(apiCategory, index) {
    let to = `/apis/category/${apiCategory.fuzion_api_category_id}`;
    return (
      <Link
        key={index}
        to={to}
        className="list-item-container"
        onClick={() => console.log()}
      >
        <div className="list-item-content">
          <div className="list-title">{apiCategory.api_cat_friendly_name}</div>
        </div>
      </Link>
    );
  }

  render() {
    if (!this.props.apiCategories)
      return (
        <div>
          <Loading />
        </div>
      );
    let active = this.props.apiCategories
      .filter(apiCategory => apiCategory.status_flag > 0)
      .map((apiCategory, index) => this.renderList(apiCategory, index));
    let inactive = this.props.apiCategories
      .filter(apiCategory => apiCategory.status_flag === 0)
      .map((apiCategory, index) => this.renderList(apiCategory, index));

    return (
      <div>
        <div className="list-sub-heading">
          {active.length > 0 ? "Active" : ""}
        </div>
        {active}
        <div className="list-sub-heading">
          {inactive.length > 0 ? "Inactive" : ""}
        </div>
        {inactive}
      </div>
    );
  }
}

let mapStateToProps = state => {
  return {
    permissions: state.activeUser.role_permissions,
    apiCategory: state.apiCategory,
    apiCategories: state.apiCategories
  };
};

export default connect(mapStateToProps, { getApiCategories })(
  ApiCategoriesList
);
