import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";

import withAuth from "components/hoc/with_auth";
import ApplicationList from "components/organizations/organization/list/organization_apps_list";
import { getOrganization } from "actions/organizations";
import { getApplication, getApplications } from "actions/applications";
import OrganizationAppEditForm from "./organization_app_edit_form";
import { getCategories } from "actions/categories";

export class OrganizationApps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    let { getOrganization, getApplications, getCategories } = this.props;
    let { fuzion_organization_id } = this.props.match.params;
    getApplications(fuzion_organization_id);
    getOrganization(fuzion_organization_id);
    getCategories(0, 100);
  }

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
    this.loadData();
  };

  render() {
    let { permissions } = this.props;
    if (!permissions) return null;
    let canCreate =
      permissions.filter(p => p.permission_name === "ORGANIZATION_APP_CREATE")
        .length > 0;
    return (
      <div className="main-applications-container">
        <div className="container-fluid tabs-spacer">
          <div className="col-sm-9 col-sm-offset-3 col-md-10 col-md-offset-2 main">
            <h1 className="page-header">Organization Applications</h1>
            <span className="header-sub-text">
              Organization:{" "}
              {this.props.organization && this.props.organization.partner_name}
            </span>
            <div className="body-content">
              {canCreate && (
                <button
                  onClick={this.onOpenModal}
                  className="btn btn-primary body-content-btn"
                >
                  + APP
                </button>
              )}
            </div>
            <div className="body-content-partial">
              <ApplicationList />
            </div>
          </div>
          <Modal
            open={this.state.open}
            onClose={this.onCloseModal}
            classNames={{ modal: "custom-modal" }}
            closeOnOverlayClick={false}
            showCloseIcon={false}
          >
            <OrganizationAppEditForm
              title="New Organization Application"
              onSave={this.onCloseModal}
              operation="save"
            />
          </Modal>
        </div>
      </div>
    );
  }
}
//
const mapStateToProps = state => {
  return {
    permissions: state.activeUser.role_permissions,
    organization: state.organization,
    applications: state.applications,
    categories: state.categories
  };
};

export default connect(mapStateToProps, {
  getOrganization,
  getApplication,
  getApplications,
  getCategories
})(withAuth(OrganizationApps));
