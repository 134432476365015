import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";

import { createUser, getUser, getUsers } from "actions/users";
import { getRoles } from "actions/roles";
import AdminUserProfileEditForm from "./users/user_profile_edit_form";
import UserList from "./users_list";
import withAuth from "components/hoc/with_auth";

export class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    this.props.getUsers();
    this.props.getRoles();
  }

  onOpenModal = () => {
    this.setState({ open: true });
    this.props.createUser();
  };

  onCloseModal = () => {
    this.setState({ open: false });
    this.loadData();
  };

  // mapUsersToSecurityRoleName = () => {
  //     this.props.roles.forEach((role) => {
  //         this.props.users.data.forEach((user) => {
  //             if (user.fuzion_security_role_id === role.fuzion_security_role_id) {
  //                 user.role_friendly_name = role.role_friendly_name;
  //             }
  //         });
  //     });
  // }

  render() {
    // this.mapUsersToSecurityRoleName();

    let { permissions } = this.props;
    if (!permissions) return "";
    let canCreate =
      permissions.filter(p => p.permission_name === "USER_ADMIN_CREATE")
        .length > 0;
    return (
      <div className="container-fluid tabs-spacer">
        <div className="col-sm-9 col-sm-offset-3 col-md-10 col-md-offset-2 main">
          <h1 className="page-header">Users</h1>
          <div className="body-content">
            {canCreate && (
              <button
                onClick={this.onOpenModal}
                className="btn btn-primary body-content-btn"
              >
                + USER
              </button>
            )}
          </div>
          <div className="body-content-partial">
            <UserList />
          </div>
        </div>
        <Modal
          open={this.state.open}
          onClose={this.onCloseModal}
          classNames={{ modal: "custom-modal" }}
          closeOnOverlayClick={false}
          showCloseIcon={false}
        >
          <AdminUserProfileEditForm
            onClose={this.onCloseModal}
            title="New User"
            onSave={this.onCloseModal}
            operation="save"
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    users: state.users,
    roles: state.roles,
    count: state.counts.count,
    permissions: state.activeUser.role_permissions
  };
};

export default connect(mapStateToProps, {
  getUser,
  getUsers,
  getRoles,
  createUser
})(withAuth(Users));
