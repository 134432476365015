import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import Cognito from "lib/auth/cognito";
import { getActiveUser, setIdentityUser } from "actions/active_user";

let withAuth = ExtendedComponent => {
  class WithAuth extends Component {
    constructor() {
      super();
      this.nextLocationKey = "nextLocation";
    }

    componentDidMount() {
      this.authCognitoUser();
    }

    setNextLocation(nextLocation) {
      if (!window.localStorage) {
        return;
      }
      window.localStorage.setItem(this.nextLocationKey, nextLocation);
    }

    getNextLocation() {
      if (!window.localStorage) {
        return "/events";
      }
      return window.localStorage.getItem(this.nextLocationKey);
    }

    authCognitoUser = async () => {
      let { history } = this.props;
      try {
        let user = await Cognito().getSession();
        if (!user) {
          history.push("/");
        } else {
          if (user.expired) {
            this.setNextLocation(window.location.pathname);
            window.location = process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI;
            return;
          }
          this.props.setIdentityUser(user);
          const response = await this.props.getActiveUser(user);
          const u = response.payload;
          console.log(response.payload);
          if (!(u && u.role_permissions && u.role_permissions.length !== 0)) {
            //TODO: figure out why in Fuzion we don't have any persmissions like this.
            // console.log("we have no permissions somehow");
            // history.push("/nopermissions");
          } else {
            let nextLocation = this.getNextLocation();
            if (nextLocation && nextLocation !== "") {
              history.push(nextLocation);
              this.setNextLocation("");
            }
          }
        }
      } catch (e) {
        window.location =
          process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI + "/login";
      }
    };

    render() {
      let { activeUser } = this.props;
      if (
        activeUser &&
        activeUser.role_permissions &&
        activeUser.role_permissions.length !== 0
      ) {
        return <ExtendedComponent {...this.props} />;
      } else {
        return null;
      }
    }
  }

  const mapStateToProps = state => {
    return {
      activeUser: state.activeUser
    };
  };

  return connect(mapStateToProps, {
    setIdentityUser,
    getActiveUser
  })(withRouter(WithAuth));
};

export default withAuth;
