import types from "types/users";

export default (state = [], action) => {
  switch (action.type) {
    case types.GET_USERS:
      return action.payload;
    case types.GET_ORGANIZATION_USERS:
      return action.payload;
    default:
      return state;
  }
};
