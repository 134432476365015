import React, { Component } from "react";

import Input from "./input_debounce";
import ClearEntry from "./clear_entry";
import "./list_search.css";
import iconSearchDark from "assets/images/iconSearchDark.svg";

class ListSearch extends Component {
  constructor(props) {
    super(props);
    this.clearSearch = this.clearSearch.bind(this);
    this.state = {
      search_string: ""
    };
  }

  clearSearch() {
    this.setState({ search_string: "" });
    this.props.onChange({ name: "search_input", value: "" });
  }

  onSearchChange = e => {
    this.setState({ search_string: e.value });
    this.props.onChange(e);
  };

  render() {
    return (
      <div className="col-sm-8 list-search-container">
        <img
          src={iconSearchDark}
          alt="Search for an event"
          className="search-icon"
        />
        <Input
          name="search_input"
          type="text"
          classes="list-search form-control"
          onChange={this.onSearchChange}
          placeholder={this.props.placeholder}
          initialValue={this.state.search_string}
          autoComplete={this.props.autoComplete}
        />
        {this.state.search_string !== "" ? (
          <ClearEntry clear={this.clearSearch} />
        ) : null}
      </div>
    );
  }
}

export default ListSearch;
