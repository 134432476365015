import { request } from "lib/data/api";
import types from "types/organizations";
import global from "types/global";

export const saveImage = (formData, fuzion_organization_id) => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "POST",
        state: state,
        route: "/v1/fuzion-organization-image",
        data: formData,
        header: {
          fuzion_organization_id
        }
      });
      dispatch({
        type: types.SAVE_ORGANIZATION_IMAGE,
        payload: response.payload
      });
      return response.payload;
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.organization_image.saveImage failed"
      });
    }
  };
};

export const deleteImage = fuzion_organization_id => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "DELETE",
        state: state,
        route: "/v1/fuzion-organization-image",
        header: {
          fuzion_organization_id
        }
      });
      dispatch({
        type: types.DELETE_ORGANIZATION_IMAGE,
        payload: response.payload
      });
      return response.payload;
    } catch (e) {
      return dispatch({
        type: global.ERROR,
        error: "ERROR: actions.organization_image.deleteImage failed"
      });
    }
  };
};
