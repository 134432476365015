import React, { Component } from "react";
import { connect } from "react-redux";

import withAuth from "components/hoc/with_auth";
import { getEventApplication } from "actions/event_applications";
import {
  getAllApplications,
  getEventApplications,
  getApplication
} from "actions/applications";
import { getCategories } from "actions/categories";
import { getOrganizations } from "actions/organizations";
import EventAppEditForm from "./event_app_edit_form";
import emptyState from "assets/images/graphicEmptyState.svg";
import { imageAssets } from "fuzion-core-lib";

class EventApps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      update: true
    };
    this.applications = [];
  }

  async componentDidMount() {
    await this.loadData();
  }

  loadData() {
    let {
      getEventApplications,
      getCategories,
      getAllApplications,
      getOrganizations
    } = this.props;
    let { fuzion_event_id } = this.props.match.params;
    getCategories(0, 1000);
    getOrganizations();
    this.applications = getAllApplications();
    getEventApplications(fuzion_event_id);
  }

  onOpenModal = eventApp => {
    if (eventApp) {
      this.props.getApplication(
        eventApp.fuzion_application_id,
        eventApp.fuzion_organization_id
      );
      this.setState({
        open: true,
        update: true
      });
    } else {
      this.setState({
        open: true,
        update: false
      });
    }
  };

  onCloseModal = () => {
    this.setState({ open: false });
    this.loadData();
  };

  renderAvatar() {
    return (
      <div className="list-avatar-div">
        <img
          src={imageAssets.FUZION_AVATAR}
          className="list-avatar-image"
          alt="user avatar"
        />
      </div>
    );
  }

  renderLink(eventApplication) {
    return (
      <div
        key={eventApplication.fuzion_application_id}
        className="list-item-container"
        onClick={() => this.onOpenModal(eventApplication)}
        onKeyPress={e => {
          if (e.key === "Enter") {
            this.onOpenModal(eventApplication);
          }
        }}
        role="button"
        tabIndex={0}
      >
        {this.renderAvatar(eventApplication)}
        <div className="list-item-content">
          <div className="list-title">{eventApplication.application_name}</div>
          <div className="status-div">
            <div>
              Category: {eventApplication.application_category_friendly_name}
            </div>
            <div>Organization: {eventApplication.organization_name}</div>
          </div>
        </div>
      </div>
    );
  }

  renderEmptyState() {
    return (
      <div className="empty-state">
        <img
          className="empty-state-img"
          src={emptyState}
          alt="empty organization"
        />
        <div className="empty-state-text">Nothing in Event Applications</div>
      </div>
    );
  }

  getCategoryName = fuzion_application_category_id => {
    const { categories } = this.props;
    let category = categories.find(
      category =>
        category.fuzion_application_category_id.toUpperCase() ===
        fuzion_application_category_id.toUpperCase()
    );
    let application_category_friendly_name =
      category !== undefined
        ? category.application_category_friendly_name
        : "Unknown Category";
    return application_category_friendly_name;
  };

  getOrganizationName = fuzion_organization_id => {
    const { organizations } = this.props;
    let organization = organizations.find(
      organization =>
        organization.fuzion_organization_id.toUpperCase() ===
        fuzion_organization_id.toUpperCase()
    );
    let organization_name =
      organization !== undefined
        ? organization.partner_name
        : "Unknown Organization";
    return organization_name;
  };

  renderEventAppsList = () => {
    const { eventApplications } = this.props;
    if (eventApplications.length < 1) {
      return this.renderEmptyState();
    }
    const sortedEventApps = eventApplications.sort((a, b) => {
      return (
        a.application_name.toUpperCase() > b.application_name.toUpperCase()
      );
    });

    sortedEventApps.map(eventApp => {
      eventApp.application_category_friendly_name = this.getCategoryName(
        eventApp.fuzion_application_category_id
      );
      eventApp.organization_name = this.getOrganizationName(
        eventApp.fuzion_organization_id
      );
    });

    let activeItems = sortedEventApps
      .filter(item => item.status_flag > 0)
      .map(item => this.renderLink(item));
    let inactiveItems = sortedEventApps
      .filter(item => item.status_flag === 0)
      .map(item => this.renderLink(item));
    return (
      <div>
        <div className="list-sub-heading">
          {activeItems.length > 0 ? "Active" : ""}
        </div>
        {activeItems}
        <div className="list-sub-heading">
          {inactiveItems.length > 0 ? "Inactive" : ""}
        </div>
        {inactiveItems}
      </div>
    );
  };

  render() {
    let { permissions } = this.props;
    if (!permissions) return null;
    let canCreate =
      permissions.filter(p => p.permission_name === "SHOW_CREATE").length > 0;
    const { update } = this.state;
    const { event } = this.props;
    return (
      <div className="container-fluid tabs-spacer">
        <div className="col-sm-9 col-sm-offset-3 col-md-10 col-md-offset-2 main">
          <h1 className="page-header">Event Applications</h1>
          <span className="header-sub-text">Event: {event.event_name}</span>
          <div className="body-content">
            {canCreate && (
              <button
                onClick={() => this.onOpenModal(null)}
                className="btn btn-primary body-content-btn"
              >
                + APPLICATION
              </button>
            )}
          </div>
          <div className="body-content-partial">
            {this.renderEventAppsList()}
          </div>
        </div>
        <EventAppEditForm
          open={this.state.open}
          key={this.props.application.fuzion_application_id}
          update={update}
          onClose={this.onCloseModal}
          initialValues={this.props.application}
          applications={this.applications}
        />
      </div>
    );
  }
}

let mapStateToProps = state => {
  return {
    permissions: state.activeUser.role_permissions,
    event: state.event,
    eventApplication: state.eventApplication,
    application: state.application,
    eventApplications: state.eventApplications,
    categories: state.categories,
    organizations: state.organizations
  };
};

export default connect(mapStateToProps, {
  getEventApplication,
  getEventApplications,
  getApplication,
  getAllApplications,
  getCategories,
  getOrganizations
})(withAuth(EventApps));
