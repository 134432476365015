export default params => {
  if (!params && typeof params !== "object") return "";
  return Object.keys(params).reduce((result, key, index) => {
    if (params[key]) {
      if (index === 0) return `${key}`;
      return `${result} ${key}`;
    }
    return result;
  }, {});
};
