import React, { Component } from "react";
import { connect } from "react-redux";
import EventEditForm from "./event_edit_form";

class EventSettings extends Component {
  render() {
    return (
      <div className="container-fluid tabs-spacer">
        <div className="col-sm-9 col-sm-offset-3 col-md-10 col-md-offset-2 main">
          <h1 className="page-header">Event Settings</h1>
          {Object.keys(this.props.selectedEvent).length > 0 && (
            <EventEditForm
              key={this.props.selectedEvent.fuzion_event_id}
              onClose={this.onSave}
              initialValues={this.props.selectedEvent}
              onSave={this.onSave}
              operation="update"
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedEvent: state.event
  };
};

export default connect(mapStateToProps)(EventSettings);
