import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";

import { getUsers } from "actions/users";
import emptyState from "assets/images/graphicEmptyState.svg";

class UserList extends Component {
  renderEmptyState(emptyMessage) {
    return (
      <div className="empty-state">
        <img
          className="empty-state-img"
          src={emptyState}
          alt="empty organization"
        />
        <div className="empty-state-text">{emptyMessage}</div>
      </div>
    );
  }

  renderList(user, index) {
    let to = `/organizations/${user.fuzion_organization_id}/users/${user.fuzion_user_profile_id}`;
    let lastUpdateFormatted = moment
      .utc(user.last_mod_timestamp, "YYYY-MM-DD hh:mm:ss")
      .local()
      .format("MMMM Do, h:mm a");
    return (
      <Link
        key={index}
        to={to}
        className="list-item-container"
        onClick={() => console.log()}
      >
        <div className="list-item-content">
          <div className="list-title">{user.full_name}</div>
          <div className="status-div">
            <div>Role: {user.role_friendly_name}</div>
            <div>Last Updated: {lastUpdateFormatted}</div>
          </div>
        </div>
      </Link>
    );
  }

  render() {
    if (this.props.item.length < 1) {
      return (
        <div>{this.renderEmptyState("Nothing in Organization Users")}</div>
      );
    }
    let active = this.props.item
      .filter(user => user.status_flag > 0)
      .map((user, index) => this.renderList(user, index));
    let inactive = this.props.item
      .filter(user => user.status_flag === 0)
      .map((user, index) => this.renderList(user, index));
    return (
      <div>
        <div className="list-sub-heading">
          {active.length > 0 ? "Active" : ""}
        </div>
        {active}
        <div className="list-sub-heading">
          {inactive.length > 0 ? "Inactive" : ""}
        </div>
        {inactive}
      </div>
    );
  }
}

let mapStateToProps = state => {
  return {
    permissions: state.activeUser.role_permissions,
    user: state.user,
    users: state.users
  };
};

export default connect(mapStateToProps, { getUsers })(UserList);
