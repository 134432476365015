import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import CountsReducer from "./counts";
import EventsReducer from "./events";
import EventReducer from "./event";
import CategoriesReducer from "./categories";
import CategoryReducer from "./category";
import ApiCategoriesReducer from "./api_categories";
import ApiCategoryReducer from "./api_category";
import ApiRoutesReducer from "./api_routes";
import ApiRouteReducer from "./api_route";
import RolesReducer from "./roles";
import RoleReducer from "./role";
import UsersReducer from "./users";
import UserReducer from "./user";
import RoleByUserProfileIdReducer from "./roleByUserProfileId";
import RolePermissionsReducer from "./role_permissions";
import NotificationsReducer from "./notifications";
import SideBarReducer from "./sidebar";
import OrganizationsReducer from "./organizations";
import OrganizationReducer from "./organization";
import applicationReducer from "./application";
import applicationsReducer from "./applications";
import applicationKeyReducer from "./application_key";
import applicationKeysReducer from "./application_keys";
import EventApplications from "./event_applications";
import EventApplication from "./event_application";
import ApplicationEvent from "./application_event";
import ApplicationEvents from "./application_events";
import ActiveUserReducer from "./active_user";
import IdentityUserReducer from "./identity_user";
import MappingsReducer from "./mappings";
import MappingReducer from "./mapping";

const rootReducer = combineReducers({
  form: formReducer,
  counts: CountsReducer,
  events: EventsReducer,
  event: EventReducer,
  categories: CategoriesReducer,
  category: CategoryReducer,
  apiCategory: ApiCategoryReducer,
  apiRoutes: ApiRoutesReducer,
  apiRoute: ApiRouteReducer,
  apiCategories: ApiCategoriesReducer,
  users: UsersReducer,
  user: UserReducer,
  roles: RolesReducer,
  role: RoleReducer,
  rolePermissions: RolePermissionsReducer,
  notifications: NotificationsReducer,
  sidebar: SideBarReducer,
  roleByUserProfileId: RoleByUserProfileIdReducer,
  organizations: OrganizationsReducer,
  organization: OrganizationReducer,
  application: applicationReducer,
  applications: applicationsReducer,
  applicationKey: applicationKeyReducer,
  applicationKeys: applicationKeysReducer,
  eventApplications: EventApplications,
  eventApplication: EventApplication,
  applicationEvent: ApplicationEvent,
  applicationEvents: ApplicationEvents,
  activeUser: ActiveUserReducer,
  identityUser: IdentityUserReducer,
  mappings: MappingsReducer,
  mapping: MappingReducer
});

export default rootReducer;
