import { request } from "lib/data/api";
import types from "types/active_user";
import global from "types/global";

export const setIdentityUser = user => {
  return dispatch => {
    try {
      dispatch({
        type: types.SET_IDENTITY_USER,
        payload: user
      });
      dispatch(getActiveUser(user));
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.Identity_user.setIdentityUser failed"
      });
      return null;
    }
  };
};

export const getActiveUser = user => {
  return async (dispatch, getState) => {
    try {
      let email = user.profile.ImpersonatedUser.toLowerCase();
      let state = getState();
      let response = await request({
        state: state,
        method: "GET",
        route: `/v1/user-profiles/profile/${email}`
      });
      dispatch({
        type: types.GET_ACTIVE_USER,
        payload: response.payload
      });
      return response.payload;
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.active_user.getActiveUser failed"
      });
      return null;
    }
  };
};
