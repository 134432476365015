import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { connect } from "react-redux";

import EventApps from "./apps/event_apps";
import EventSettings from "./event_settings";
import EventReportsList from "./reports/event_reports_list";
import Header from "components/header/header";
import { getEvent } from "actions/events";

class EventDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: this.tabs.settings //default tab
    };
  }

  tabs = {
    applications: 0,
    settings: 1,
    reports: 2
  };

  indexes = ["applications", "settings", "reports"];

  selectTab = () => {
    const { tab } = this.props.match.params;

    if (tab) {
      const index = this.tabs[tab];
      if (index !== this.state.index) {
        this.setState({ index: index });
      }
    }
  };

  componentDidMount() {
    this.selectTab();
    let { fuzion_event_id } = this.props.match.params;
    if (fuzion_event_id) {
      this.props.getEvent(fuzion_event_id);
    }
  }
  componentDidUpdate = this.selectTab;

  changeTab = index => {
    const target = `/events/${this.props.match.params.fuzion_event_id}/${this.indexes[index]}`;
    this.props.history.push(target);
  };

  render() {
    const tabListClass = `nav nav-tabs admin-tab-subheader ${this.props
      .sidebar && "sidebar-spacer"}`;
    return (
      <>
        <Header detailView={true} to="/events" />
        <div>
          <Tabs selectedIndex={this.state.index} onSelect={this.changeTab}>
            <TabList className={tabListClass}>
              <div className="tab-inner-container">
                <Tab className="tab">APPLICATIONS</Tab>
                <Tab className="tab">SETTINGS</Tab>
                <Tab className="tab">EVENT REPORTS</Tab>
              </div>
            </TabList>
            <TabPanel>
              <EventApps />
            </TabPanel>
            <TabPanel>
              {this.props.event && Object.keys(this.props.event).length > 0 && (
                <EventSettings key={this.props.event.fuzion_event_id} />
              )}
            </TabPanel>
            <TabPanel>
              <EventReportsList />
            </TabPanel>
          </Tabs>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    sidebar: state.sidebar,
    organizations: state.organizations,
    event: state.event
  };
};

export default connect(mapStateToProps, {
  getEvent
})(EventDetails);
