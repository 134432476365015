import { CognitoUser } from "amazon-cognito-identity-js";
import Joi from "joi";
import { Link } from "react-router-dom";
import Pool from "../../userPool";
import React, { useState } from "react";
import { joiResolver } from "@hookform/resolvers/joi";
import { useForm } from "react-hook-form";
import resolveJoiErrors from "lib/util/joi_error_resolver";

const formStage = {
  first: "SEND_CODE",
  second: "RESET_PASSWORD"
};

export default function ForgotPassword(props) {
  const [awsCognitoError, setAwsCognitoError] = useState(false);
  const [awsCognitoErrorMessage, setAwsCognitoErrorMessage] = useState(false);

  const schema = Joi.object({
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .trim()
      .required()
  });
  const { register, handleSubmit, errors } = useForm({
    resolver: joiResolver(schema)
  });

  const sendCode = ({ email }) => {
    const user = new CognitoUser({ Username: email, Pool: Pool });

    user.forgotPassword({
      onSuccess: data => {
        console.log("onSuccess:", data);
      },
      onFailure: err => {
        setAwsCognitoError(true);
        setAwsCognitoErrorMessage(err.message);
        // console.error("onFailure:", err);
      },
      inputVerificationCode: () => {
        props.setUser(user);
        props.setFormStage(formStage.second);
      }
    });
  };

  return (
    <div style={{ maxWidth: "400px", paddingTop: "20px" }}>
      <h4 className="page-header">Forgot Password</h4>
      <form onSubmit={handleSubmit(sendCode)} style={{ paddingTop: "20px" }}>
        <div className="form-group">
          <div className="field-label">Email Address</div>
          <input
            placeholder="Enter Email Address"
            type="text"
            name="email"
            ref={register}
            className="form-control"
          />
          {errors.email && (
            <div className="text-danger">
              {resolveJoiErrors("Email Address", errors.email)}
            </div>
          )}
        </div>
        {awsCognitoError && (
          <div className="text-danger">{awsCognitoErrorMessage}</div>
        )}
        <Link to={"/login"}>Cancel</Link>
        <div className="align-right">
          <button type="submit" className="btn btn-primary">
            SEND VERIFICATION CODE
          </button>
        </div>
      </form>
    </div>
  );
}
