import { request } from "lib/data/api";
import types from "types/categories";
import global from "types/global";

const sortCategories = category => {
  let sorted = category.sort((a, b) => {
    if (
      a.application_category_friendly_name.toLowerCase() >
      b.application_category_friendly_name.toLowerCase()
    )
      return 1;
    else if (
      a.application_category_friendly_name.toLowerCase() <
      b.application_category_friendly_name.toLowerCase()
    )
      return -1;
    else {
      if (a.last_mod_timestamp > b.last_mod_timestamp) return -1;
      else if (a.last_mod_timestamp < b.last_mod_timestamp) return 1;
      else return 0;
    }
  });
  return sorted;
};

export const getCategories = () => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "GET",
        state: state,
        route: "/v1/application-categories/all"
      });
      let sortedCategories = sortCategories(response.payload);
      dispatch({
        type: types.GET_CATEGORIES,
        payload: sortedCategories
      });
      return sortedCategories;
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.catergories.getCategories failed"
      });
      return null;
    }
  };
};

export const createCategory = () => {
  return dispatch => {
    dispatch({
      type: types.CREATE_CATEGORY,
      payload: null
    });
  };
};

export const getCategory = fuzion_app_category_id => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "GET",
        state: state,
        route: `/v1/application-categories/${fuzion_app_category_id}`
      });
      dispatch({
        type: types.GET_CATEGORY,
        payload: response.payload
      });
      return response.payload;
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.categories.getCategory failed"
      });
      return null;
    }
  };
};

export const saveCategory = category => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "POST",
        state: state,
        route: "/v1/application-categories",
        data: category
      });
      return dispatch({
        type: types.SAVE_CATEGORY,
        payload: response.payload
      });
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.categories.saveCatergory failed"
      });
      return null;
    }
  };
};

export const updateCategory = category => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "PUT",
        state: state,
        route: `/v1/application-categories/${category.id}`,
        data: category
      });
      dispatch({
        type: types.UPDATE_CATEGORY,
        payload: response.payload
      });
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.categories.updateCategory failed"
      });
      return null;
    }
  };
};

export const deleteCategory = application_category => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "DELETE",
        state: state,
        route: `/v1/application-categories/${application_category.fuzion_app_category_id}`,
        data: application_category
      });
      return dispatch({
        type: types.DELETE_CATEGORY,
        payload: response.payload
      });
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.categories.deleteCategory failed"
      });
      return null;
    }
  };
};
