import { request } from "lib/data/api";
import types from "types/events";
import global from "types/global";

const sortEvents = event => {
  let sorted = event.sort((a, b) => {
    if (a.event_name.toLowerCase() > b.event_name.toLowerCase()) return 1;
    else if (a.event_name.toLowerCase() < b.event_name.toLowerCase()) return -1;
    else {
      if (a.last_mod_timestamp > b.last_mod_timestamp) return -1;
      else if (a.last_mod_timestamp < b.last_mod_timestamp) return 1;
      else return 0;
    }
  });
  return sorted;
};

export const getEvents = () => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "GET",
        state: state,
        route: "/v1/admin-events/all"
      });
      let sortedEvent = sortEvents(response.payload);
      dispatch({
        type: types.GET_EVENTS,
        payload: sortedEvent
      });
      return sortedEvent;
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.events.getEvents failed"
      });
    }
  };
};

export const getEvent = fuzion_event_id => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "GET",
        state: state,
        route: `/v1/admin-events/${fuzion_event_id}`
      });
      dispatch({
        type: types.GET_EVENT,
        payload: response.payload
      });
      return response.payload;
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.events.getEvent failed"
      });
    }
  };
};

export const filterEvents = (filter_string, events) => {
  let filter_string_lower = filter_string.toLowerCase();

  if (!filter_string_lower) return [];

  return events.filter(
    event =>
      event.event_name &&
      event.event_name.toLowerCase().includes(filter_string_lower)
  );
};

export const createEvent = () => {
  return dispatch => {
    dispatch({
      type: types.CREATE_EVENT,
      payload: null
    });
  };
};

export const saveEvent = event => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "POST",
        state: state,
        route: "/v1/admin-events",
        data: event
      });
      return dispatch({
        type: types.SAVE_EVENT,
        payload: response.payload
      });
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.events.saveEvent failed"
      });
    }
  };
};

export const updateEvent = event => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "PUT",
        state: state,
        route: `/v1/admin-events/${event.fuzion_event_id}`,
        data: event
      });
      dispatch({
        type: types.UPDATE_EVENT,
        payload: response.payload
      });
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.events.updateEvent failed"
      });
    }
  };
};

export const deleteEvent = event => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "DELETE",
        state: state,
        route: `/v1/admin-events/${event.fuzion_event_id}`,
        data: event
      });
      return dispatch({
        type: types.DELETE_EVENT,
        payload: response.payload
      });
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.events.deleteEvent failed"
      });
    }
  };
};
