import { request } from "lib/data/api";
import types from "types/api_routes";
import global from "types/global";

const sortApiRoutes = routes => {
  let sorted = routes.sort((a, b) => {
    if (a.application_name.toLowerCase() > b.application_name.toLowerCase())
      return 1;
    else if (
      a.application_name.toLowerCase() < b.application_name.toLowerCase()
    )
      return -1;
    else {
      if (a.last_mod_timestamp > b.last_mod_timestamp) return -1;
      else if (a.last_mod_timestamp < b.last_mod_timestamp) return 1;
      else return 0;
    }
  });
  return sorted;
};

export const getApiRoutes = () => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "GET",
        state: state,
        route: "/api-routes"
      });
      let sortedRoutes = sortApiRoutes(response.payload);
      dispatch({
        type: types.GET_API_ROUTES,
        payload: sortedRoutes
      });
      return sortedRoutes;
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.api_routes.getApiRoutes failed"
      });
    }
  };
};

export const getApiRoutesForCategory = fuzion_api_category_id => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "GET",
        state: state,
        route: `/api-routes?name=fuzion_api_category_id&id=${fuzion_api_category_id}`
      });
      dispatch({
        type: types.GET_API_ROUTES,
        payload: response.payload
      });
      return response.payload;
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.api_routes.getApiRoutesForCategory failed"
      });
      return null;
    }
  };
};

export const getApiRoute = apiRoute => {
  return {
    type: types.GET_API_ROUTE,
    payload: apiRoute
  };
};

export const saveApiRoute = apiRoute => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "POST",
        state: state,
        route: "/api-routes",
        data: apiRoute
      });
      dispatch({
        type: types.DELETE_API_ROUTESAVE_API_ROUTE,
        payload: response.payload
      });
      return response.payload;
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.api_routes.saveApiRoute failed"
      });
      return null;
    }
  };
};

export const updateApiRoute = apiRoute => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "PUT",
        state: state,
        route: `/api-routes/${apiRoute.fuzion_api_route_id}`,
        data: apiRoute
      });
      return dispatch({
        type: types.UPDATE_API_ROUTE,
        payload: response.payload
      });
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.api_routes.updateApiRoute failed"
      });
      return null;
    }
  };
};

export const deleteApiRoute = apiRoute => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "DELETE",
        state: state,
        route: `/api-routes/${apiRoute.fuzion_api_route_id}`
      });
      return dispatch({
        type: types.DELETE_API_ROUTE,
        payload: response.payload
      });
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.api_routes.deleteApiRoutes failed"
      });
      return null;
    }
  };
};
