import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import withAuth from "components/hoc/with_auth";
import Mappings from "./mappings";
import MappingDetailsContainer from "./details/mapping_details_container";

class MappingsIndex extends Component {
  render() {
    return (
      <span className="fuzion-nested-router">
        <Switch>
          <Route exact path="/mappings" component={Mappings} />
          <Route
            exact
            path="/mappings/:fuzion_mappings_id"
            component={MappingDetailsContainer}
          />
        </Switch>
        {/* <Route
            exact
            path="/mappings/:fuzion_mappings_id/:tab?"
            component={EventDetails}
          />
          <Route path="/events/app-edit-form" component={EventAppEditForm} />
          <Route
            path="/events/:fuzion_event_id/reports/exhibitors_list"
            component={EventReportExhibitor}
          /> */}
      </span>
    );
  }
}

export default withAuth(MappingsIndex);
