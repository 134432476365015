import React from "react";

export default function MenuButton(props) {
  return (
    <button
      type="button"
      onClick={props.onClick}
      className="navbar-toggle collapsed"
      data-toggle="collapse"
      data-target="#navbar"
      aria-expanded="false"
      aria-controls="navbar"
    >
      <span className="icon-bar" />
      <span className="icon-bar" />
      <span className="icon-bar" />
    </button>
  );
}
