import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import OrganizationEditForm from "./organization_edit_form";
import { getOrganization } from "actions/organizations";

class Settings extends Component {
  componentDidMount() {
    let { getOrganization } = this.props;
    let { fuzion_organization_id } = this.props.match.params;
    if (fuzion_organization_id) {
      getOrganization(fuzion_organization_id);
    }
  }

  render() {
    return (
      <div className="container-fluid tabs-spacer">
        <div className="col-sm-9 col-sm-offset-3 col-md-10 col-md-offset-2 main">
          <h1 className="page-header">Organization Settings</h1>
          {this.props.organization &&
            Object.keys(this.props.organization).length > 0 && (
              <OrganizationEditForm
                key={this.props.organization.fuzion_organization_id}
                operation="update"
                initialValues={this.props.organization}
              />
            )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    organization: state.organization
  };
};

export default connect(mapStateToProps, {
  getOrganization
})(withRouter(Settings));
