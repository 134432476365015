import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";

import withAuth from "components/hoc/with_auth";
import { getCategory, getCategories } from "actions/categories";

import CategoryList from "components/admin/categories_list";
import AppCategoriesEditForm from "./app_categories/category_edit_form";

const PAGE_SIZE = 1000;

export class Categories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      start: 0,
      pageSize: PAGE_SIZE
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    let { start, pageSize } = this.state;
    this.props.getCategories(start, pageSize);
  }

  onOpenModal = () => {
    this.setState({ open: true });
    // this.props.getCategory(null); //clear for insert
  };

  onCloseModal = () => {
    this.setState({ open: false });
    this.loadData();
  };

  render() {
    let { permissions } = this.props;
    if (!permissions) return "";
    let canCreate =
      permissions.filter(p => p.permission_name === "APP_CATEGORY_CREATE")
        .length > 0;
    return (
      <div className="container-fluid tabs-spacer">
        <div className="col-sm-9 col-sm-offset-3 col-md-10 col-md-offset-2 main">
          <h1 className="page-header">Categories</h1>
          <div className="body-content">
            {canCreate && (
              <button
                onClick={this.onOpenModal}
                className="btn btn-primary body-content-btn"
              >
                + CATEGORY
              </button>
            )}
          </div>
          <div className="body-content-partial">
            <CategoryList />
            {/* RESULTS_PAGING <p><a href="#" onClick={this.onLastClick}>&lt;Last</a>&nbsp;<a href="#" onClick={this.onNextClick}>Next&gt;</a></p> */}
          </div>
        </div>
        <Modal
          open={this.state.open}
          onClose={this.onCloseModal}
          classNames={{ modal: "custom-modal" }}
          closeOnOverlayClick={false}
          showCloseIcon={false}
        >
          <AppCategoriesEditForm
            title="New Category"
            onSave={this.onCloseModal}
            operation="insert"
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    permissions: state.activeUser.role_permissions,
    categories: state.categories
  };
};

export default connect(mapStateToProps, {
  getCategory,
  getCategories
})(withAuth(Categories));
