import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { Loading } from "components/loading/loading";

class EventReportList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventReports: null
    };
  }

  reportList = [
    {
      event_report_name: "Exhibitor List",
      event_report_ugly_name: "exhibitors_list",
      id: 0
    }
  ];

  componentDidMount() {
    this.setState({ eventReports: this.reportList });
  }

  renderList = eventReports => {
    let to = `/events/${this.props.event.fuzion_event_id}/reports/${eventReports.event_report_ugly_name}`;
    return (
      <Link key={eventReports.id} to={to} className="list-item-container">
        <div className="list-item-content">
          <div className="list-title">{eventReports.event_report_name}</div>
        </div>
      </Link>
    );
  };

  render() {
    if (!this.state.eventReports)
      return (
        <div>
          <Loading />
        </div>
      );
    return <div>{this.state.eventReports.map(this.renderList)}</div>;
  }
}

let mapStateToProps = state => {
  return {
    event: state.event
  };
};

export default connect(mapStateToProps, {})(EventReportList);
