import React, { Fragment, Component } from "react";

class TextAreaNonRedux extends Component {
  constructor(props) {
    super(props);
    let { initialLength } = this.props;
    this.state = { charLength: initialLength };
  }

  onChange = e => {
    let { value } = e.target;
    this.props.onChange(e);
    this.setState({ charLength: value.length });
  };

  render() {
    let { rows, bgColor } = this.props;
    return (
      <div className="textAreaContainer">
        {this.props.showLength ? (
          <Fragment>
            <textarea
              keys={this.state.charLength}
              onChange={this.onChange}
              onBlur={this.props.onBlur}
              rows={rows}
              placeholder={this.props.label}
              className={this.props.className || "form-control-textarea"}
              style={bgColor}
              value={this.props.value}
              name={this.props.name}
            />
            <div className="pull-right textAreaCharCount">
              {this.state.charLength}/{this.props.totalLength}
            </div>
          </Fragment>
        ) : (
          <textarea rows={rows} className="form-control" />
        )}
      </div>
    );
  }
}

export default TextAreaNonRedux;
