import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";

import withAuth from "components/hoc/with_auth";
import {
  saveApplicationKey,
  getApplicationKey,
  getApplicationKeys,
  createApplicationKey
} from "actions/application_keys";
import OrganizationAppKeyEditForm from "./keys/organization_app_key_edit_form";
import { guidId } from "fuzion-core-lib";

export class OrganizationAppKeys extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      operation: "none"
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    let { getApplicationKeys, getApplicationKey } = this.props;
    let { fuzion_organization_app_id } = this.props.match.params; // page works with refresh

    getApplicationKeys(fuzion_organization_app_id);
    getApplicationKey(null);
  }

  onOpenModalCreate = () => {
    let { applicationKeys } = this.props;
    const status_flag =
      applicationKeys.filter(pak => pak.status_flag > 0).length < 2 ? 1 : 0; // only two active
    this.props.createApplicationKey({
      fuzion_application_key_id: guidId.generate(),
      status_flag: status_flag
    });
    this.setState({ open: true, operation: "save" });
  };

  onOpenModalSelect = applicationKey => {
    let { application } = this.props;
    this.props.getApplicationKey(applicationKey);
    this.setState({ open: true, operation: "update" });
    this.props.history.push(
      `/organization/${application.fuzion_organization_id}/apps/${application.fuzion_application_id}/organizations-app-key/${applicationKey.fuzion_application_key_id}`
    );
  };

  onCloseModal = () => {
    this.setState({ open: false });
    this.loadData();
  };

  renderAppKey = applicationKey => {
    return (
      <div
        onClick={() => this.onOpenModalSelect(applicationKey)}
        onKeyPress={e => {
          if (e.key === "Enter") {
            this.onOpenModalSelect(applicationKey);
          }
        }}
        role="button"
        tabIndex={0}
        className="list-item-container"
        key={applicationKey.fuzion_application_key_id}
      >
        <div className="list-item-content">
          <div className="list-title">
            {applicationKey.fuzion_application_key_id}
          </div>
          <div className="status-div">
            Last Updated: {applicationKey.last_mod_timestamp}
          </div>
        </div>
      </div>
    );
  };

  render() {
    let { permissions } = this.props;
    if (!permissions) return "";
    let canCreate =
      permissions.filter(p => p.permission_name === "APP_KEY_CREATE").length >
      0;
    const { application, applicationKeys } = this.props;

    let activeItems = applicationKeys
      .filter(k => k.status_flag === 1)
      .map(applicationKey => this.renderAppKey(applicationKey));
    let inactiveItems = applicationKeys
      .filter(k => k.status_flag === 0)
      .map(applicationKey => this.renderAppKey(applicationKey));

    return (
      <div className="main-applications-container">
        <div className="container-fluid tabs-spacer">
          <div className="col-sm-9 col-sm-offset-3 col-md-10 col-md-offset-2 main">
            <h1 className="page-header">Application Keys</h1>
            <span className="header-sub-text">
              Application: {application.application_name}
            </span>
            <div className="body-content">
              {canCreate && (
                <button
                  onClick={this.onOpenModalCreate}
                  className="btn btn-primary body-content-btn"
                >
                  + APP KEY
                </button>
              )}
            </div>
            <div className="body-content-partial">
              <div className="list-sub-heading">
                {activeItems.length > 0 ? "Active" : ""}
              </div>
              {activeItems}
              <div className="list-sub-heading">
                {inactiveItems.length > 0 ? "Inactive" : ""}
              </div>
              {inactiveItems}
            </div>
          </div>
          <Modal
            open={this.state.open}
            onClose={this.onCloseModal}
            classNames={{ modal: "custom-modal" }}
            closeOnOverlayClick={false}
            showCloseIcon={false}
          >
            <OrganizationAppKeyEditForm
              title="New Application Key"
              onClose={this.onCloseModal}
              operation={this.state.operation}
              application={this.props.application}
              initialValues={this.props.applicationKey}
            />
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    permissions: state.activeUser.role_permissions,
    application: state.application,
    applicationKeys: state.applicationKeys,
    applicationKey: state.applicationKey
  };
};

export default connect(mapStateToProps, {
  saveApplicationKey,
  getApplicationKey,
  getApplicationKeys,
  createApplicationKey
})(withAuth(OrganizationAppKeys));
