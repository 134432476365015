import React from "react";

const selectObjectNonRedux = field => {
  const renderSelectOptions = (key, index) => {
    return (
      <option key={index} value={JSON.stringify(field.options[key])}>
        {field.options[key].application_name || field.options[key].event_name}
      </option>
    );
  };

  const placeholder = field.placeholder || "Select an option...";

  return (
    <div>
      <select
        name={field.name}
        value={field.value}
        className={field.className || "form-control"}
        style={field.bgColor}
        disabled={field.disabled}
        onChange={field.onChange}
        onBlur={field.onBlur}
      >
        <option key="-1" value="" disabled>
          {placeholder}
        </option>
        {Object.keys(field.options).map(renderSelectOptions)}
      </select>
    </div>
  );
};

export default selectObjectNonRedux;
