export default {
  GET_ORGANIZATIONS: "GET_ORGANIZATIONS",
  CREATE_ORGANIZATION: "CREATE_ORGANIZATION",
  GET_ORGANIZATION: "GET_ORGANIZATION",
  SAVE_ORGANIZATION: "SAVE_ORGANIZATION",
  REFRESH_ORGANIZATION: "REFRESH_ORGANIZATION",
  DELETE_ORGANIZATION: "DELETE_ORGANIZATION",
  UPDATE_ORGANIZATION: "UPDATE_ORGANIZATION",
  GET_ORGANIZATION_APP_EVENT_IDS: "GET_ORGANIZATION_APP_EVENT_IDS",
  GET_ORGANIZATION_APP_EVENT_ID: "GET_ORGANIZATION_APP_EVENT_ID",
  SAVE_ORGANIZATION_APP_EVENT_ID: "SAVE_ORGANIZATION_APP_EVENT_ID",
  UPDATE_ORGANIZATION_APP_EVENT_ID: "UPDATE_ORGANIZATION_APP_EVENT_ID",
  DELETE_ORGANIZATION_APP_EVENT_ID: "DELETE_ORGANIZATION_APP_EVENT_ID",
  SAVE_ORGANIZATION_IMAGE: "SAVE_ORGANIZATION_IMAGE",
  DELETE_ORGANIZATION_IMAGE: "DELETE_ORGANIZATION_IMAGE"
};
