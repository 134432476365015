import React, { Component, Fragment } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class Date extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    const date = this.props.value
      ? moment(this.props.value, "YYYY-MM-DD HH:mm:ss")
      : null;
    this.state = {
      date: date,
      focused: false
    };
  }

  onChange = date => {
    this.setState({ date: date });
    this.props.onChange(this.props.name, date);
  };

  onBlur = () => {
    //this.setState({ date: date });
    this.props.onBlur(this.props.name, this.state.date);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.loadData();
    }
  }

  loadData() {
    let date = this.props.value
      ? moment(this.props.value, "YYYY-MM-DD HH:mm:ss")
      : null;
    this.setState({ date: date });
  }
  render() {
    return (
      <Fragment>
        <div>
          <DatePicker
            className={this.props.className || "form-control"}
            selected={this.state.date}
            onChange={this.onChange}
            onBlur={this.onBlur}
            dateFormat={this.props.dateFormat || "MMM DD, YYYY"}
            showTimeSelect={this.props.showTimeSelect}
            showTimeSelectOnly={this.props.showTimeSelectOnly}
            placeholderText={this.props.placeholder || "Select a date"}
            minDate={this.props.minDate || null}
            maxDate={this.props.maxDate || null}
            timeIntervals={15}
            disabled={this.props.disabled}
          />
        </div>
      </Fragment>
    );
  }
}

export default Date;
