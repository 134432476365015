import { request } from "lib/data/api";
import types from "types/application_events";
import global from "types/global";

export const getApplicationEvents = fuzion_application_id => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "GET",
        state: state,
        route: `/v1/application-events/${fuzion_application_id}`
      });
      dispatch({
        type: types.GET_APPLICATION_EVENTS,
        payload: response.payload
      });
      return response.payload;
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.application_events.getApplicationEvents failed"
      });
      return null;
    }
  };
};

export const getApplicationEvent = fuzion_event_id => {
  let data = { fuzion_event_id: fuzion_event_id };
  return {
    type: types.GET_APPLICATION_EVENT,
    payload: data
  };
};

export const saveApplicationEvent = formValues => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "POST",
        state: state,
        route: "/v1/application-events",
        data: formValues
      });
      return dispatch({
        type: types.SAVE_APPLICATION_EVENT,
        payload: response.payload
      });
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.application_events.saveApplicationEvent failed"
      });
      return null;
    }
  };
};

export const deleteApplicationEvent = formValues => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "DELETE",
        state: state,
        route: "/v1/application-events",
        data: formValues
      });
      return dispatch({
        type: types.DELETE_APPLICATION_EVENT,
        payload: response.payload
      });
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.application_events.deleteApplicationEvent failed"
      });
      return null;
    }
  };
};
