import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import withAuth from "components/hoc/with_auth";
import changes from "components/hoc/unsaved_changes";
import {
  required,
  minLength3,
  maxLength256
} from "lib/validation/form_validation_rules";
import TextArea from "lib/ui/form/text_area_non_redux";
import Select from "lib/ui/form/select_non_redux";
import ConfirmationModal from "lib/ui/confirmationModal";
import {
  createApplication,
  getApplication,
  getApplications,
  saveApplication,
  deleteApplication,
  updateApplication
} from "actions/applications";
import { getCategories } from "actions/categories";
import { saveImage, deleteImage } from "actions/application_image";
import { setNotification } from "actions/notifications";
import { imageAssets } from "fuzion-core-lib";
import formHelper from "lib/ui/form/form";
import renderClassNames from "lib/ui/render_class_names";

export class OrganizationAppEditForm extends Component {
  constructor(props) {
    super(props);
    this.fileUploader = React.createRef();
    let { initialValues } = props;
    const isInsert = this.props.operation === "save";
    const stateFields = {
      isInsert: isInsert,
      deleteConfirmationOpen: false,
      cantDeleteConfirmationOpen: false,
      updateConfirmationOpen: false,
      applicationImage: null,
      imageButtonsDisabled: false
    };
    const insertStateFields = {
      application_name: {
        key: "application_name",
        value: (initialValues && initialValues.application_name) || "",
        valid: true,
        errorMessage: "",
        validation: [required, minLength3]
      },
      application_description: {
        key: "application_description",
        value: (initialValues && initialValues.application_description) || "",
        valid: true,
        errorMessage: "",
        validation: [maxLength256]
      },
      fuzion_application_category_id: {
        key: "fuzion_application_category_id",
        value:
          (initialValues && initialValues.fuzion_application_category_id) || "",
        valid: true,
        errorMessage: "",
        validation: [required]
      },
      status_flag: {
        key: "status_flag",
        value:
          !isInsert &&
          Object.keys(initialValues).length > 0 &&
          initialValues.status_flag !== null
            ? initialValues.status_flag.toString()
            : "0",
        valid: true,
        errorMessage: "",
        validation: [required]
      }
    };
    const updateStateFields = {
      api_access_flag: {
        key: "api_access_flag",
        value:
          !isInsert &&
          Object.keys(initialValues).length > 0 &&
          initialValues.api_access_flag !== null
            ? initialValues.api_access_flag.toString()
            : "",
        valid: true,
        errorMessage: ""
      }
    };
    if (isInsert) {
      this.state = {
        ...stateFields,
        ...insertStateFields
      };
    } else {
      this.state = {
        ...stateFields,
        ...insertStateFields,
        ...updateStateFields
      };
    }
  }

  componentDidMount() {
    let { initialValues } = this.props;
    if (initialValues && initialValues.fuzion_organization_app_id) {
      this.setImage(initialValues.application_image_file);
    }
  }

  onSubmit = async () => {
    const {
      operation,
      saveApplication,
      setNotification,
      initialValues,
      applications
    } = this.props;
    const isFormValid = formHelper.isFormValid(this.state);
    if (!isFormValid.valid) {
      this.setState(isFormValid.formState);
      return;
    }
    let formValues = formHelper.getFormValues(this.state);
    let {
      fuzion_organization_id,
      fuzion_organization_app_id
    } = this.props.match.params;
    formValues.status_flag = Number(formValues.status_flag);
    let newApplicationName =
      operation === "update"
        ? initialValues.application_name.toLowerCase().trim() !==
          formValues.application_name.toLowerCase().trim()
        : true;
    const nameAlreadyExists = applications.some(app => {
      return (
        app.application_name.toUpperCase().trim() ===
          formValues.application_name.toUpperCase().trim() && newApplicationName
      );
    });
    if (nameAlreadyExists) {
      const newApplicationName = Object.assign(
        {},
        {
          ...this.state.application_name,
          valid: false,
          errorMessage: "This application name already exists"
        }
      );
      this.setState({ application_name: newApplicationName });
    } else {
      switch (operation) {
        case "save": {
          formValues.fuzion_organization_id = fuzion_organization_id;
          formHelper.removeUnwantedValues(formValues);
          await saveApplication(formValues);
          this.props.onSave();
          setNotification("good", "Organization App Created");
          break;
        }
        case "update":
          formValues.fuzion_organization_id = fuzion_organization_id;
          formValues.fuzion_application_id = fuzion_organization_app_id;
          formValues.prior_status_flag = initialValues.status_flag;
          this.setState({
            updateFormValues: formValues,
            updateConfirmationOpen: true
          });
          break;
        default:
          console.log(`Unknown operation: ${operation}`);
          break;
      }
    }
  };

  continueUpdate = async () => {
    const { updateApplication, setNotification } = this.props;
    await updateApplication(this.state.updateFormValues);
    setNotification("good", "Organization App Updated ");
  };

  onDateChange = (name, value) => {
    // console.log(e);
    this.setState(formHelper.mergeNewState(this.state, name, { value }));
  };

  onChange = ({ target: { name, value } }) => {
    this.setState(formHelper.mergeNewState(this.state, name, { value }));
  };

  onDateBlur = (name, value) => {
    // console.log(e);
    this.setState(formHelper.mergeNewState(this.state, name, { value }));
  };

  onBlur = e => {
    const {
      target: { name, value }
    } = e;
    const validation = formHelper.validate(this.state, name, value);
    this.setState(formHelper.mergeNewState(this.state, name, validation));
  };

  onDelete = async () => {
    let {
      deleteApplication,
      setNotification,
      history,
      initialValues
    } = this.props;
    let { fuzion_organization_id } = this.props.match.params;
    initialValues.fuzion_organization_id = fuzion_organization_id;
    initialValues.status_flag = Number(initialValues.status_flag);
    initialValues.api_access_flag = Number(initialValues.api_access_flag);
    await deleteApplication(initialValues);
    history.push(`/organizations/${fuzion_organization_id}/applications`);
    setNotification("good", "Organization App Deleted ");
  };

  renderTitle() {
    return <h4 className="modal-title">{this.props.title}</h4>;
  }

  handleNewImage = e => {
    e.stopPropagation();
    e.preventDefault();
    this.fileUploader.current.click();
  };

  setImage(applicationImage) {
    this.setState({ applicationImage });
  }

  onDeleteImage = async () => {
    let { deleteImage, initialValues, setNotification } = this.props;
    this.setState({ imageButtonsDisabled: true });
    const appWithoutImage = await deleteImage(
      initialValues.fuzion_application_id,
      initialValues.fuzion_organization_id
    );
    if (!appWithoutImage) {
      console.log("Failed to delete image");
    } else {
      await this.props.updateApplication(appWithoutImage);
      this.setImage(null);
      setNotification("good", "Delete Successful");
    }
    this.setState({ imageButtonsDisabled: false });
  };

  onChangeFile = async e => {
    let { setNotification, saveImage, initialValues } = this.props;
    e.stopPropagation();
    e.preventDefault();
    this.setState({ imageButtonsDisabled: true });
    if (!e) return;
    let file = e.target.files[0];
    e.target.value = null;
    if (!file) return;
    let acceptFileTypes = /(\.|\/)(gif|jpe?g|png)$/i;
    let fileSizeMB = file.size / (1024 * 1024);
    if (file.type.length && !acceptFileTypes.test(file.type)) {
      setNotification("good", "Not an image");
      console.log("Image must be of type jpg, png, or gif!");
      return false;
    }
    if (fileSizeMB > 10) {
      setNotification("good", "Image is too large");
      return false;
    }
    let formData = new FormData();
    formData.append("fuzion_application_image", file);
    formData.append("content_type", file.type);
    formData.append("image_name", file.name);
    const appWithImage = await saveImage(
      formData,
      initialValues.fuzion_application_id,
      initialValues.fuzion_organization_id
    );
    if (!appWithImage) {
      console.log("Failed to update image");
    } else {
      await this.props.updateApplication(appWithImage);
      const localImageUrl = window.URL.createObjectURL(file);
      this.setImage(localImageUrl);
      setNotification("good", "Update Successful");
    }
    this.setState({ imageButtonsDisabled: false });
  };

  renderImageUpdateButton() {
    return (
      <button
        type="button"
        disabled={this.state.imageButtonsDisabled}
        name="updateApplicationImageButton"
        onClick={this.handleNewImage}
        className="btn btn-default-alt"
      >
        UPDATE
      </button>
    );
  }

  renderImageRemoveButton() {
    return (
      <button
        type="button"
        disabled={this.state.imageButtonsDisabled}
        name="removeApplicationImageButton"
        onClick={this.onDeleteImage}
        className="btn btn-default"
      >
        REMOVE
      </button>
    );
  }

  renderAvatar() {
    const { applicationImage } = this.state;
    let image = applicationImage || imageAssets.FUZION_AVATAR;
    return (
      <div className="form-group">
        <div className="field-label">Application Image</div>
        <img src={image} className="avatar-upload" alt="Event Profile" />
        <input
          type="file"
          name="application_image"
          id="fuzion_application_image"
          style={{ display: "none" }}
          ref={this.fileUploader}
          onChange={this.onChangeFile}
          accept="image/gif, image/png, image/jpeg"
        />
        <div className="avatar-button-container">
          {this.renderImageUpdateButton()}
          {applicationImage && this.renderImageRemoveButton()}
        </div>
        <div className="field-label">
          PNG, JPG or GIF format. Recommended dimensions: 300px x 300px.
        </div>
      </div>
    );
  }

  renderSubmitButton(options) {
    let { permissions } = this.props;
    let canUpdate =
      permissions.filter(p => p.permission_name === "ORGANIZATION_APP_UPDATE")
        .length > 0;
    let submitText = options.isInsert ? "SAVE" : "UPDATE";
    if (canUpdate) {
      return (
        <button
          type="button"
          onClick={this.onSubmit}
          name="saveOrganizationAppButton"
          className={options.submitStyle}
        >
          {submitText}
        </button>
      );
    } else {
      return null;
    }
  }

  renderCancelButton(options) {
    return (
      <button
        type="button"
        disabled={options.submitting}
        onClick={this.props.onSave}
        name="cancelOrganizationAppButton"
        className={options.otherStyle}
      >
        CANCEL
      </button>
    );
  }

  renderDeleteButton(options) {
    let { permissions } = this.props;
    let canDelete =
      permissions.filter(p => p.permission_name === "ORGANIZATION_APP_DELETE")
        .length > 0;
    if (canDelete) {
      return (
        <button
          type="button"
          disabled={options.submitting}
          onClick={() => this.setState({ deleteConfirmationOpen: true })}
          name="deleteOrganizationAppButton"
          className={options.otherStyle}
        >
          DELETE ORGANIZATION APP
        </button>
      );
    } else {
      return null;
    }
  }

  optionsFromCategories() {
    let optionsObject = {};
    this.props.categories.forEach(category => {
      optionsObject[category.fuzion_application_category_id] =
        category.application_category_friendly_name;
    });
    return optionsObject;
  }

  renderOrganizationApp(style) {
    const { initialValues } = this.props;
    const { isInsert } = this.state;
    const initialLength =
      initialValues && initialValues.application_description
        ? initialValues.application_description.length
        : 0;

    return (
      <div>
        {!this.state.isInsert && this.renderAvatar()}
        <div className="form-group">
          <div className="field-label">Name *</div>
          <div>
            <input
              name={this.state.application_name.key}
              type="text"
              value={this.state.application_name.value}
              onChange={this.onChange}
              onBlur={this.onBlur}
              className={style.appNameErrStyle}
              placeholder="Name"
            />
          </div>
          <div className="field-label">This name is used in the UI</div>
          {!this.state.application_name.valid && (
            <div className="custom-text-danger">
              {this.state.application_name.errorMessage}
            </div>
          )}
        </div>
        {!isInsert && (
          <div className="form-group">
            <div className="field-label">Application ID</div>
            <div>
              <input
                name="fuzion_application_id"
                type="text"
                value={initialValues.fuzion_application_id}
                className={style.appIDErrStyle}
                disabled={true}
              />
            </div>
          </div>
        )}
        <div className="form-group">
          <div className="field-label">Description</div>
          <div>
            <TextArea
              name={this.state.application_description.key}
              value={this.state.application_description.value}
              onChange={this.onChange}
              onBlur={this.onBlur}
              label="Description"
              rows={5}
              showLength={true}
              initialLength={initialLength}
              totalLength={256}
              className={style.appDescErrStyle}
            ></TextArea>
            {!this.state.application_description.valid && (
              <div className="custom-text-danger">
                {this.state.application_description.errorMessage}
              </div>
            )}
          </div>
        </div>
        <div className="form-group">
          <div className="field-label">Category *</div>
          <div>
            <Select
              name={this.state.fuzion_application_category_id.key}
              value={this.state.fuzion_application_category_id.value}
              onChange={this.onChange}
              onBlur={this.onBlur}
              options={this.optionsFromCategories()}
              className={style.fuzionAppCategoryIdErrStyle}
            ></Select>
          </div>
          {!this.state.fuzion_application_category_id.valid && (
            <div className="custom-text-danger">
              {this.state.fuzion_application_category_id.errorMessage}
            </div>
          )}
        </div>
        <div className="form-group">
          <div className="field-label">Status *</div>
          <div>
            <Select
              name={this.state.status_flag.key}
              value={this.state.status_flag.value}
              onChange={this.onChange}
              onBlur={this.onBlur}
              disabled={isInsert ? true : undefined}
              options={{
                "1": "Active",
                "0": "Inactive"
              }}
              className={style.statusFlagErrStyle}
            ></Select>
          </div>
          {!this.state.status_flag.valid && (
            <div className="custom-text-danger">
              {this.state.status_flag.errorMessage}
            </div>
          )}
        </div>
      </div>
    );
  }

  renderServiceSettings() {
    return (
      <div>
        <div>Service Settings</div>
        <div className="form-group">
          <div className="field-label">API Access</div>
          <div>
            <Select
              name={this.state.api_access_flag.key}
              value={this.state.api_access_flag.value}
              onChange={this.onChange}
              onBlur={this.onBlur}
              options={{
                "0": "Disabled",
                "1": "Enabled"
              }}
            ></Select>
          </div>
          {!this.state.api_access_flag.valid && (
            <div className="custom-text-danger">
              {this.state.api_access_flag.errorMessage}
            </div>
          )}
        </div>
      </div>
    );
  }

  getStyle(isInsert) {
    const appNameErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !this.state.application_name.valid
    });
    const appIDErrStyle = renderClassNames({
      "form-control": true
    });
    const appDescErrStyle = renderClassNames({
      "form-control-textarea": true,
      "custom-danger-border": !this.state.application_description.valid
    });
    const fuzionAppCategoryIdErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !this.state.fuzion_application_category_id.valid
    });
    const statusFlagErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !this.state.status_flag.valid
    });
    const apiAccessFlagErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !isInsert && !this.state.api_access_flag.valid
    });

    return {
      otherStyle: isInsert ? "btn btn-default pull-right" : "btn btn-default",
      submitStyle: isInsert ? "btn btn-primary pull-right" : "btn btn-primary",
      submitText: isInsert ? "CREATE" : "SAVE",
      btnContainer: isInsert
        ? "formAddButtonContainer"
        : "formEditButtonContainer",
      requiredLabel: isInsert ? "required-label-jpom" : "required-label",
      appNameErrStyle: appNameErrStyle,
      appIDErrStyle: appIDErrStyle,
      appDescErrStyle: appDescErrStyle,
      fuzionAppCategoryIdErrStyle: fuzionAppCategoryIdErrStyle,
      statusFlagErrStyle: statusFlagErrStyle,
      apiAccessFlagErrStyle: apiAccessFlagErrStyle
    };
  }

  render() {
    const { isInsert } = this.state;
    const style = this.getStyle(isInsert);
    return (
      <div>
        {this.props.title && this.renderTitle()}
        <div className="modal-form">
          <div>
            <form>
              {this.renderOrganizationApp(style)}
              {this.state.isInsert ? null : this.renderServiceSettings(style)}
              <div className="form-group">
                <div className={style.btnContainer}>
                  {isInsert &&
                    this.renderCancelButton({
                      otherStyle: style.otherStyle
                    })}
                  {this.renderSubmitButton({
                    isInsert: isInsert,
                    submitStyle: style.submitStyle
                  })}
                  {!isInsert &&
                    this.renderDeleteButton({
                      otherStyle: style.otherStyle
                    })}
                </div>
                <div className={style.requiredLabel}>* Required</div>
              </div>
            </form>
          </div>
        </div>
        <ConfirmationModal
          open={this.state.updateConfirmationOpen}
          header="Save Application?"
          text="Changes have been made to the application that will impact its behavior. Are you sure you want to save?"
          leftLabel="SAVE"
          onLeft={this.continueUpdate}
          rightLabel="CANCEL"
          onClose={() => this.setState({ updateConfirmationOpen: false })}
        />
        <ConfirmationModal
          open={this.state.cantDeleteConfirmationOpen}
          header="Can't Delete Application!"
          text="This application has data associated to it so it cannot be deleted."
          rightLabel="GOT IT"
          onClose={() => this.setState({ cantDeleteConfirmationOpen: false })}
        />
        <ConfirmationModal
          open={this.state.deleteConfirmationOpen}
          header="Delete Application?"
          text="If deleted, this application will no longer have access to any Fuzion functionality. Are you sure you want to delete?"
          leftLabel="DELETE"
          onLeft={this.onDelete}
          rightLabel="CANCEL"
          onClose={() => this.setState({ deleteConfirmationOpen: false })}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    permissions: state.activeUser.role_permissions,
    categories: state.categories,
    applications: state.applications
  };
};

export default connect(mapStateToProps, {
  getCategories,
  getApplication,
  getApplications,
  updateApplication,
  createApplication,
  saveApplication,
  deleteApplication,
  setNotification,
  saveImage,
  deleteImage
})(withAuth(withRouter(changes(OrganizationAppEditForm))));
