import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import atob from "atob";
import { withRouter } from "react-router-dom";

import { Loading } from "components/loading/loading";
import ReportControls from "components/events/event/reports/report_controls";
import ConfirmationModal from "lib/ui/confirmationModal";
import { setNotification } from "actions/notifications";
import {
  getExhibitorReport,
  deleteExhibitorReport
} from "actions/event_reports";

import emptyState from "assets/images/graphicEmptyState.svg";

class EventReportExhibitorList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventReports: null,
      deleteConfirmationOpen: false,
      currentKey: null
    };
  }

  onDelete = async keyValue => {
    this.setState({ deleteConfirmationOpen: true, currentKey: keyValue });
  };

  deleteReport = async () => {
    const { setNotification } = this.props;
    const keyValue = this.state.currentKey;
    const response = await this.props.deleteExhibitorReport(keyValue);
    if (!response.payload) {
      setNotification("error", "Failed to delete report");
      return;
    }
    this.setState({ deleteConfirmationOpen: false });
    this.props.getReports(this.props.match.params.fuzion_event_id);
    setNotification("good", "Successfully deleted report");
  };

  onDownload = async keyValue => {
    const response = await this.props.getExhibitorReport(keyValue);
    if (!response.payload) return;
    let res = response.payload;
    let csvContent = atob(res.file_data);
    let blob = new Blob([csvContent], {
      type: "data:application/octet-stream;base64"
    });
    if (window.navigator.msSaveBlob) {
      // IE hack
      window.navigator.msSaveOrOpenBlob(blob, `${res.file_name}`);
    } else {
      let a = window.document.createElement("a");
      a.href = window.URL.createObjectURL(blob, { type: "text/plain" });
      a.download = `${res.file_name}`;
      document.body.appendChild(a);
      a.click(); // IE: "Access is denied"
      document.body.removeChild(a);
    }
  };

  renderList = report => {
    return (
      <div className="list-item" key={report.id}>
        <div key={report.id} className="list-item-container">
          <div
            className="list-item-content"
            onClick={() => this.onDownload(report.id)}
            onKeyPress={e => {
              if (e.key === "Enter") {
                this.onDownload(report.id);
              }
            }}
            role="button"
            tabIndex={0}
          >
            <div className="list-title">{report.id.replace(/.*\//, "")}</div>
            <div className="status-div">
              <div>
                Created:{" "}
                {moment(report.last_modified).format("MMMM Do YYYY, h:mm a")}
              </div>
            </div>
          </div>
          <ReportControls
            key={report.id}
            id={report.id}
            onDelete={this.onDelete}
            onDownload={this.onDownload}
          />
        </div>
      </div>
    );
  };

  renderEmptyState(emptyMessage) {
    return (
      <div className="empty-state">
        <img
          className="empty-state-img"
          src={emptyState}
          alt="empty organization"
        />
        <div className="empty-state-text">{emptyMessage}</div>
      </div>
    );
  }

  render() {
    if (!this.props.isLoaded) return <Loading />;
    if (this.props.reports.length < 1) {
      return <div>{this.renderEmptyState("Nothing in Exhibitor List")}</div>;
    }
    return (
      <>
        {this.props.reports.map(this.renderList)}
        <ConfirmationModal
          open={this.state.deleteConfirmationOpen}
          header="Delete Report?"
          text="If deleted, this report will no longer be accessible. Are you sure you want to delete?"
          leftLabel="DELETE"
          onLeft={this.deleteReport}
          rightLabel="CANCEL"
          onClose={() => this.setState({ deleteConfirmationOpen: false })}
        />
      </>
    );
  }
}

let mapStateToProps = () => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, {
    getExhibitorReport,
    deleteExhibitorReport,
    setNotification
  })(EventReportExhibitorList)
);
