import React from "react";

const selectnonredux = field => {
  const renderSelectOptions = (key, index) => {
    return (
      <option key={index} value={key}>
        {field.options[key]}
      </option>
    );
  };

  const placeholder = field.placeholder
    ? field.placeholder
    : "Select an option...";

  return (
    <div>
      {
        <select
          className={field.className || "form-control"}
          name={field.name}
          value={field.value}
          disabled={field.disabled}
          onChange={field.onChange}
          onBlur={field.onBlur}
        >
          <option key="-1" value="" disabled>
            {placeholder}
          </option>
          {Object.keys(field.options).map(renderSelectOptions)}
        </select>
      }
    </div>
  );
};

export default selectnonredux;
