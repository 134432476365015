const get = require("lodash/get");
const set = require("lodash/set");
const remove = require("lodash/remove");
const find = require("lodash/find");
const findIndex = require("lodash/findIndex");
const pullAt = require("lodash/pullAt");
const filter = require("lodash/filter");

export const lodashGet = (schema, path) => {
  try {
    let response = get(schema, path);
    return response;
  } catch (e) {
    console.log(e.message);
    return schema;
  }
};

export const lodashSet = (schema, path, object) => {
  try {
    let response = set(schema, path, object);
    return response;
  } catch (e) {
    console.log(e.message);
    return schema;
  }
};

export const lodashFind = (schema, path, object) => {
  try {
    let response = find(schema, path, object);
    return response;
  } catch (e) {
    console.log(e.message);
    return schema;
  }
};

export const lodashFindIndex = (schema, object) => {
  try {
    let response = findIndex(schema, object);
    return response;
  } catch (e) {
    console.log(e.message);
    return schema;
  }
};

export const lodashFilter = (schema, object) => {
  try {
    let response = filter(schema, object);
    return response;
  } catch (e) {
    console.log(e.message);
    return schema;
  }
};

export const lodashRemove = (schema, pathOrCriteria) => {
  try {
    let response = remove(schema, pathOrCriteria);
    return response;
  } catch (e) {
    console.log(e.message);
    return schema;
  }
};

export const lodashPullAt = (schema, index) => {
  try {
    let response = pullAt(schema, index);
    return response;
  } catch (e) {
    console.log(e.message);
    return schema;
  }
};
