import React, { Component } from "react";
import { connect } from "react-redux";

import { withRouter } from "react-router";

import { getEvent } from "actions/events";
import { getOrganization } from "actions/organizations";
import EventReportExhibitorEditForm from "./event_report_exhibitor_edit_form";
import EventReportExhibitorList from "components/events/event/list/event_report_exhibitor_list";
import { setNotification } from "actions/notifications";
import { getExhibitorList, saveExhibitorReport } from "actions/event_reports";
import Header from "components/header/header";

export class EventReportExhibitor extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      reports: [],
      isLoaded: false
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    await this.props.getEvent(this.props.match.params.fuzion_event_id);
    const value = await this.props.getExhibitorList(
      this.props.match.params.fuzion_event_id
    );
    if (!value || !value.payload) {
      setNotification("error", "Failed to get report list");
      this.setLoaded(true);
      return;
    }
    this.setState({ reports: value.payload });
    this.setLoaded(true);
  }

  getReports = async event_id => {
    const value = await this.props.getExhibitorList(event_id);
    this.setState({ reports: value.payload });
  };

  setLoaded = isLoaded => this.setState({ isLoaded });

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  addReport = async formValues => {
    this.setLoaded(false);
    const { setNotification } = this.props;
    this.onCloseModal();
    const response = await this.props.saveExhibitorReport(formValues);
    if (this._isMounted) {
      if (response.payload && response.payload.Key) {
        this.getReports(this.props.match.params.fuzion_event_id);
        setNotification("good", "Successfully generated report");
      } else if (response.payload === "no data") {
        setNotification("error", "No results. Report not generated.");
      } else {
        setNotification("error", "Failed to generate report");
      }
      this.setLoaded(true);
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { open } = this.state;
    return (
      <>
        <Header
          detailView={true}
          to={`/events/${this.props.match.params.fuzion_event_id}/reports`}
        />
        <div className="container-fluid">
          <div className="col-sm-9 col-sm-offset-3 col-md-10 col-md-offset-2 main">
            <h1 className="page-header">Exhibitor List</h1>
            <span className="header-sub-text">
              Event: {this.props.event.event_name}
            </span>
            <div className="body-content">
              <button
                onClick={() => this.onOpenModal(null)}
                disabled={!this.state.isLoaded}
                className="btn btn-primary body-content-btn"
              >
                + REPORT
              </button>
            </div>
            <div className="body-content-partial">
              <EventReportExhibitorList
                reports={this.state.reports}
                isLoaded={this.state.isLoaded}
                getReports={this.getReports}
              />
            </div>
          </div>
          {open && (
            <EventReportExhibitorEditForm
              open={open}
              key={open}
              addReport={this.addReport}
              onClose={this.onCloseModal}
              reports={this.state.reports}
            />
          )}
        </div>
      </>
    );
  }
}

let mapStateToProps = state => {
  return {
    permissions: state.activeUser.role_permissions,
    event: state.event,
    events: state.events,
    organizations: state.organizations
  };
};

export default connect(mapStateToProps, {
  getEvent,
  getOrganization,
  getExhibitorList,
  saveExhibitorReport,
  setNotification
})(withRouter(EventReportExhibitor));
