import React, { Component } from "react";
import Modal from "react-responsive-modal";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import {
  saveEventApplication,
  deleteEventApplication
} from "actions/event_applications";
import { setNotification } from "actions/notifications";
import ConfirmationModal from "lib/ui/confirmationModal";
import { required } from "lib/validation/form_validation_rules";
import { Loading } from "components/loading/loading";
import formHelper from "lib/ui/form/form";
import SelectObject from "lib/ui/form/select_object_non_redux";
import renderClassNames from "lib/ui/render_class_names";

class EventAppEditForm extends Component {
  constructor(props) {
    super(props);
    let { initialValues } = props;
    const isInsert = this.isInsert();
    this.state = {
      deleteConfirmationOpen: false,
      cantDeleteConfirmationOpen: false,
      updateConfirmationOpen: false,
      renderedOnce: false,
      fuzion_application_id: {
        key: "fuzion_application_id",
        value: (!isInsert && JSON.stringify(initialValues)) || "",
        valid: true,
        errorMessage: "",
        validation: [required]
      }
    };
  }

  onSave = async () => {
    if (this.checkValues()) {
      await this.updateValue();
    }
    const isFormValid = formHelper.isFormValid(this.state);
    if (!isFormValid.valid) {
      this.setState(isFormValid.formState);
      return;
    }
    let formData = formHelper.getFormValues(this.state);
    let selectedApplication = JSON.parse(formData.fuzion_application_id);
    const { event, saveEventApplication } = this.props;
    let formValues = {
      fuzion_event_id: event.fuzion_event_id,
      event_name: event.event_name,
      application_name: selectedApplication.application_name,
      fuzion_application_id: selectedApplication.fuzion_application_id,
      fuzion_organization_id: selectedApplication.fuzion_organization_id,
      fuzion_application_category_id:
        selectedApplication.fuzion_application_category_id
    };
    await saveEventApplication(formValues);
    this.onClose("Saved Application");
  };

  async setValue() {
    let options = this.organizationAppsAsOptions();
    for (let i = 0; i < this.props.applications.length; i++) {
      if (options[this.props.applications[i].fuzion_application_id]) {
        let name = "fuzion_application_id";
        let value = JSON.stringify(this.props.applications[i]);
        await this.setState(
          formHelper.mergeNewState(this.state, name, { value })
        );
        break;
      }
    }
  }

  onDelete = async () => {
    const { event, initialValues, deleteEventApplication } = this.props;
    let formValues = {
      fuzion_event_id: event.fuzion_event_id,
      application: initialValues
    };
    await deleteEventApplication(formValues);
    this.onClose("Deleted Application");
  };

  onClose = notification => {
    const { onClose, setNotification } = this.props;
    this.setState({ renderedOnce: false });
    onClose();
    if (notification !== null) {
      setNotification("good", notification);
    }
  };

  checkValues() {
    let list = this.organizationAppsAsOptions();
    if (this.state.fuzion_application_id.value === "{}") {
      return true;
    } else if (
      list[
        JSON.parse(this.state.fuzion_application_id.value).fuzion_application_id
      ]
    ) {
      return false;
    } else {
      return true;
    }
  }

  updateValue = async () => {
    let list = this.organizationAppsAsOptions();
    for (let i = 0; i < this.props.applications.length; i++) {
      if (list[this.props.applications[i].fuzion_application_id]) {
        let name = "fuzion_application_id";
        let value = JSON.stringify(this.props.applications[i]);
        this.setState(formHelper.mergeNewState(this.state, name, { value }));
        break;
      }
    }
  };

  onChange = e => {
    const {
      target: { name, value }
    } = e;
    this.setState(formHelper.mergeNewState(this.state, name, { value }));
  };

  onBlur = e => {
    const {
      target: { name, value }
    } = e;
    const validation = formHelper.validate(this.state, name, value);
    this.setState(formHelper.mergeNewState(this.state, name, validation));
  };

  organizationAppsAsOptions() {
    let options = {};
    const { update, applications, eventApplications } = this.props;
    if (!update) {
      let eventApplicationIds = new Set(
        eventApplications.map(
          eventApplication => eventApplication.fuzion_application_id
        )
      );
      let allApplications = new Set(applications);
      let applicationsDifference = new Set(
        [...allApplications].filter(
          application =>
            !eventApplicationIds.has(application.fuzion_application_id)
        )
      );

      // formatting data for react component -- I feel like there should be a better way to do this...
      applicationsDifference.forEach(application => {
        options[application.fuzion_application_id.toUpperCase()] = application;
      });
    } else {
      applications.forEach(application => {
        options[application.fuzion_application_id.toUpperCase()] = application;
      });
    }

    return options;
  }

  renderFields = () => {
    const isInsert = this.isInsert();
    const fuzionApplicationIdErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !this.state.fuzion_application_id.valid
    });
    return (
      <div className="form-group">
        <div>
          <SelectObject
            name={this.state.fuzion_application_id.key}
            value={this.state.fuzion_application_id.value}
            onChange={this.onChange}
            onBlur={this.onBlur}
            disabled={!isInsert}
            options={this.organizationAppsAsOptions()}
            className={fuzionApplicationIdErrStyle}
          ></SelectObject>
          {!this.state.fuzion_application_id.valid && (
            <div className="custom-text-danger">
              {this.state.fuzion_application_id.errorMessage}
            </div>
          )}
        </div>
      </div>
    );
  };

  isInsert() {
    return this.props.update === false;
  }

  renderTitle(isInsert) {
    return (
      <h4 className="modal-title">
        {isInsert ? "Add Application" : "Edit Application"}
      </h4>
    );
  }

  renderSubmitButton() {
    let { permissions } = this.props;
    let canUpdate =
      permissions.filter(p => p.permission_name === "SHOW_UPDATE").length > 0;
    if (canUpdate) {
      return (
        <button
          type="button"
          onClick={this.onSave}
          name="submitEventAppEditButton"
          className="btn btn-primary"
        >
          ADD
        </button>
      );
    } else {
      return null;
    }
  }

  renderCancelButton() {
    return (
      <button
        type="button"
        onClick={() => this.onClose(null)}
        name="cancelEventAppEditButton"
        className="btn btn-default"
      >
        CANCEL
      </button>
    );
  }

  renderDeleteButton() {
    let { permissions } = this.props;
    let canDelete =
      permissions.filter(p => p.permission_name === "SHOW_DELETE").length > 0;
    if (canDelete) {
      return (
        <button
          type="button"
          onClick={() => this.setState({ deleteConfirmationOpen: true })}
          name="deleteEventAppEditButton"
          className="btn btn-default-alt"
        >
          REMOVE
        </button>
      );
    } else {
      return null;
    }
  }

  renderButtons = () => {
    const isInsert = this.isInsert();
    return (
      <div className="form-group">
        <div className="formAddButtonContainer">
          {this.renderCancelButton()}
          {isInsert ? this.renderSubmitButton() : this.renderDeleteButton()}
        </div>
        <div className="required-label-jpom">* Required</div>
      </div>
    );
  };

  render() {
    const { open } = this.props;
    const isInsert = this.isInsert();
    return (
      <div>
        <Modal
          open={open}
          classNames={{ modal: "custom-modal" }}
          onClose={() => this.onClose(null)}
          closeOnOverlayClick={false}
          showCloseIcon={false}
        >
          <div>
            {this.renderTitle(isInsert)}
            <div className="modal-form">
              {!isInsert && this.props.initialValues.length < 1 && (
                <div>
                  <Loading />
                </div>
              )}
              <div>
                <form>
                  {this.renderFields()}
                  {this.renderButtons()}
                </form>
              </div>
            </div>
          </div>
        </Modal>
        <ConfirmationModal
          open={this.state.updateConfirmationOpen}
          header="Save Event Application?"
          text="Changes have been made to the application that will impact its behavior. Are you sure you want to save?"
          leftLabel="SAVE"
          onLeft={this.continueUpdate}
          rightLabel="CANCEL"
          onClose={() => this.setState({ updateConfirmationOpen: false })}
        />
        <ConfirmationModal
          open={this.state.cantDeleteConfirmationOpen}
          header="Can't Delete Event Application!"
          text="This event application has data associated to it so it cannot be deleted."
          rightLabel="GOT IT"
          onClose={() => this.setState({ cantDeleteConfirmationOpen: false })}
        />
        <ConfirmationModal
          open={this.state.deleteConfirmationOpen}
          header="Delete Event Application?"
          text="If deleted, this application will no longer have access to the event. Are you sure you want to delete?"
          leftLabel="DELETE"
          onLeft={() => this.onDelete()}
          rightLabel="CANCEL"
          onClose={() => this.setState({ deleteConfirmationOpen: false })}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    permissions: state.activeUser.role_permissions,
    event: state.event,
    applications: state.applications,
    eventApplications: state.eventApplications
  };
};

export default connect(mapStateToProps, {
  saveEventApplication,
  deleteEventApplication,
  setNotification
})(withRouter(EventAppEditForm));
