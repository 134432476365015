import types from "types/users";

export default (state = {}, action) => {
  switch (action.type) {
    case types.CREATE_USER:
      return action.payload;
    case types.GET_USER:
      return action.payload;
    case types.UPDATE_USER:
      return action.payload;
    default:
      return state;
  }
};
