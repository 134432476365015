import React, { Component } from "react";

import MappingListItem from "./mapping_list_item";
import emptyState from "assets/images/graphicEmptyState.svg";

import "./mappings_list.css";

class MappingsList extends Component {
  renderEmptyState(emptyMessage) {
    return (
      <div className="empty-state">
        <img
          className="empty-state-img"
          src={emptyState}
          alt="empty mappings"
        />
        <div className="empty-state-text">{emptyMessage}</div>
      </div>
    );
  }

  render() {
    let { data, isFiltered } = this.props;
    if (!data || data.length < 1) {
      return (
        <div>
          {this.renderEmptyState(
            isFiltered
              ? "None of the mappings match your search criteria"
              : "No Mappings"
          )}
        </div>
      );
    }
    return (
      <div className="row mappings-list-row">
        {data.map(mapping => (
          <MappingListItem key={mapping.fuzion_mappings_id} mapping={mapping} />
        ))}
      </div>
    );
  }
}

export default MappingsList;
