import React from "react";
import { render } from "react-dom";

import "bootstrap/dist/css/bootstrap.css";
import "./assets/css/fuzion.admin.css";

import Fuzion from "./routes/index";
import { CognitoProvider } from "components/cognito_provider";

const hideConsole = function(window) {
  const names = [
    "log",
    "debug",
    "info",
    "warn",
    "error",
    "assert",
    "dir",
    "dirxml",
    "group",
    "groupEnd",
    "time",
    "timeEnd",
    "count",
    "trace",
    "profile",
    "profileEnd"
  ];
  window.console = {};
  names.forEach(name => {
    window.console[name] = () => {};
  });
};

if (!window.console || process.env.NODE_ENV !== "development") {
  hideConsole(window);
}

render(
  <CognitoProvider>
    <Fuzion />
  </CognitoProvider>,
  document.querySelector(".container")
);
