import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import emptyState from "assets/images/graphicEmptyState.svg";

class EventList extends Component {
  renderEmptyState(emptyMessage) {
    return (
      <div className="empty-state">
        <img className="empty-state-img" src={emptyState} alt="empty events" />
        <div className="empty-state-text">{emptyMessage}</div>
      </div>
    );
  }

  renderList(event, index) {
    let to = `/events/${event.fuzion_event_id}`;
    let lastUpdateFormatted = moment
      .utc(event.last_mod_timestamp, "YYYY-MM-DD hh:mm:ss")
      .local()
      .format("MMMM Do, h:mm a");
    let eventStartDateFormatted = moment
      .utc(event.event_begin)
      .format("MMMM Do, YYYY");
    let eventEndDateFormatted = moment
      .utc(event.event_end)
      .format("MMMM Do, YYYY");
    let formattedEventDates =
      event.event_begin && event.event_end
        ? `${eventStartDateFormatted} to ${eventEndDateFormatted}`
        : "Not Configured";
    return (
      <Link
        key={index}
        to={to}
        className="list-item-container"
        onClick={() => console.log()}
      >
        <div className="list-item-content">
          <div className="list-title">{event.event_name}</div>
          <div className="status-div">
            <div>Client: {event.partner_name}</div>
            <div>Last Updated: {lastUpdateFormatted}</div>
            <div>Event Dates: {formattedEventDates}</div>
          </div>
        </div>
      </Link>
    );
  }

  render() {
    if (!this.props.item || this.props.item.length < 1) {
      return (
        <div>
          {this.renderEmptyState(
            this.props.eventsFiltered
              ? "None of the events match your search criteria"
              : "Nothing in Events"
          )}
        </div>
      );
    }
    let active = this.props.item
      .filter(event => event.status_flag > 0)
      .map((event, index) => this.renderList(event, index));
    let inactive = this.props.item
      .filter(event => event.status_flag === 0)
      .map((event, index) => this.renderList(event, index));

    return (
      <div>
        <div className="list-sub-heading">
          {active.length > 0 ? "Active" : ""}
        </div>
        {active}
        <div className="list-sub-heading">
          {inactive.length > 0 ? "Inactive" : ""}
        </div>
        {inactive}
      </div>
    );
  }
}

export default EventList;
