import { request } from "lib/data/api";
import types from "types/roles";
import global from "types/global";

export const getRoles = () => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "GET",
        state: state,
        route: "/v1/security-roles/all"
      });
      dispatch({
        type: types.GET_ROLES,
        payload: response.payload
      });
      return response.payload;
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.roles.getRoles failed"
      });
    }
  };
};

export const getOrganizationRoles = () => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "GET",
        state: state,
        route: "/v1/security-roles/organization"
      });
      dispatch({
        type: types.GET_ORGANIZATION_ROLES,
        payload: response.payload
      });
      return response.payload;
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.roles.getOrganizationRoles failed"
      });
    }
  };
};

export const getRole = fuzion_security_role_id => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "GET",
        state: state,
        route: `/v1/security-roles/${fuzion_security_role_id}`
      });
      dispatch({
        type: types.GET_ROLE,
        payload: response.payload
      });
      return response.payload;
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.roles.getRole failed"
      });
    }
  };
};

export const createRole = () => {
  return dispatch => {
    dispatch({
      type: types.CREATE_ROLE,
      payload: null
    });
  };
};

export const getRolesCount = () => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "GET",
        state: state,
        route: "/security-roles/count"
      });
      dispatch({
        type: types.GET_ROLES_COUNT,
        payload: response.payload
      });
      return response.payload;
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.roles.getRolesCount failed"
      });
    }
  };
};

export const saveRole = role => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "POST",
        state: state,
        route: "/v1/security-roles",
        data: role
      });
      return dispatch({
        type: types.SAVE_ROLE,
        payload: response.payload
      });
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.roles.saveRole failed"
      });
    }
  };
};

export const deleteRole = role => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "DELETE",
        state: state,
        route: `/v1/security-roles/${role.fuzion_security_role_id}`,
        data: role
      });
      return dispatch({
        type: types.DELETE_ROLE,
        payload: response.payload
      });
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.roles.deleteRole failed"
      });
    }
  };
};

export const updateRole = role => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "PUT",
        state: state,
        route: `/v1/security-roles/${role.fuzion_security_role_id}`,
        data: role
      });
      dispatch({
        type: types.UPDATE_ROLE,
        payload: response.payload
      });
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.roles.updateRole failed"
      });
    }
  };
};

export const getRolePermissions = roleId => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "GET",
        state: state,
        route: `/v1/security-roles/${roleId}/permissions`
      });
      return dispatch({
        type: types.GET_ROLE_PERMISSIONS,
        payload: response.payload
      });
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.roles.getRolePermissions failed"
      });
    }
  };
};

export const setRolePermission = data => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "PUT",
        state: state,
        route: `/security-roles/${data.fuzion_security_role_id}/permissions/${data.fuzion_security_role_permission_rel_id}`,
        data: data
      });
      dispatch({
        type: types.SET_ROLE_PERMISSION,
        payload: response.payload
      });
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.roles.setRolePermission failed"
      });
    }
  };
};
