import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { setNotification } from "actions/notifications";
import ConfirmationModal from "lib/ui/confirmationModal";
import {
  required,
  minLength3,
  maxLength256
} from "lib/validation/form_validation_rules";

import Select from "lib/ui/form/select_non_redux";
import TextArea from "lib/ui/form/text_area_non_redux";
import {
  saveApiRoute,
  updateApiRoute,
  deleteApiRoute
} from "actions/api_routes";
import formHelper from "lib/ui/form/form";
import renderClassNames from "lib/ui/render_class_names";

class ApiRouteEditForm extends Component {
  constructor(props) {
    super(props);
    let { initialValues, operation } = props;
    this.state = {
      updateFormValues: null,
      updateConfirmationOpen: false,
      deleteConfirmationOpen: false,
      api_route_name: {
        key: "api_route_name",
        value: (initialValues && initialValues.api_route_name) || "",
        valid: true,
        errorMessage: "",
        validation: [required, minLength3]
      },
      api_route_descr: {
        key: "api_route_descr",
        value: (initialValues && initialValues.api_route_descr) || "",
        valid: true,
        errorMessage: "",
        validation: [maxLength256]
      },
      status_flag: {
        key: "status_flag",
        value:
          operation === "update"
            ? initialValues && initialValues.status_flag.toString()
            : "",
        valid: true,
        errorMessage: "",
        validation: [required]
      }
    };
  }

  onSave = async () => {
    const isFormValid = formHelper.isFormValid(this.state);
    if (!isFormValid.valid) {
      this.setState(isFormValid.formState);
      return;
    }
    let form = formHelper.getFormValues(this.state);
    const { onClose, saveApiRoute, setNotification, apiCategory } = this.props;
    form.fuzion_api_category_id = apiCategory.fuzion_api_category_id;
    formHelper.removeUnwantedValues(form);
    await saveApiRoute(form);
    onClose();
    setNotification("good", "API Route Created");
  };

  onUpdate = async () => {
    const isFormValid = formHelper.isFormValid(this.state);
    if (!isFormValid.valid) {
      this.setState(isFormValid.formState);
      return;
    }
    let form = formHelper.getFormValues(this.state);
    form.fuzion_api_categonry_id = this.props.apiCategory.fuzion_api_category_id;
    this.setState({
      updateFormValues: form,
      updateConfirmationOpen: true
    });
  };

  continueUpdate = async () => {
    const { onClose, updateApiRoute, setNotification } = this.props;
    await updateApiRoute(this.state.updateFormValues);
    onClose();
    setNotification("good", "API Route Updated");
  };

  onDelete = async () => {
    let { deleteApiRoute, setNotification, onClose } = this.props;
    await deleteApiRoute();
    onClose();
    setNotification("good", "API Route Deleted ");
  };

  renderTitle() {
    return <h4 className="modal-title">{this.props.title}</h4>;
  }

  renderApiRoute() {
    const initialLength =
      this.props.initialValues && this.props.initialValues.api_route_descr
        ? this.props.initialValues.api_route_descr.length
        : 0;

    return (
      <div>
        <div className="form-group">
          <div className="field-label">Name *</div>
          <div>
            <input
              name={this.state.api_route_name.key}
              type="text"
              value={this.state.api_route_name.value}
              onChange={this.onChange}
              onBlur={this.onBlur}
              className="form-control"
              placeholder="Name"
            />
          </div>
          <div className="field-label">This name is used in the UI</div>
          {!this.state.api_route_name.valid && (
            <div className="custom-text-danger">
              {this.state.api_route_name.errorMessage}
            </div>
          )}
        </div>
        <div className="form-group">
          <div className="field-label">Description (optional)</div>
          <div>
            <TextArea
              name={this.state.api_route_descr.key}
              value={this.state.api_route_descr.value}
              onChange={this.onChange}
              onBlur={this.onBlur}
              label="Description"
              rows={5}
              showLength={true}
              initialLength={initialLength}
              totalLength={256}
            ></TextArea>
            {!this.state.api_route_descr.valid && (
              <div className="custom-text-danger">
                {this.state.api_route_descr.errorMessage}
              </div>
            )}
          </div>
        </div>
        <div className="form-group">
          <div className="field-label">Status</div>
          <div>
            <Select
              name={this.state.status_flag.key}
              value={this.state.status_flag.value}
              onChange={this.onChange}
              onBlur={this.onBlur}
              options={{
                1: "Active",
                0: "Inactive"
              }}
            ></Select>
          </div>
          {!this.state.status_flag.valid && (
            <div className="custom-text-danger">
              {this.state.status_flag.errorMessage}
            </div>
          )}
        </div>
      </div>
    ); //endreturn jsx
  } //end renderOrganization

  renderButtons() {
    const style = this.getStyle();
    const onSubmit = this.isInsert() ? this.onSave : this.onUpdate;

    return (
      <div className="form-group">
        <div className="col-md-9">
          <button
            type="button"
            name="addApiRouteButton"
            className={style.submitStyle}
            onClick={onSubmit}
          >
            {style.submitText}
          </button>
          {!this.isInsert() && (
            <button
              type="button"
              onClick={() => this.setState({ deleteConfirmationOpen: true })}
              name="deleteApiRouteButton"
              className={style.otherStyle}
            >
              REMOVE
            </button>
          )}
          <button
            type="button"
            onClick={this.props.onClose}
            name="cancelApiRouteButton"
            className={style.submitStyle}
          >
            CANCEL
          </button>
        </div>
      </div>
    ); //end return jsx
  } //end renderButtons

  isInsert() {
    return this.props.operation === "save";
  }

  getStyle() {
    const isInsert = this.isInsert();
    const apiRouteNameErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !this.state.api_route_name.valid
    });
    const apiRouteDescErrStyle = renderClassNames({
      "form-control-textarea": true,
      "custom-danger-border": !this.state.api_route_descr.valid
    });
    const statusFlagErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !this.state.status_flag.valid
    });
    return {
      otherStyle: isInsert ? "btn btn-default pull-right" : "btn btn-default",
      submitStyle: isInsert ? "btn btn-primary pull-right" : "btn btn-primary",
      submitText: isInsert ? "CREATE" : "SAVE",
      apiRouteNameErrStyle: apiRouteNameErrStyle,
      apiRouteDescErrStyle: apiRouteDescErrStyle,
      statusFlagErrStyle: statusFlagErrStyle
    };
  }

  render() {
    return (
      <div>
        {this.props.title && this.renderTitle()}
        <div className="modal-form">
          <div className="col-md-12">
            <form className="form-horizontal">
              {this.renderApiRoute()}
              {this.renderButtons()}
            </form>
          </div>
        </div>
        <ConfirmationModal
          open={this.state.updateConfirmationOpen}
          header="Save Route?"
          text="Changes have been made to the API Route that will impact its behavior. Are you sure you want to save?"
          leftLabel="SAVE"
          onLeft={this.continueUpdate}
          rightLabel="CANCEL"
          onClose={() => this.setState({ updateConfirmationOpen: false })}
        />
        <ConfirmationModal
          open={this.state.deleteConfirmationOpen}
          header="Remove Route?"
          text="This API Route will no longer be available in the API."
          leftLabel="REMOVE"
          onLeft={this.onDelete}
          rightLabel="CANCEL"
          onClose={() => this.setState({ deleteConfirmationOpen: false })}
        />
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {
    //enableReinitialize: true,
    //apiCategory: state.apiCategory,
    //initialValues: state.apiRoute
  };
};

export default connect(mapStateToProps, {
  setNotification,
  saveApiRoute,
  updateApiRoute,
  deleteApiRoute
})(withRouter(ApiRouteEditForm));
