import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import { imageAssets } from "fuzion-core-lib";
import emptyState from "assets/images/graphicEmptyState.svg";

class OrganizationList extends Component {
  renderEmptyState(emptyMessage) {
    return (
      <div className="empty-state">
        <img className="empty-state-img" src={emptyState} alt="empty events" />
        <div className="empty-state-text">{emptyMessage}</div>
      </div>
    );
  }

  renderAvatar(organization) {
    const image =
      organization.organization_image_file || imageAssets.FUZION_AVATAR;
    return (
      <div className="list-avatar-div">
        <img src={image} className="list-avatar-image" alt="user avatar" />
      </div>
    );
  }

  renderList(organization, index) {
    let to = `/organizations/${organization.fuzion_organization_id}`;
    let lastUpdateFormatted = moment
      .utc(organization.last_mod_timestamp, "YYYY-MM-DD hh:mm:ss")
      .local()
      .format("MMMM Do, h:mm a");
    return (
      <Link
        key={index}
        to={to}
        className="list-item-container"
        onClick={() => console.log()}
      >
        {this.renderAvatar(organization)}
        <div className="list-item-content">
          <div className="list-title">{organization.partner_name}</div>
          <div className="status-div">
            <div>Last Updated: {lastUpdateFormatted}</div>
          </div>
        </div>
      </Link>
    );
  }

  render() {
    if (!this.props.item || this.props.item.length < 1) {
      return (
        <div>
          {this.renderEmptyState(
            this.props.orgsFiltered
              ? "None of the organizations match your search criteria"
              : "Nothing in organizations"
          )}
        </div>
      );
    }
    let active = this.props.item
      .filter(organization => organization.status_flag > 0)
      .map((organization, index) => this.renderList(organization, index));
    let inactive = this.props.item
      .filter(organization => organization.status_flag === 0)
      .map((organization, index) => this.renderList(organization, index));

    return (
      <div>
        <div className="list-sub-heading">
          {active.length > 0 ? "Active" : ""}
        </div>
        {active}
        <div className="list-sub-heading">
          {inactive.length > 0 ? "Inactive" : ""}
        </div>
        {inactive}
      </div>
    );
  }
}

export default OrganizationList;
