import React, { Component } from "react";
import { connect } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import withAuth from "components/hoc/with_auth";
import Header from "components/header/header";
import Roles from "./roles";
import Categories from "./categories";
import Users from "./users";

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: this.tabs.categories, //default tab
      canViewAppCategories: this.getPermission("APP_CATEGORY_VIEW"),
      canViewSecurityRoles: this.getPermission("SECURITY_ROLE_VIEW"),
      canViewAdminUsers: this.getPermission("USER_ADMIN_VIEW")
    };
  }

  tabs = {
    roles: 0,
    categories: 1,
    users: 2
  };

  indexes = ["roles", "categories", "users"];

  loadTabArray = () => {
    let {
      canViewSecurityRoles,
      canViewAdminUsers,
      canViewAppCategories
    } = this.state;
    if (!canViewSecurityRoles) {
      let rolesIndex = this.indexes.indexOf("roles");
      if (rolesIndex > -1) {
        this.indexes.splice(rolesIndex, 1);
      }
    }

    if (!canViewAppCategories) {
      let categoriesIndex = this.indexes.indexOf("categories");
      if (categoriesIndex > -1) {
        this.indexes.splice(categoriesIndex, 1);
      }
    }

    if (!canViewAdminUsers) {
      let usersIndex = this.indexes.indexOf("users");
      if (usersIndex > -1) {
        this.indexes.splice(usersIndex, 1);
      }
    }

    return this.indexes;
  };

  loadTabObj = () => {
    let {
      canViewSecurityRoles,
      canViewAdminUsers,
      canViewAppCategories
    } = this.state;

    if (!canViewSecurityRoles) {
      delete this.tabs["roles"];
    }

    if (!canViewAppCategories) {
      delete this.tabs["categories"];
    }

    if (!canViewAdminUsers) {
      delete this.tabs["users"];
    }

    let index = 0;
    for (let tab in this.tabs) {
      this.tabs[tab] = index;
      index++;
    }

    return this.tabs;
  };

  selectTab = () => {
    const { tab } = this.props.match.params;

    if (tab) {
      this.loadTabObj();
      const index = this.tabs[tab];
      if (index !== this.state.index) {
        this.setState({ index: index });
      }
    }
  };

  componentDidMount = this.selectTab;
  componentDidUpdate = this.selectTab;

  changeTab = index => {
    this.loadTabArray();
    const target = `/admin/${this.indexes[index]}`;
    this.props.history.push(target);
  };

  getPermission = permissionName => {
    let { permissions } = this.props;
    return (
      permissions.filter(p => p.permission_name === permissionName).length > 0
    );
  };

  render = () => {
    let {
      canViewSecurityRoles,
      canViewAdminUsers,
      canViewAppCategories
    } = this.state;

    const tabListClass = `nav nav-tabs admin-tab-subheader ${this.props
      .sidebar && "sidebar-spacer"}`;

    return (
      <>
        <Header />
        <Tabs selectedIndex={this.state.index} onSelect={this.changeTab}>
          <TabList className={tabListClass}>
            <div className="tab-inner-container">
              {canViewSecurityRoles && <Tab className="tab">ROLES</Tab>}
              {canViewAppCategories && <Tab className="tab">CATEGORIES</Tab>}
              {canViewAdminUsers && <Tab className="tab">USERS</Tab>}
            </div>
          </TabList>
          {canViewSecurityRoles && (
            <TabPanel>
              <Roles />
            </TabPanel>
          )}
          {canViewAppCategories && (
            <TabPanel>
              <Categories />
            </TabPanel>
          )}
          {canViewAdminUsers && (
            <TabPanel>
              <Users />
            </TabPanel>
          )}
        </Tabs>
      </>
    );
  };
}

const mapStateToProps = state => {
  return {
    permissions: state.activeUser.role_permissions,
    sidebar: state.sidebar
  };
};

export default connect(mapStateToProps)(withAuth(Admin));
