import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";

import { Loading } from "components/loading/loading";
import { getCategories } from "actions/categories";

class CategoryList extends Component {
  renderList(category, index) {
    let to = `/admin-app-category/${category.fuzion_application_category_id}`;
    let lastUpdateFormatted = moment
      .utc(category.last_mod_timestamp, "YYYY-MM-DD hh:mm:ss")
      .local()
      .format("MMMM Do, h:mm a");
    return (
      <Link key={index} to={to} className="list-item-container">
        <div className="list-item-content">
          <div className="list-title">
            {category.application_category_friendly_name}
          </div>
          <div className="status-div">
            <div>Last Updated: {lastUpdateFormatted}</div>
          </div>
        </div>
      </Link>
    );
  }

  render() {
    if (!this.props.categories)
      return (
        <div>
          <Loading />
        </div>
      );
    let active = this.props.categories
      .filter(category => category.status_flag > 0)
      .map((category, index) => this.renderList(category, index));
    let inactive = this.props.categories
      .filter(category => category.status_flag === 0)
      .map((category, index) => this.renderList(category, index));

    return (
      <div>
        <div className="list-sub-heading">
          {active.length > 0 ? "Active" : ""}
        </div>
        {active}
        <div className="list-sub-heading">
          {inactive.length > 0 ? "Inactive" : ""}
        </div>
        {inactive}
      </div>
    );
  }
}

let mapStateToProps = state => {
  return {
    permissions: state.activeUser.role_permissions,
    category: state.category,
    categories: state.categories
  };
};

export default connect(mapStateToProps, { getCategories })(CategoryList);
