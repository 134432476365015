import React, { Component } from "react";
import { withRouter } from "react-router";

// import Manager from "lib/auth/manager";

class Index extends Component {
  componentDidMount() {
    this.props.history.push("/events");
  }

  onLoginClick = e => {
    e.preventDefault();
    // let manager = new Manager();
    // manager.signIn();
  };

  render() {
    return (
      <div className="col-sm-9 col-sm-offset-3 col-md-10 col-md-offset-2 main">
        <h1 className="page-header">Login</h1>
        <div>
          You have not been authenticated.
          <button className="hidden-button" onClick={this.onLoginClick}>
            Click here
          </button>
          to log in.
        </div>
      </div>
    );
  }
}

export default withRouter(Index);
