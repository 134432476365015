import React, { Component } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { setSideBarState } from "actions/sidebar";
import logoFuzion from "assets/images/logoFuzion.svg";
import iconUserDark from "assets/images/iconUserDark.svg";
import iconEventDark from "assets/images/iconEventDark.svg";
import iconLinkDark from "assets/images/iconLinkDark.svg";
import iconPlugDark from "assets/images/iconPlugDark.svg";
import iconGroupDark from "assets/images/iconGroupDark.svg";
import iconSettingsDark from "assets/images/iconSettingsDark.svg";
import iconHelpDark from "assets/images/iconHelpDark.svg";
import iconExitDark from "assets/images/iconExitDark.svg";
import Cognito from "../lib/auth/cognito";

export class SideBar extends Component {
  componentDidMount() {
    window.addEventListener("resize", this.resize);
    this.setSideBar();
  }

  hasPermission(permissionName) {
    let perm = null;
    if (this.props.permissions) {
      perm = this.props.permissions.filter(
        p => p.permission_name === permissionName.toUpperCase().trim()
      );
    }
    return perm !== null && perm.length > 0;
  }

  //   setSideBar() {
  //     let windowWidth = window.innerWidth;
  //     if (windowWidth > 768) {
  //       this.props.setSideBarState(true);
  //     } else {
  //       this.props.setSideBarState(false);
  //     }
  //   }

  setSideBar = async () => {
    let isVisible = false;
    const user = await Cognito().getSession();
    if (window.innerWidth > 768) {
      isVisible = true;
    }
    if (!user || user.isExpired) {
      isVisible = false;
    }
    await this.props.setSideBarState(isVisible);
  };

  resize = () => {
    this.forceUpdate();
    this.setSideBar();
  };

  logout = e => {
    e.preventDefault();
    Cognito().logout();
  };

  renderItem(to, text, imgSrc, isAvatar = false) {
    let { pathname } = this.props.location;
    let active = pathname.startsWith(to);
    let activeClass = active ? "active" : "";
    let imgOpacity = active ? { opacity: 1 } : { opacity: 0.5 };
    let iconClass = isAvatar ? "icon-menu icon-menu-avatar" : "icon-menu";
    return (
      <li className={activeClass}>
        <Link to={to} className="sidebar-link">
          <div className={iconClass} style={imgOpacity}>
            <img src={imgSrc} alt="sidebar item" />
          </div>
          <div className="sidebar-text">{text}</div>
        </Link>
      </li>
    );
  }

  render() {
    if (!Cognito().haveUser()) {
      return null;
    }
    if (!this.props.sidebar) {
      return <div className="sidebar" />;
    }

    const hasAdminAccess = this.hasPermission("ADMIN_ACCESS");
    const canViewEvents = this.hasPermission("SHOW_VIEW");
    const canViewApis = this.hasPermission("API_VIEW");
    const canViewOrganizations = this.hasPermission("ORGANIZATION_VIEW");
    const canViewAdmin = this.hasPermission("USER_ADMIN_VIEW");

    return (
      <div className="sidebar sidebar-open">
        <div className="navbar-header">
          <Link to="/events" className="navbar-brand" role="button">
            <img src={logoFuzion} className="logomain" alt="fuzion logo" />
          </Link>
        </div>
        <ul className="sidebar-list">
          {hasAdminAccess &&
            this.renderItem("/account", "My Account", iconUserDark, true)}
          {canViewEvents && this.renderItem("/events", "Events", iconEventDark)}
          {canViewAdmin &&
            this.renderItem("/mappings", "Mappings", iconLinkDark)}
          {canViewApis && this.renderItem("/apis", "APIs", iconPlugDark)}
          {canViewOrganizations &&
            this.renderItem("/organizations/", "Organizations", iconGroupDark)}
          {canViewAdmin && this.renderItem("/admin", "Admin", iconSettingsDark)}
          <li>
            <a
              href="mailto:fuzion-support@freeman.com"
              className="sidebar-link"
            >
              <div className="icon-menu" style={{ opacity: 0.5 }}>
                <img src={iconHelpDark} alt="Help" />
              </div>
              <div className="sidebar-text">Help</div>
            </a>
          </li>
          <li>
            <button className="sidebar-button-link" onClick={this.logout}>
              <div className="icon-menu" style={{ opacity: 0.5 }}>
                <img src={iconExitDark} alt="logout" />
              </div>
              <div className="sidebar-text">Logout</div>
            </button>
          </li>
        </ul>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    permissions: state.activeUser ? state.activeUser.role_permissions : null,
    sidebar: state.sidebar
  };
};

export default connect(mapStateToProps, {
  setSideBarState
})(withRouter(SideBar));
