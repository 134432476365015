import React, { Component } from "react";
import Modal from "react-responsive-modal";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

import formHelper from "lib/ui/form/form";
import { getEvent } from "actions/events";
import { required } from "lib/validation/form_validation_rules";
import { setNotification } from "actions/notifications";
import Date from "lib/ui/form/date_non_redux";
import renderClassNames from "lib/ui/render_class_names";

class EventReportExhibitorEditForm extends Component {
  initialStartTime =
    this.props.reports && this.props.reports[0]
      ? moment(this.props.reports[0].last_modified)
      : moment()
          .startOf("day")
          .subtract(3, "d");
  constructor(props) {
    super(props);
    this.state = {
      inputDisabled: false,
      modalHidden: false,
      event_report_start_date: {
        key: "event_report_start_date",
        value: this.initialStartTime,
        valid: true,
        errorMessage: "",
        validation: [required]
      },
      event_report_start_time: {
        key: "event_report_start_time",
        value: this.initialStartTime,
        valid: true,
        errorMessage: "",
        validation: [required]
      },
      event_report_end_date: {
        key: "event_report_end_date",
        value: moment().utc(),
        valid: true,
        errorMessage: "",
        validation: [required]
      },
      event_report_end_time: {
        key: "event_report_end_time",
        value: moment().utc(),
        valid: true,
        errorMessage: "",
        validation: [required]
      }
    };
  }

  onDateChange = (name, value) => {
    this.setState(formHelper.mergeNewState(this.state, name, { value }));
  };

  onBlur = (name, value) => {
    const validation = formHelper.validate(this.state, name, value);
    this.setState(formHelper.mergeNewState(this.state, name, validation));
  };

  onClose = () => {
    const { onClose } = this.props;
    onClose();
  };

  getStyle() {
    const eventReportStartDateErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !this.state.event_report_start_date.valid
    });
    const eventReportStartTimeErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !this.state.event_report_start_time.valid
    });
    const eventReportEndDateErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !this.state.event_report_end_date.valid
    });
    const eventReportEndTimeErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !this.state.event_report_end_time.valid
    });

    return {
      eventReportStartDateErrStyle,
      eventReportStartTimeErrStyle,
      eventReportEndDateErrStyle,
      eventReportEndTimeErrStyle
    };
  }

  renderSubmitButton() {
    return (
      <button
        type="button"
        onClick={this.onSubmit}
        disabled={this.state.inputDisabled}
        name="submitEventAppEditButton"
        className="btn btn-primary"
      >
        GENERATE
      </button>
    );
  }

  renderCancelButton() {
    return (
      <button
        type="button"
        onClick={() => this.onClose(null)}
        disabled={this.state.inputDisabled}
        name="cancelEventAppEditButton"
        className="btn btn-default"
      >
        CANCEL
      </button>
    );
  }

  renderButtons = () => {
    return (
      <div className="form-group">
        <div className="formAddButtonContainer">
          {this.renderCancelButton()}
          {this.renderSubmitButton()}
        </div>
        <div className="required-label-jpom">* Required</div>
      </div>
    );
  };

  onSubmit = async () => {
    const isFormValid = formHelper.isFormValid(this.state);
    if (!isFormValid.valid) {
      this.setState(isFormValid.formState);
      return;
    }
    this.setState({ inputDisabled: true });

    let formValues = formHelper.getFormValues(this.state);

    formValues.event_id = this.props.event.fuzion_event_id;
    formValues.event_name = this.props.event.event_name.replace(/\W/g, "");

    let dateStart = moment(formValues.event_report_start_date).format(
      "YYYY-MM-DD"
    );
    let timeStart = moment(formValues.event_report_start_time).format("HH:mm");
    let dateEnd = moment(formValues.event_report_end_date).format("YYYY-MM-DD");
    let timeEnd = moment(formValues.event_report_end_time).format("HH:mm");

    let timestampStart = moment(`${dateStart} ${timeStart}`);
    let timestampEnd = moment(`${dateEnd} ${timeEnd}`);

    if (timestampStart.isSameOrAfter(timestampEnd)) {
      const newReportEndTime = Object.assign(
        {},
        {
          ...this.state.event_report_end_time,
          valid: false,
          errorMessage: "End time must follow start time"
        }
      );
      this.setState({
        event_report_end_time: newReportEndTime,
        inputDisabled: false
      });
      return;
    }

    formValues.event_timeframe_formatted = `${timestampStart.format(
      "MMDDYYYY"
    )}-${timestampEnd.format("MMDDYYYY")}`;

    formValues.last_modified_timestamp_start = moment(
      `${dateStart} ${timeStart}`
    ).format("YYYY-MM-DD HH:mm");
    formValues.last_modified_timestamp_end = moment(
      `${dateEnd} ${timeEnd}`
    ).format("YYYY-MM-DD HH:mm");

    formHelper.removeUnwantedValues(formValues);

    this.setState({ modalHidden: true });
    this.props.addReport(formValues);
  };

  continueUpdate = async () => {
    const isFormValid = formHelper.isFormValid(this.state);
    if (!isFormValid.valid) return;
  };

  render() {
    const { open, event } = this.props;
    const style = this.getStyle();
    const {
      event_report_start_date,
      event_report_start_time,
      event_report_end_date,
      event_report_end_time,
      modalHidden
    } = this.state;
    return (
      <div>
        <Modal
          open={open}
          classNames={{
            modal: `custom-modal ${modalHidden && "custom-modal-hidden"}`
          }}
          onClose={() => this.onClose(null)}
          closeOnOverlayClick={false}
          showCloseIcon={false}
        >
          <div>
            <h4 className="modal-title">Generate Exhibitor List Report</h4>
            <form className="modal-form" autoComplete="off">
              <div className="form-group">
                <div className="field-label">Event Name *</div>
                <input
                  name="event_report_friendly_name"
                  type="text"
                  className="form-control"
                  disabled={true}
                  value={event.event_name}
                />
              </div>
              <div className="form-group">
                <div className="field-label">Start Date *</div>
                <Date
                  label="event_report_start_date"
                  name="event_report_start_date"
                  className={style.eventReportStartDateErrStyle}
                  value={event_report_start_date.value}
                  onChange={this.onDateChange}
                  onBlur={this.onBlur}
                  maxDate={event_report_end_date.value}
                  normalize={value => moment(value).format("YYYY-MM-DD")}
                  disabled={this.state.inputDisabled}
                />
                {!event_report_start_date.valid && (
                  <div className="custom-text-danger">
                    {event_report_start_date.errorMessage}
                  </div>
                )}
              </div>
              <div className="form-group">
                <div className="field-label">Start Time (UTC) *</div>
                <Date
                  label="event_report_start_time"
                  name="event_report_start_time"
                  className={style.eventReportStartTimeErrStyle}
                  value={event_report_start_time.value}
                  placeholder="Select a time"
                  onChange={this.onDateChange}
                  onBlur={this.onBlur}
                  dateFormat="h:mm a"
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  disabled={this.state.inputDisabled}
                />
                {!event_report_start_time.valid && (
                  <div className="custom-text-danger">
                    {event_report_start_time.errorMessage}
                  </div>
                )}
              </div>
              <div className="form-group">
                <div className="field-label">End Date *</div>
                <Date
                  label="event_report_end_date"
                  name="event_report_end_date"
                  className={style.eventReportEndDateErrStyle}
                  value={event_report_end_date.value}
                  onChange={this.onDateChange}
                  onBlur={this.onBlur}
                  minDate={event_report_start_date.value}
                  normalize={value => moment(value).format("YYYY-MM-DD")}
                  disabled={this.state.inputDisabled}
                />
                {!event_report_end_date.valid && (
                  <div className="custom-text-danger">
                    {event_report_end_date.errorMessage}
                  </div>
                )}
              </div>
              <div className="form-group">
                <div className="field-label">End Time (UTC) *</div>
                <Date
                  label="event_report_end_time"
                  name="event_report_end_time"
                  className={style.eventReportEndTimeErrStyle}
                  value={event_report_end_time.value}
                  placeholder="Select a time"
                  onChange={this.onDateChange}
                  onBlur={this.onBlur}
                  dateFormat="h:mm a"
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  disabled={this.state.inputDisabled}
                />
                {!event_report_end_time.valid && (
                  <div className="custom-text-danger">
                    {event_report_end_time.errorMessage}
                  </div>
                )}
              </div>
              {this.renderButtons()}
            </form>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    event: state.event,
    events: state.events
  };
};

export default connect(mapStateToProps, { getEvent, setNotification })(
  withRouter(EventReportExhibitorEditForm)
);
