import { request } from "lib/data/api";
import types from "types/application_keys";
import global from "types/global";

export const createApplicationKey = applicationKey => {
  return {
    type: types.CREATE_APPLICATION_KEY,
    payload: applicationKey
  };
};

export const getApplicationKeys = fuzion_application_id => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      let response = await request({
        method: "GET",
        state: state,
        route: `/v1/application-keys?app_id=${fuzion_application_id}`
      });
      dispatch({
        type: types.GET_APPLICATION_KEYS,
        payload: response.payload
      });
      return response.payload;
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.application_keys.getApplicationKeys failed"
      });
      return null;
    }
  };
};

export const reloadApplicationKey = applicationKey => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      let response = await request({
        method: "GET",
        state: state,
        route: `/v1/application-keys/${applicationKey.fuzion_application_key_id}?app_id=${applicationKey.fuzion_application_id}`
      });
      dispatch({
        type: types.RELOAD_APPLICATION_KEY,
        payload: response.payload
      });
      return response.payload;
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.application_keys.reloadApplicationKey"
      });
      return null;
    }
  };
};

export const getApplicationKey = applicationKey => {
  return {
    type: types.GET_APPLICATION_KEY,
    payload: applicationKey
  };
};

export const clearApplicationKey = applicationKey => {
  return {
    type: types.CLEAR_APPLICATION_KEY,
    payload: applicationKey
  };
};

export const saveApplicationKey = applicationKey => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "POST",
        state: state,
        route: "/v1/application-keys",
        data: applicationKey
      });
      return dispatch({
        type: types.SAVE_APPLICATION_KEY,
        payload: response.payload
      });
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.application_keys.saveApplicationKey failed"
      });
      return null;
    }
  };
};

export const updateApplicationKey = applicationKey => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "PUT",
        state: state,
        route: `/v1/application-keys/${applicationKey.fuzion_application_key_id}`,
        data: applicationKey
      });
      return dispatch({
        type: types.UPDATE_APPLICATION_KEY,
        payload: response.payload
      });
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.application_keys.updateApplicationKey failed"
      });
      return null;
    }
  };
};

export const deleteApplicationKey = applicationKey => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "DELETE",
        state: state,
        route: `/v1/application-keys/${applicationKey.fuzion_application_key_id}`,
        data: applicationKey
      });
      return dispatch({
        type: types.DELETE_APPLICATION_KEY,
        payload: response.payload
      });
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.application_keys.deleteApplicationKey failed"
      });
    }
  };
};
