const Joi = require("@hapi/joi");

export const required = value => (value ? null : "Required");
/*
 * These don't seem to exec properly inside the form...something to do with
 * the form state. Resorted to non-curried funcs w/ hard-coded lengths.
 * Revisit later...
 */
// export const minLength = min => value =>
// 	value && value.length < min ? `Must be ${min} characters or more` : null;
// export const maxLength = max => value =>
// 	value && value.length > max ? `Must be ${max} characters or less` : null;
export const minLength2 = value =>
  value && value.length < 2 ? `Must be ${2} characters or more` : null;
export const minLength3 = value =>
  value && value.length < 3 ? `Must be ${3} characters or more` : null;
export const maxLength500 = value =>
  value && value.length > 500 ? `Must be ${500} characters or less` : null;
export const maxLength256 = value =>
  value && value.length > 256 ? `Must be ${256} characters or less` : null;
export const maxLength60 = value =>
  value && value.length > 60 ? `Must be ${60} characters or less` : null;
export const number = value =>
  value && isNaN(Number(value)) ? "Must be a number" : null;
export const minValue = min => value =>
  value && value < min ? `Must be at least ${min}` : null;
export const commaSeparatedString = value =>
  value && !/^([^,]+,)*([^,]+){1}$/i.test(value)
    ? "Must contain comma separated values"
    : null;
//Use Joi to leverage built in email validation
let emailSchema = {
  value: Joi.string().email()
};

const emailValidate = param =>
  Joi.validate({ value: param }, emailSchema, err => {
    if (err) {
      return "Invalid email address";
    } else {
      return null;
    }
  });

export const email = value => value && emailValidate(value);
