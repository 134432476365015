import React, { Component } from "react";
import { connect } from "react-redux";

import OrganizationAppKeyEditForm from "./organization_app_key_edit_form";
import { setNotification } from "actions/notifications";
import Header from "components/header/header";

class OrganizationAppKeyDetail extends Component {
  render() {
    return (
      <div>
        <Header
          detailView={true}
          to={`/organizations/${this.props.match.params.fuzion_partner_id}/app/${this.props.match.params.fuzion_partner_app_id}/keys`}
        />
        <div className="organization-keys-page container-fluid">
          <h1 className="page-header">Application Key Settings</h1>
          {this.props.applicationKey &&
            Object.keys(this.props.applicationKey).length > 0 && (
              <OrganizationAppKeyEditForm
                application={this.props.application}
                initialValues={this.props.applicationKey}
                onClose={this.onSave}
                onSave={this.onSave}
                closeOnOverlayClick={false}
                operation="update"
              />
            )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    application: state.application,
    applicationKey: state.applicationKey
  };
};

export default connect(mapStateToProps, {
  setNotification
})(OrganizationAppKeyDetail);
