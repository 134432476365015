import React, { Component } from "react";
import PropTypes from "prop-types";

const KEY_CODES = {
  Enter: 13
};

class InputDebounce extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.initialValue
    };
  }

  UNSAFE_componentWillMount() {
    this.timer = null;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.initialValue !== this.props.initialValue) {
      this.setState({ value: nextProps.initialValue });
    }
  }

  handleChange = e => {
    const { debounce } = this.props;
    const { target } = e;

    clearTimeout(this.timer);
    this.setState({ value: target.value });

    if (debounce && debounce.on) {
      this.timer = setTimeout(
        () => this.triggerChange(target),
        debounce.interval
      );
    } else {
      this.triggerChange(target);
    }
  };

  handleKeyDown = e => {
    if (e.keyCode === KEY_CODES.Enter) {
      this.triggerChange(e.target);
    }
  };

  handleOnBlur = e => {
    this.triggerChange(e.target);
  };

  triggerChange = target => {
    const result = {
      name: target.name,
      value: target.value
    };
    if (target.type === "number") result.value = parseFloat(target.value);
    this.props.onChange(result);
  };

  render() {
    let { theme } = this.props;
    return (
      <input
        name={this.props.name}
        type={this.props.type}
        className={this.props.classes}
        value={this.state.value}
        onChange={this.handleChange}
        onKeyDown={this.handleKeyDown}
        onBlur={this.handleOnBlur}
        step="any"
        style={theme}
        autoComplete={this.props.autoComplete}
        placeholder={this.props.placeholder}
      />
    );
  }
}

InputDebounce.propTypes = {
  classes: PropTypes.string,
  debounce: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

InputDebounce.defaultProps = {
  debounce: {
    on: true,
    interval: 800
  }
};

export default InputDebounce;
