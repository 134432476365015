import React, { Component } from "react";

import withAuth from "components/hoc/with_auth";
import ApiCategoriesEditForm from "./api_category_edit_form";

class ApiSettings extends Component {
  render() {
    return (
      <div className="container-fluid">
        <div className="col-sm-9 col-sm-offset-3 col-md-10 col-md-offset-2 main">
          <h1 className="page-header">API Settings</h1>
          <ApiCategoriesEditForm operation="update" />
        </div>
      </div>
    );
  }
}

export default withAuth(ApiSettings);
