import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";

import withAuth from "components/hoc/with_auth";
import Header from "components/header/header";
import UserAvatar from "lib/ui/user_avatar";

class Account extends Component {
  removeEmail = () => {
    const { user } = this.props;
    const subject = "Request to be Forgotten";
    const body = `I, ${user.first_name} ${user.last_name} (${user.email}), am requesting to be forgotten from Fuzion and would like all of my Personal Identifiable Information removed.`;
    const href = `mailto:privacy@freemanco.com?subject=${subject}&body=${body}`;
    return href;
  };

  render() {
    const { user } = this.props;

    if (!user) {
      return <div>fetching active user details...</div>;
    }

    const formattedDate = moment
      .utc(user.last_mod_timestamp, "YYYY-MM-DD hh:mm:ss")
      .local()
      .format("MMMM Do, h:mm a");

    return (
      <>
        <Header />
        <div className="container-fluid">
          <div className="col-sm-9 col-sm-offset-3 col-md-10 col-md-offset-2 main">
            <h1 className="page-header" style={{ marginBottom: "40px" }}>
              My Account
            </h1>
            <UserAvatar />
            <h4 className="modal-title" style={{ marginBottom: "1px" }}>
              {user.first_name} {user.last_name}
            </h4>
            <div className="status-div">{user.email}</div>
            <div className="status-div">Last Updated: {formattedDate}</div>
            <a href={this.removeEmail()} className="formEditTextAnchorLink">
              Request to be Forgotten
            </a>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.activeUser
  };
};

export default connect(mapStateToProps)(withAuth(Account));
