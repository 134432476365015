import React, { Component } from "react";
import { connect } from "react-redux";
import withAuth from "components/hoc/with_auth";
import AppCategoriesEditForm from "./category_edit_form";

class Settings extends Component {
  render() {
    return (
      <div className="container-fluid tabs-spacer">
        <div className="col-sm-9 col-sm-offset-3 col-md-10 col-md-offset-2 main">
          <h1 className="page-header">Category Settings</h1>
          {this.props.selectedCategory &&
            Object.keys(this.props.selectedCategory).length > 0 && (
              <AppCategoriesEditForm
                key={this.props.selectedCategory.fuzion_application_category_id}
                initialValues={this.props.selectedCategory}
                operation="update"
              />
            )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedCategory: state.category
  };
};

export default connect(mapStateToProps)(withAuth(Settings));
