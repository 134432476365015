import types from "types/organizations";

export default (state = [], action) => {
  switch (action.type) {
    case types.GET_ORGANIZATIONS:
      return action.payload;
    default:
      return state;
  }
};
