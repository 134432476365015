import React, { Component } from "react";
import { connect } from "react-redux";

import withAuth from "components/hoc/with_auth";
import { getMappings, filterMappings } from "actions/mappings";
import MappingsList from "./mappings_list";
import ListSearch from "lib/ui/form/list_search";
import Header from "components/header/header";

import "assets/images/chevron-down-solid.svg";
import "./mappings.css";
import Modal from "react-responsive-modal";
import MappingEditForm from "./details/mapping_edit_form";

class Mappings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFiltered: false,
      filter: "",
      status: "",
      api: "",
      open: false,
      insert: false
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    this.props.getMappings();
  }

  filterMappings = () => {
    let { filter, status, api } = this.state;
    let { mappings } = this.props;
    if (filter || status || api) {
      return filterMappings(filter, status, api, mappings);
    }
    return mappings;
  };

  onListSearchChange = e => {
    this.setState({
      isFiltered: true,
      filter: e.value
    });
  };

  onStatusFilterChange = e => {
    this.setState({
      isFiltered: true,
      status: e.target.value
    });
  };

  onApiFilterChange = e => {
    this.setState({
      isFiltered: true,
      api: e.target.value
    });
  };

  onOpenModal = () => {
    this.setState({
      open: true,
      insert: true
    });
  };

  onCloseModal = () => {
    this.setState({ open: false, insert: false });
    this.loadData();
  };

  render() {
    let { permissions, mappings } = this.props;
    if (!permissions || !mappings) return null;
    let canCreate =
      permissions.filter(p => p.permission_name === "ADMIN_ACCESS").length > 0;
    return (
      <div className="col-sm-9 col-sm-offset-3 col-md-10 col-md-offset-2 main">
        <Header />
        <div className="container-fluid">
          <h1 className="page-header">Mappings</h1>

          <div className="body-content">
            <div>
              {canCreate && (
                <button
                  onClick={() => this.onOpenModal(null)}
                  className="btn btn-primary btn-add-event"
                >
                  + MAPPING
                </button>
              )}
            </div>
            <ListSearch
              onChange={this.onListSearchChange}
              placeholder="Search"
              autoComplete="off"
            />
            <div className="mapping-filter">
              <div className="filterdropdown mappings-filter-row">
                <div className="dropdownlabel mappings-filter-label status-filter">
                  Status:
                </div>
                <div className="mappings-filter-select-row dropdowncontrol">
                  <select
                    className="mappings-filter-select"
                    onChange={this.onStatusFilterChange}
                    onBlur={this.onStatusFilterChange}
                  >
                    <option className="mappings-filter-option" value="">
                      ALL
                    </option>
                    <option className="mappings-filter-option" value="1">
                      Published
                    </option>
                    <option className="mappings-filter-option" value="0">
                      Draft
                    </option>
                  </select>
                </div>
              </div>
              <div className="filterdropdown mappings-filter-row">
                <div className="dropdownlabel mappings-filter-label api-filter">
                  API:
                </div>
                <div className="dropdowncontrol mappings-filter-select-row">
                  <select
                    className="mappings-filter-select"
                    onChange={this.onApiFilterChange}
                    onBlur={this.onApiFilterChange}
                  >
                    <option className="mappings-filter-option" value="">
                      ALL
                    </option>
                    <option className="mappings-filter-option" value="abstract">
                      Abstract Management
                    </option>
                    <option className="mappings-filter-option" value="event">
                      Event
                    </option>
                    <option
                      className="mappings-filter-option"
                      value="exhibitor"
                    >
                      Exhibitor
                    </option>
                    <option
                      className="mappings-filter-option"
                      value="floorplan"
                    >
                      Floorplan
                    </option>
                    <option
                      className="mappings-filter-option"
                      value="registration"
                    >
                      Registration
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="body-content-partial">
            <MappingsList
              data={this.filterMappings()}
              isFiltered={this.state.isFiltered}
            />
          </div>
        </div>
        <Modal
          open={this.state.open}
          onClose={this.onCloseModal}
          classNames={{ modal: "custom-modal" }}
          closeOnOverlayClick={false}
          showCloseIcon={false}
          title="New mapping - General Settings"
        >
          <MappingEditForm
            open={this.state.open}
            operation="insert"
            insert={this.state.insert}
            onClose={this.onCloseModal}
          />
        </Modal>
      </div>
    );
  }
}

let mapStateToProps = state => {
  return {
    permissions: state.activeUser.role_permissions,
    mappings: state.mappings
  };
};

export default connect(mapStateToProps, {
  getMappings
})(withAuth(Mappings));
