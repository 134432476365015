import axios from "axios";

import { store } from "store";

const asyncValidate = (values, props) => {
  if (props && props.operation === "update") {
    return new Promise(resolve => {
      return resolve();
    });
  }

  return new Promise((resolve, reject) => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_ADMIN_URI}/v1/user-profiles/validate/${values.email}`,
      headers: {
        access_token: store.getState().identityUser.token
      }
    }).then(payload => {
      if (!payload.data.payload.validEmail) {
        // for some reason redux form wants the error this way?
        reject({
          email: "Error: email already in use",
          _email: "email already in use"
        });
      }
      resolve();
    });
  });
};

export default asyncValidate;
