import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";

import OrganizationUserProfileEditForm from "./users/organization_user_profile_edit_form";
import UserList from "./list/organization_users_list";
import withAuth from "components/hoc/with_auth";
import { Loading } from "components/loading/loading";
import { createUser, getOrganizationUsers } from "actions/users";

export class OrganizationUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    let fuzion_organization_id = this.props.match.params.fuzion_organization_id;
    this.props.getOrganizationUsers(fuzion_organization_id);
  }

  onOpenModal = () => {
    this.setState({ open: true });
    this.props.createUser();
  };

  onCloseModal = () => {
    this.setState({ open: false });
    this.loadData();
  };

  render() {
    let { users } = this.props;

    let { permissions } = this.props;
    if (!permissions) return <Loading />;
    let canCreate =
      permissions.filter(p => p.permission_name === "USER_ORGANIZATION_CREATE")
        .length > 0;

    return (
      <div className="container-fluid tabs-spacer">
        <div className="col-sm-9 col-sm-offset-3 col-md-10 col-md-offset-2 main">
          <h1 className="page-header">Organization Users</h1>
          <span className="header-sub-text">
            Organization:{" "}
            {this.props.organization && this.props.organization.partner_name}
          </span>
          <div className="body-content">
            {canCreate && (
              <button
                onClick={this.onOpenModal}
                className="btn btn-primary body-content-btn"
              >
                + USER
              </button>
            )}
          </div>
          <div className="body-content-partial">
            <UserList item={users} />
          </div>
        </div>
        <Modal
          open={this.state.open}
          onClose={this.onCloseModal}
          classNames={{ modal: "custom-modal" }}
          closeOnOverlayClick={false}
          showCloseIcon={false}
        >
          <OrganizationUserProfileEditForm
            onClose={this.onCloseModal}
            title="New Organization User"
            onSave={this.onCloseModal}
            operation="save"
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    users: state.users,
    permissions: state.activeUser.role_permissions,
    organization: state.organization
  };
};

export default connect(mapStateToProps, {
  createUser,
  getOrganizationUsers
})(withAuth(OrganizationUsers));
