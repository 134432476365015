import React, { Component } from "react";
import { connect } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import withAuth from "components/hoc/with_auth";
import Header from "components/header/header";
import OrganizationAppKeys from "./organization_app_keys";
import OrganizationAppSettings from "./organization_app_settings";
import OrganizationAppEvents from "./events/organization_app_events";

class OrganizationApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: this.tabs.settings,
      canViewOrganizationAppKeys:
        this.props.permissions.filter(p => p.permission_name === "APP_KEY_VIEW")
          .length > 0,
      fuzion_organization_app_id: null
    };
  }

  tabs = {
    keys: 0,
    settings: 1,
    events: 2
  };

  indexes = ["keys", "settings", "events"];

  loadTabArray = () => {
    if (!this.state.canViewOrganizationAppKeys) {
      let keysIndex = this.indexes.indexOf("keys");
      if (keysIndex > -1) {
        this.indexes.splice(keysIndex, 1);
      }
    }

    return this.indexes;
  };

  loadTabObj = () => {
    if (!this.state.canViewOrganizationAppKeys) {
      delete this.tabs["keys"];
    }

    let index = 0;
    for (let tab in this.tabs) {
      this.tabs[tab] = index;
      index++;
    }

    return this.tabs;
  };

  selectTab = () => {
    const { fuzion_organization_app_id, tab } = this.props.match.params;

    if (tab) {
      this.loadTabObj();
      const index = this.tabs[tab];
      if (index !== this.state.index) {
        this.setState({
          fuzion_organization_app_id: fuzion_organization_app_id,
          index: index
        });
      }
    }
  };

  componentDidMount = this.selectTab;
  componentDidUpdate = this.selectTab;

  changeTab = index => {
    this.loadTabArray();
    let {
      fuzion_organization_id,
      fuzion_organization_app_id
    } = this.props.match.params;
    const target = `/organizations/${fuzion_organization_id}/app/${fuzion_organization_app_id}/${this.indexes[index]}`;
    this.props.history.push(target);
  };

  render() {
    const tabListClass = `nav nav-tabs admin-tab-subheader ${this.props
      .sidebar && "sidebar-spacer"}`;
    return (
      <>
        <Header
          detailView={true}
          to={`/organizations/${this.props.match.params.fuzion_organization_id}/applications`}
        />
        <Tabs selectedIndex={this.state.index} onSelect={this.changeTab}>
          <TabList className={tabListClass}>
            <div className="tab-inner-container">
              {this.state.canViewOrganizationAppKeys && (
                <Tab className="tab">APP KEYS</Tab>
              )}
              <Tab className="tab">APP SETTINGS</Tab>
              <Tab className="tab">APP EVENTS</Tab>
            </div>
          </TabList>

          {this.state.canViewOrganizationAppKeys && (
            <TabPanel>
              <OrganizationAppKeys />
            </TabPanel>
          )}
          <TabPanel>
            <OrganizationAppSettings
              key={this.props.match.params.fuzion_organization_app_id}
            />
          </TabPanel>
          <TabPanel>
            <OrganizationAppEvents />
          </TabPanel>
        </Tabs>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    permissions: state.activeUser.role_permissions,
    sidebar: state.sidebar
  };
};

export default connect(mapStateToProps)(withAuth(OrganizationApp));
