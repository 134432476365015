import { request } from "lib/data/api";
import types from "types/applications";
import global from "types/global";

const sortApplications = application => {
  let sorted = application.sort((a, b) => {
    if (a.application_name.toLowerCase() > b.application_name.toLowerCase())
      return 1;
    else if (
      a.application_name.toLowerCase() < b.application_name.toLowerCase()
    )
      return -1;
    else {
      if (a.last_mod_timestamp > b.last_mod_timestamp) return -1;
      else if (a.last_mod_timestamp < b.last_mod_timestamp) return 1;
      else return 0;
    }
  });
  return sorted;
};

export const getEventApplications = fuzion_event_id => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      let response = await request({
        method: "GET",
        state: state,
        route: `/v1/event-applications/${fuzion_event_id}`
      });
      let sortApp = sortApplications(response.payload);
      dispatch({
        type: types.GET_EVENT_APPLICATIONS,
        payload: sortApp
      });
      return sortApp;
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.applications.getEventApplications failed"
      });
      return null;
    }
  };
};

export const getApplications = fuzion_organization_id => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      let response = await request({
        method: "GET",
        state: state,
        route: `/v1/applications?org_id=${fuzion_organization_id}`
      });
      let sortApp = sortApplications(response.payload);
      dispatch({
        type: types.GET_ORGANIZATION_APPS,
        payload: sortApp
      });
      return sortApp;
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.applications.getApplications failed"
      });
      return null;
    }
  };
};

export const getAllApplications = () => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      let response = await request({
        method: "GET",
        state: state,
        route: "/v1/applications/all"
      });
      let sortApp = sortApplications(response.payload);
      dispatch({
        type: types.GET_ALL_ORGANIZATION_APPS,
        payload: sortApp
      });
      return sortApp;
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.applications.getAllApplications failed"
      });
      return null;
    }
  };
};

export const getApplication = (
  fuzion_application_id,
  fuzion_organization_id
) => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      let response = await request({
        method: "GET",
        state: state,
        route: `/v1/applications/${fuzion_application_id}?org_id=${fuzion_organization_id}`
      });
      dispatch({
        type: types.GET_ORGANIZATION_APP,
        payload: response.payload
      });
      return response.payload;
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.applications.getApplication"
      });
      return null;
    }
  };
};

export const createApplication = () => {
  return dispatch => {
    try {
      dispatch({
        type: types.CREATE_ORGANIZATION_APP,
        payload: null
      });
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.applications.createApplication failed"
      });
      return null;
    }
  };
};

export const refreshOrganizationApp = organizationApp => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "GET",
        state: state,
        route: `/organization-apps/${organizationApp.fuzion_organization_app_id}`
      });
      dispatch({
        type: types.REFRESH_ORGANIZATION_APP,
        payload: response.payload
      });
      return response.payload;
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.applications.refreshOrganizationApp failed"
      });
      return null;
    }
  };
};

export const saveApplication = application => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "POST",
        state: state,
        route: "/v1/applications",
        data: application
      });
      return dispatch({
        type: types.SAVE_ORGANIZATION_APP,
        payload: response.payload
      });
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.applications.saveApplication failed"
      });
      return null;
    }
  };
};

export const updateApplication = application => {
  return async (dispatch, getState) => {
    let state = getState();
    const response = await request({
      method: "PUT",
      state: state,
      route: `/v1/applications/${application.fuzion_application_id}`,
      data: application
    });
    dispatch({
      type: types.UPDATE_ORGANIZATION_APP,
      payload: response.payload
    });
  };
};

export const deleteApplication = application => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "DELETE",
        state: state,
        route: `/v1/applications/${application.fuzion_application_id}`,
        data: application
      });
      return dispatch({
        type: types.DELETE_ORGANIZATION_APP,
        payload: response.payload
      });
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.applications.deleteApplication"
      });
      return null;
    }
  };
};
