import React, { Component } from "react";
import { Route } from "react-router-dom";

import withAuth from "components/hoc/with_auth";
import ApiCategoryList from "./api_categories_list";
import ApiCategory from "./api_category";

class Apis extends Component {
  render() {
    return (
      <span className="fuzion-nested-router">
        <Route exact path="/apis" component={ApiCategoryList} />
        <Route path="/apis/category/:tab?" component={ApiCategory} />
      </span>
    );
  }
}

export default withAuth(Apis);
