import { request } from "lib/data/api";
import types from "types/api_categories";
import global from "types/global";

export const getApiCategories = () => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "GET",
        state: state,
        route: "/v1/api-categories"
      });
      dispatch({
        type: types.GET_API_CATEGORIES,
        payload: response.payload
      });
      return response.payload;
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.api_categories.getApiCategories failed"
      });
      return null;
    }
  };
};

export const getApiCategory = apiCategory => {
  return {
    type: types.GET_API_CATEGORY,
    payload: apiCategory
  };
};

export const saveApiCategory = apiCategory => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "POST",
        state: state,
        route: "/v1/api-categories",
        data: apiCategory
      });
      dispatch({
        type: types.SAVE_API_CATEGORY,
        payload: response.payload
      });
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.api_categories.saveApiCategory failed"
      });
      return null;
    }
  };
};

export const updateApiCategory = apiCategory => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "PUT",
        state: state,
        route: `/v1/api-categories/${apiCategory.fuzion_api_category_id}`,
        data: apiCategory
      });
      dispatch({
        type: types.UPDATE_API_CATEGORY,
        payload: response.payload
      });
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.api_catergories.updateApiCategory failed"
      });
      return null;
    }
  };
};

export const deleteApiCategory = catId => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "DELETE",
        state: state,
        route: `/v1/api-categories/${catId}`
      });
      dispatch({
        type: types.DELETE_API_CATEGORY,
        payload: response.payload
      });
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.api_catergories.deleteApiCategory"
      });
      return null;
    }
  };
};
