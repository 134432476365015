import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import withAuth from "components/hoc/with_auth";
import { setNotification } from "actions/notifications";
import ConfirmationModal from "lib/ui/confirmationModal";
import { required } from "lib/validation/form_validation_rules";
import {
  saveApplicationEvent,
  deleteApplicationEvent
} from "actions/application_events";
import { Loading } from "components/loading/loading";
import SelectObject from "lib/ui/form/select_object_non_redux";
import formHelper from "lib/ui/form/form";
import renderClassNames from "lib/ui/render_class_names";

class OrganizationAppEventEditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateConfirmationOpen: false,
      deleteConfirmationOpen: false,
      fuzion_event_id: {
        key: "fuzion_event_id",
        value: (!this.isInsert() && JSON.stringify(props.event)) || "",
        valid: true,
        errorMessage: "",
        validation: [required]
      }
    };
  }

  onSave = async () => {
    const isFormValid = formHelper.isFormValid(this.state);
    if (!isFormValid.valid) {
      this.setState(isFormValid.formState);
      return;
    }
    let formData = formHelper.getFormValues(this.state);
    let selectedEvent = JSON.parse(formData.fuzion_event_id);
    const {
      onClose,
      saveApplicationEvent,
      setNotification,
      application
    } = this.props;
    let formValues = {
      fuzion_event_id: selectedEvent.fuzion_event_id,
      event_name: selectedEvent.event_name,
      application_name: application.application_name,
      fuzion_application_id: application.fuzion_application_id,
      fuzion_organization_id: application.fuzion_organization_id,
      fuzion_application_category_id:
        application.fuzion_application_category_id,
      status_flag: 1
    };
    formHelper.removeUnwantedValues(formValues);
    await saveApplicationEvent(formValues);
    onClose();
    setNotification("good", "Application Event Created");
  };

  onDelete = async () => {
    let { onClose, deleteApplicationEvent, event, application } = this.props;
    let formValues = {
      event: event,
      fuzion_application_id: application.fuzion_application_id
    };
    await deleteApplicationEvent(formValues);
    onClose();
    setNotification("good", "Application Event Deleted");
  };

  onChange = ({ target: { name, value } }) => {
    this.setState(formHelper.mergeNewState(this.state, name, { value }));
  };

  onBlur = e => {
    const {
      target: { name, value }
    } = e;
    const validation = formHelper.validate(this.state, name, value);
    this.setState(formHelper.mergeNewState(this.state, name, validation));
  };

  renderTitle() {
    return <h4 className="modal-title">{this.props.title}</h4>;
  }

  optionsFromEvents() {
    let { events, applicationEvents } = this.props;
    let options = {};
    if (this.isInsert()) {
      let applicationEventIds = new Set(
        applicationEvents.map(
          applicationEvent => applicationEvent.fuzion_event_id
        )
      );
      let allEvents = new Set(events);
      let eventsDifference = new Set(
        [...allEvents].filter(
          event => !applicationEventIds.has(event.fuzion_event_id)
        )
      );

      // formatting data for react component
      eventsDifference.forEach(event => {
        options[event.fuzion_event_id.toUpperCase()] = event;
      });
    } else {
      events.forEach(event => {
        options[event.fuzion_event_id.toUpperCase()] = event;
      });
    }

    return options;
  }

  renderOrganizationAppKey() {
    const style = this.getStyle();
    return (
      <div>
        <div className="form-group">
          <div className="field-label">Event</div>
          <div>
            <SelectObject
              name={this.state.fuzion_event_id.key}
              value={this.state.fuzion_event_id.value}
              onChange={this.onChange}
              onBlur={this.onBlur}
              disabled={!this.isInsert()}
              options={this.optionsFromEvents()}
              className={style.appEventErrStyle}
            ></SelectObject>
            {!this.state.fuzion_event_id.valid && (
              <div className="custom-text-danger">
                {this.state.fuzion_event_id.errorMessage}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  renderSubmitButton(options) {
    let { permissions } = this.props;
    let canUpdate =
      permissions.filter(p => p.permission_name === "ORGANIZATION_APP_UPDATE")
        .length > 0;
    if (canUpdate) {
      return (
        <button
          type="button"
          onClick={this.onSave}
          name="addOrganizationAppEventButton"
          className={options.submitStyle}
        >
          {options.submitText}
        </button>
      );
    } else {
      return "";
    }
  }

  renderDeleteButton(options) {
    let { permissions } = this.props;
    let canDelete =
      permissions.filter(p => p.permission_name === "ORGANIZATION_APP_DELETE")
        .length > 0;
    if (canDelete) {
      return (
        <button
          type="button"
          onClick={() => this.setState({ deleteConfirmationOpen: true })} //{this.onDelete}
          name="deleteOrganizationAppEventButton"
          className={options.otherStyle}
        >
          REMOVE
        </button>
      );
    } else {
      return "";
    }
  }

  // Update:	REMOVE
  // Save:	ADD CANCEL
  renderButtons() {
    const style = this.getStyle();
    const isInsert = this.isInsert();
    return (
      <div className="form-group">
        <div className="formAddButtonContainer">
          {
            <button
              type="button"
              onClick={this.props.onClose}
              name="cancelOrganizationAppEventButton"
              className={style.otherStyle}
            >
              CANCEL
            </button>
          }
          {isInsert
            ? this.renderSubmitButton({
                isInsert: isInsert,
                submitStyle: style.submitStyle,
                submitText: style.submitText
              })
            : this.renderDeleteButton({
                otherStyle: style.otherStyle
              })}
        </div>
        <div className="required-label-jpom">* Required</div>
      </div>
    );
  }

  isInsert() {
    return this.props.operation === "save";
  }

  getStyle() {
    const isInsert = this.isInsert();
    const appEventErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !this.state.fuzion_event_id.valid
    });
    return {
      otherStyle: isInsert ? "btn btn-default pull-right" : "btn btn-default",
      submitStyle: isInsert ? "btn btn-primary pull-right" : "btn btn-primary",
      submitText: isInsert ? "ADD" : "SAVE",
      appEventErrStyle: appEventErrStyle
    };
  }

  render() {
    const { event } = this.props;
    return (
      <div>
        {this.props.title && this.renderTitle()}
        <div className="modal-form">
          {!this.isInsert() && Object.keys(event).length < 1 && (
            <div>
              <Loading />
            </div>
          )}
          <div>
            <form>
              {this.renderOrganizationAppKey()}
              {this.renderButtons()}
            </form>
          </div>
        </div>
        <ConfirmationModal
          open={this.state.updateConfirmationOpen}
          header="Save Organization Application to Event?"
          text="Changes have been made to the organization application event that will impact its behavior. Are you sure you want to save?"
          leftLabel="SAVE"
          onLeft={this.continueUpdate}
          rightLabel="CANCEL"
          onClose={() => this.setState({ updateConfirmationOpen: false })}
        />
        <ConfirmationModal
          open={this.state.deleteConfirmationOpen}
          header="Remove Organization Application from Event?"
          text="Removing from the event could limit the organization applications's functionality. Are you sure you want to remove?"
          leftLabel="DELETE"
          onLeft={this.onDelete}
          rightLabel="CANCEL"
          onClose={() => this.setState({ deleteConfirmationOpen: false })}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    permissions: state.activeUser.role_permissions,
    events: state.events,
    organization: state.organization,
    applicationEvents: state.applicationEvents
  };
};

export default connect(mapStateToProps, {
  setNotification,
  saveApplicationEvent,
  deleteApplicationEvent
})(withAuth(withRouter(OrganizationAppEventEditForm)));
