export default {
  CREATE_ROLE: "CREATE_ROLE",
  GET_ROLES: "GET_ROLES",
  GET_ORGANIZATION_ROLES: "GET_ORGANIZATION_ROLES",
  GET_ROLE_BY_USER_PROFILE_ID: "GET_ROLE_BY_USER_PROFILE_ID",
  GET_ROLES_COUNT: "GET_ROLES_COUNT",
  GET_ROLE: "GET_ROLE",
  SAVE_ROLE: "SAVE_ROLE",
  DELETE_ROLE: "DELETE_ROLE",
  UPDATE_ROLE: "UPDATE_ROLE",
  GET_ROLE_PERMISSIONS: "GET_ROLE_PERMISSIONS",
  SET_ROLE_PERMISSION: "SET_ROLE_PERMISSION"
};
