export default (field, error) => {
  let result = "";
  if (error.message.includes("not allowed to be empty")) {
    result = `${field} is required.`;
  } else if (error.message.includes("fails to match the required pattern")) {
    result = `Invalid ${field}.`;
  } else if (error.message.includes("must be a valid")) {
    result = `Invalid ${field}.`;
  }
  return result;
};
