import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";

import withAuth from "components/hoc/with_auth";
import Header from "components/header/header";
import OrganizationList from "./organization/list/organization_list";
import ListSearch from "lib/ui/form/list_search";
import {
  getOrganization,
  getOrganizations,
  filterOrganizations
} from "actions/organizations";
import OrganizationEditForm from "./organization/organization_edit_form";

export class OrganizationsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      orgsFiltered: false,
      filterString: ""
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    const { getOrganizations } = this.props;
    getOrganizations();
  }

  filterOrganizations = () => {
    if (this.state.filterString !== "") {
      return filterOrganizations(
        this.state.filterString,
        this.props.organizations
      );
    } else {
      return this.props.organizations;
    }
  };

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
    this.loadData();
  };

  onListSearchChange = e => {
    this.setState({
      orgsFiltered: true,
      filterString: e.value
    });
  };

  sortedOrganizations() {
    if (!this.props.organizations) return [];
    let filteredOrganizations = this.filterOrganizations();
    return filteredOrganizations.sort((a, b) => {
      return a.partner_name.toUpperCase() > b.partner_name.toUpperCase();
    });
  }

  render() {
    let { permissions } = this.props;
    if (!permissions) return null;
    let canCreate =
      permissions.filter(p => p.permission_name === "ORGANIZATION_CREATE")
        .length > 0;
    return (
      <>
        <Header />
        <div className="container-fluid">
          <div className="col-sm-9 col-sm-offset-3 col-md-10 col-md-offset-2 main">
            <h1 className="page-header">Organizations</h1>
            <div className="body-content">
              {canCreate && (
                <button
                  onClick={this.onOpenModal}
                  className="btn btn-primary btn-add-organization"
                >
                  + ORGANIZATION
                </button>
              )}
            </div>
            <ListSearch
              onChange={this.onListSearchChange}
              placeholder="Search for an organization"
              autoComplete="off"
            ></ListSearch>
            <div className="body-content-partial">
              <OrganizationList
                item={this.sortedOrganizations()}
                orgsFiltered={this.state.orgsFiltered}
              />
            </div>
          </div>
          <Modal
            open={this.state.open}
            onClose={this.onCloseModal}
            classNames={{ modal: "custom-modal" }}
            closeOnOverlayClick={false}
            showCloseIcon={false}
          >
            <OrganizationEditForm
              title="New Organization"
              onSave={this.onCloseModal}
              operation="save"
            />
          </Modal>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    permissions: state.activeUser.role_permissions,
    organizations: state.organizations,
    organization: state.organization
  };
};

export default connect(mapStateToProps, {
  getOrganization,
  getOrganizations
})(withAuth(OrganizationsList));
