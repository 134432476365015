import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Select from "../../../lib/ui/form/select_non_redux";
import { setNotification } from "../../../actions/notifications";
import { displayMapping } from "../../../actions/mappings";
import { required } from "../../../lib/validation/form_validation_rules";
import changes from "../../hoc/unsaved_changes";
import { Loading } from "../../loading/loading";
import formHelper from "../../../lib/ui/form/form";
import renderClassNames from "../../../lib/ui/render_class_names";
import {
  lodashSet,
  lodashFind,
  lodashFindIndex,
  lodashRemove
} from "../../../lib/data/lodash";
import { jsonParser, apiEntityTypes } from "fuzion-core-lib";

class MappingEditTypeForm extends Component {
  constructor(props) {
    super(props);
    let { operation, insert, isDelete, initialSelectedType } = this.props;
    this.state = {
      isInsert: insert,
      isDelete: isDelete,
      deleteConfirmationOpen: isDelete,
      type: {
        key: "type",
        value: operation === "insert" ? "" : initialSelectedType.toString(),
        valid: true,
        errorMessage: "",
        validation: [required]
      }
    };
  }

  getmappingEntityTypesToDisplay() {
    let { initialMapping, initialSelectedType } = this.props;
    let { isInsert } = this.state;
    let displayTypeOptions = {};
    let mappings =
      jsonParser(initialMapping.mappings) || initialMapping.mappings;
    if (apiEntityTypes) {
      let entityTypes = lodashFind(apiEntityTypes, {
        api: initialMapping.fuzion_api_category
      });
      entityTypes.entities.forEach(a => {
        let typeName = a.name.toLowerCase();
        let typeDisplayName = a.displayname;
        if (!isInsert && initialSelectedType.toLowerCase() === typeName) {
          displayTypeOptions[typeName] = typeDisplayName;
        } else if (
          mappings &&
          Object.keys(mappings).length > 0 &&
          mappings.types &&
          Object.keys(mappings.types).length > 0
        ) {
          let existingType = lodashFind(mappings.types, { type: typeName });
          if (!existingType) {
            displayTypeOptions[typeName] = typeDisplayName;
          }
        } else {
          displayTypeOptions[typeName] = typeDisplayName;
        }
      });
    }
    return displayTypeOptions;
  }

  onChange = e => {
    const {
      target: { name, value }
    } = e;
    this.setState(formHelper.mergeNewState(this.state, name, { value }));
  };

  onBlur = e => {
    const {
      target: { name, value }
    } = e;
    const validation = formHelper.validate(this.state, name, value);
    this.setState(formHelper.mergeNewState(this.state, name, validation));
  };

  onSave = async () => {
    let {
      operation,
      initialMapping,
      initialSelectedType,
      setNotification
    } = this.props;
    let selectedNewType = this.state.type.value;
    let mapping =
      jsonParser(initialMapping.mappings) || initialMapping.mappings;
    let updatedJson = {};

    const isFormValid = formHelper.isFormValid(this.state);
    if (!isFormValid.valid) {
      this.setState(isFormValid.formState);
      return;
    }

    let typeIndex = 0;
    switch (operation) {
      case "insert":
        typeIndex = (mapping.types && mapping.types.length) || 0;
        updatedJson = lodashSet(
          mapping,
          "types[" + typeIndex + "].type",
          selectedNewType
        );
        initialMapping.mappings = updatedJson;
        this.props.displayMapping(initialMapping);
        this.onClose();
        setNotification("good", "Mapping Type added");
        break;
      case "update":
        typeIndex = lodashFindIndex(mapping.types, {
          type: initialSelectedType
        });
        if (
          initialSelectedType.toLowerCase() !== selectedNewType.toLowerCase()
        ) {
          updatedJson = lodashSet(
            mapping,
            "types[" + typeIndex + "].type",
            selectedNewType
          );
          initialMapping.mappings = updatedJson;
          this.props.displayMapping(initialMapping);
          this.onClose();
          setNotification("good", "Mapping Type Updated");
        } else {
          this.onClose();
        }
        break;
      default:
        break;
    }
  };

  onDelete = () => {
    let { initialMapping, setNotification } = this.props;
    let selectedType = this.state.type.value;
    let mapping =
      jsonParser(initialMapping.mappings) || initialMapping.mappings;
    lodashRemove(mapping.types, { type: selectedType });
    initialMapping.mappings = mapping;
    this.props.displayMapping(initialMapping);
    this.onClose();
    setNotification("good", "Mapping Type Deleted");
  };

  onClose = () => {
    this.props.onClose();
  };

  getStyle = isInsert => {
    const typeErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !this.state.type.valid
    });
    return {
      typeErrStyle: typeErrStyle,
      otherStyle: "btn btn-default",
      deleteStyle: "btn btn-primary btn-delete",
      submitStyle: isInsert ? "btn btn-primary pull-right" : "btn btn-primary",
      submitText: isInsert ? "ADD" : "SAVE",
      btnContainer: "formAddButtonContainer",
      requiredLabel: isInsert ? "required-label-jpom" : "required-label"
    };
  };

  renderSaveButton(options) {
    let { permissions } = this.props;
    let canUpdate =
      permissions.filter(p => p.permission_name === "SHOW_UPDATE").length > 0;
    if (canUpdate) {
      return (
        <button
          type="button"
          onClick={this.onSave}
          name="saveTypeButton"
          className={options.submitStyle}
        >
          {options.submitText}
        </button>
      );
    } else {
      return "";
    }
  }

  renderDeleteButton(options) {
    let { permissions } = this.props;
    let canDelete =
      permissions.filter(p => p.permission_name === "SHOW_DELETE").length > 0;
    if (canDelete) {
      return (
        <button
          type="button"
          onClick={() => this.onDelete()}
          name="deleteTypeButton"
          className={options.deleteStyle}
        >
          DELETE
        </button>
      );
    } else {
      return "";
    }
  }

  renderCancelButton(options) {
    return (
      <button
        type="button"
        onClick={() => this.onClose(null)}
        name="cancelAddTypeButton"
        className={options.otherStyle}
      >
        CANCEL
      </button>
    );
  }

  renderButtons = (isInsert, isDelete) => {
    const style = this.getStyle(isInsert);
    return (
      <div className={style.btnContainer}>
        {this.renderCancelButton({
          otherStyle: style.otherStyle
        })}
        {!isDelete &&
          this.renderSaveButton({
            submitStyle: style.submitStyle,
            submitText: style.submitText
          })}
        {isDelete &&
          this.renderDeleteButton({
            deleteStyle: style.deleteStyle
          })}
      </div>
    );
  };

  render() {
    const { initialMapping, displayFieldsWarning } = this.props;
    const { isInsert, isDelete } = this.state;
    let style = this.getStyle(isInsert);
    let deleteTypeText = displayFieldsWarning
      ? " Any associated fields will be removed."
      : "";
    return (
      (!isDelete && (
        <div className="">
          <form className="modal-form">
            <h4 className="modal-title">
              {isInsert ? "Add Type" : "Edit Type"}
            </h4>
            {!isInsert && initialMapping.length < 1 && (
              <div>
                <Loading />
              </div>
            )}
            <div className="form-group">
              <div className="field-label">Type *</div>
              <div>
                <Select
                  name={this.state.type.key}
                  value={this.state.type.value}
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                  options={this.getmappingEntityTypesToDisplay()}
                  className={style.typeErrStyle}
                ></Select>
              </div>
              {!this.state.type.valid && (
                <div className="custom-text-danger">
                  {this.state.type.errorMessage}
                </div>
              )}
              {!isInsert && displayFieldsWarning && (
                <div className="helpertext fordragdrop">
                  Changing this type will remove the fields associated.
                </div>
              )}
            </div>
            <div className="form-group">
              <div className={style.requiredLabel}>* Required</div>
              {this.renderButtons(isInsert, false)}
            </div>
          </form>
        </div>
      )) ||
      (isDelete && (
        /* <form className="modal-form"> */
        <div className="">
          <form className="modal-form">
            <h4 className="modal-title">Delete Type</h4>
            <div>
              {"Are you sure you want to delete this type?" + deleteTypeText}
            </div>
            <div className="form-group">{this.renderButtons(false, true)}</div>
          </form>
        </div>
      ))
    );
  }
}

const mapStateToProps = state => {
  let values = {
    permissions: state.activeUser.role_permissions,
    initialMapping: state.mapping
  };
  return values;
};

export default connect(mapStateToProps, {
  displayMapping,
  setNotification
})(withRouter(changes(MappingEditTypeForm)));
