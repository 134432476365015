import types from "types/categories";

export default (state = {}, action) => {
  switch (action.type) {
    case types.CREATE_CATEGORY:
      return action.payload;
    case types.GET_CATEGORY:
      return action.payload;
    case types.UPDATE_CATEGORY:
      return action.payload;
    default:
      return state;
  }
};
