import React, { useContext, useState } from "react";

import { CognitoContext } from "components/cognito_provider";
import Joi from "joi";
import { Link } from "react-router-dom";
import { joiResolver } from "@hookform/resolvers/joi";
import { useForm } from "react-hook-form";
import resolveJoiErrors from "lib/util/joi_error_resolver";

const formStage = {
  first: "PASSWORD",
  second: "FORCE_RESET",
  third: "COOKIE_NOTICE",
  fourth: "DATA_COLLECTION"
};

export default function Login(props) {
  const { authenticate } = useContext(CognitoContext);
  const [wrongPassword, setWrongPassword] = useState(false);

  const schema = Joi.object({
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .trim()
      .required(),
    password: Joi.string().required()
  });
  const { register, handleSubmit, errors } = useForm({
    resolver: joiResolver(schema)
  });

  const onSubmit = ({ email, password }) => {
    console.log({ email });
    authenticate(email, password)
      .then(data => {
        console.log("Logged in!", data);
        if (data === "NEW_PASSWORD_REQUIRED") {
          // TODO: Could this be better or more clear *why* we're doing this?
          // do nothing -- further action will get handled by CognitoContext.
          return;
        }

        props.setFormStage(formStage.third);
      })
      .catch(err => {
        setWrongPassword(true);
        console.error("Failed to login!", err);
      });
  };

  return (
    <div style={{ maxWidth: "400px", marginTop: "30px" }}>
      <h4 className="page-header">Log In</h4>
      <form onSubmit={handleSubmit(onSubmit)} style={{ paddingTop: "30px" }}>
        <div className="form-group">
          <div className="field-label">Email Address</div>
          <input
            placeholder="Enter Email Address"
            type="text"
            name="email"
            ref={register}
            className="form-control"
          />
          {errors.email && (
            <div className="text-danger">
              {resolveJoiErrors("Email Address", errors.email)}
            </div>
          )}
        </div>
        <div className="form-group">
          <div className="field-label">Password</div>
          <input
            placeholder="Enter Password"
            type="password"
            name="password"
            ref={register}
            className="form-control"
          />
          {errors.password && (
            <div className="text-danger">
              {resolveJoiErrors("Password", errors.password)}
            </div>
          )}
        </div>
        {wrongPassword && (
          <div className="text-danger">Invalid Email Address or Password</div>
        )}
        <Link to={"/forgot-password"}>Forgot Password?</Link>
        <div className="align-right">
          <button type="submit" className="btn btn-primary">
            LOG IN
          </button>
        </div>
      </form>
    </div>
  );
}
