import React, { Component } from "react";
import { connect } from "react-redux";
import "./mapping_fields.css";
import "./mapping_details.css";
import iconAddCircleDark from "assets/images/iconAddCircleDark.svg";
import iconEditDark from "../../../assets/images/iconEditDark.svg";
import iconDeleteDark from "../../../assets/images/iconDeleteDark.svg";
import MappingEditTypeForm from "./mapping_edit_type_form";
import MappingEditFieldForm from "./mapping_edit_field_form";
import MappingEditConditionForm from "./mapping_edit_condition_form";
import Modal from "react-responsive-modal";
import { jsonParser } from "fuzion-core-lib";
import { updateMapping } from "../../../actions/mappings";
import { setNotification } from "../../../actions/notifications";
import ConfirmationModal from "lib/ui/confirmationModal";

class MappingDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteMappingConfirmationOpen: false,
      typeModalOpen: false,
      fieldModalOpen: false,
      conditionModalOpen: false,
      typeInsert: false,
      typeDelete: false,
      fieldInsert: false,
      fieldDelete: false,
      conditionInsert: false,
      conditioDelete: false,
      selectedType: "",
      selectedField: {},
      selectedFieldIndex: 0,
      selectedCondition: {},
      selectedConditionIndex: 0,
      displayFieldsWarning: false,
      displayConditionsWarning: false
    };
  }

  onOpenTypeModal = (
    isInsert,
    isDelete,
    displayFieldsWarning,
    selectedType
  ) => {
    this.setState({
      typeModalOpen: true,
      typeInsert: isInsert,
      typeDelete: isDelete,
      selectedType: selectedType,
      displayFieldsWarning: displayFieldsWarning
    });
  };

  onCloseTypeModal = () => {
    this.setState({
      typeModalOpen: false,
      typeInsert: false,
      typeDelete: false,
      selectedType: "",
      displayFieldsWarning: false
    });
  };

  onOpenFieldModal = (
    isInsert,
    isDelete,
    displayConditionsWarning,
    selectedType,
    selectedField,
    selectedFieldIndex
  ) => {
    this.setState({
      fieldModalOpen: true,
      fieldInsert: isInsert,
      fieldDelete: isDelete,
      selectedType: selectedType,
      selectedField: selectedField,
      selectedFieldIndex: selectedFieldIndex,
      displayConditionsWarning: displayConditionsWarning
    });
  };

  onCloseFieldModal = () => {
    this.setState({
      fieldModalOpen: false,
      fieldInsert: false,
      fieldDelete: false,
      selectedType: "",
      selectedField: {},
      selectedFieldIndex: 0,
      displayConditionsWarning: false
    });
  };

  onOpenConditionModal = (
    isInsert,
    isDelete,
    selectedType,
    selectedField,
    selectedCondition,
    selectedFieldIndex,
    selectedConditionIndex
  ) => {
    this.setState({
      conditionModalOpen: true,
      conditionInsert: isInsert,
      conditionDelete: isDelete,
      selectedType: selectedType,
      selectedField: selectedField,
      selectedCondition: selectedCondition,
      selectedFieldIndex: selectedFieldIndex,
      selectedConditionIndex: selectedConditionIndex
    });
  };

  onCloseConditionModal = () => {
    this.setState({
      conditionModalOpen: false,
      conditionInsert: false,
      conditionDelete: false,
      selectedType: "",
      selectedField: {},
      selectedCondition: {},
      selectedFieldIndex: 0,
      selectedConditionIndex: 0
    });
  };

  onSave = async () => {
    this.props.onSave();
  };

  onDelete = async () => {
    let { mapping, updateMapping, setNotification } = this.props;
    mapping.mappings = "{}";
    await updateMapping(mapping);
    setNotification("good", "Mapping Deleted");
  };

  renderSaveButton(options) {
    let { permissions } = this.props;
    let canUpdate =
      permissions.filter(p => p.permission_name === "SHOW_UPDATE").length > 0;
    if (canUpdate) {
      return (
        <button
          type="button"
          onClick={this.onSave}
          name="saveMappingFieldButton"
          className={options.submitStyle}
        >
          SAVE
        </button>
      );
    } else {
      return "";
    }
  }

  renderDeleteButton(options) {
    let { permissions } = this.props;
    let canDelete =
      permissions.filter(p => p.permission_name === "SHOW_DELETE").length > 0;
    if (canDelete) {
      return (
        <button
          type="button"
          onClick={() => this.setState({ deleteMappingConfirmationOpen: true })}
          name="deleteMappingButton"
          className={options.otherStyle}
        >
          DELETE MAPPING
        </button>
      );
    } else {
      return "";
    }
  }

  renderButtons() {
    return (
      <div>
        <div className="formMappingEditButtonContainer">
          {this.renderSaveButton({
            submitStyle: "btn btn-primary"
          })}
        </div>
      </div>
    );
  }

  renderFields(typeValue, fields, isPublished) {
    let lastFieldName = "";
    return (
      Object.keys(fields).length > 0 &&
      fields.map((field, j) => {
        let { conditions } = field;
        let displayOR = lastFieldName === field.field && j > 0;
        lastFieldName = field.field;
        let formattedDictionary =
          field.dictionary &&
          field.dictionary.length > 0 &&
          field.dictionary.join(",");
        return (
          <div key={"header " + j}>
            {displayOR && (
              <div className="conjunctiondiv">
                <div className="text-block-2">OR</div>
              </div>
            )}
            <div className="conditiondiv2 fieldSection" key={j}>
              <div className="mappinginteriordiv">
                <div className="conditiontitle">
                  <strong>Field: &quot;{field.field}&quot;</strong>
                </div>
                {!isPublished && (
                  <div className="mappingiconsdiv fieldIcons">
                    <button
                      onClick={() =>
                        this.onOpenFieldModal(
                          false,
                          false,
                          conditions && Object.keys(conditions).length > 0,
                          typeValue,
                          field,
                          j
                        )
                      }
                      className="mappingiconbtn w-inline-block"
                    >
                      <img src={iconEditDark} alt="" className="plusicon" />
                    </button>
                    <button
                      onClick={() =>
                        this.onOpenFieldModal(
                          false,
                          true,
                          conditions && Object.keys(conditions).length > 0,
                          typeValue,
                          field,
                          j
                        )
                      }
                      className="mappingiconbtn fieldmappingIcon w-inline-block"
                    >
                      <img src={iconDeleteDark} alt="" className="plusicon" />
                    </button>
                  </div>
                )}
              </div>
              <div className="conditiontitle">
                Maps to: {field.maps_to ? `"${field.maps_to}"` : "null"}
              </div>
              <div className="conditiontitle">
                Type: {field.type ? `"${field.type}"` : "null"}
              </div>
              <div className="conditiontitle">
                Possible Values:{" "}
                {formattedDictionary ? `[${formattedDictionary}]` : "null"}
              </div>
              <div className="conditiontitle bottomtitle">
                Value: {field.value ? `"${field.value}"` : "null"}
              </div>
              {conditions &&
                Object.keys(conditions).length > 0 &&
                conditions.map((condition, k) => {
                  let { 1: evalOperator } = Object.keys(condition);
                  let { 1: evalValue } = Object.values(condition);
                  return (
                    <div key={k} className="conditiondiv2 conditionSection">
                      {k > 0 ? <div className="conditiontitle">AND</div> : ""}
                      <div className="mappinginteriordiv">
                        <div className="conditiontitle">
                          &quot;{condition.field}&quot; {evalOperator} &quot;
                          {evalValue}&quot;
                        </div>
                        {!isPublished && (
                          <div className="mappingiconsdiv conditionIcons">
                            <button
                              onClick={() =>
                                this.onOpenConditionModal(
                                  false,
                                  false,
                                  typeValue,
                                  field,
                                  condition,
                                  j,
                                  k
                                )
                              }
                              className="mappingiconbtn w-inline-block"
                            >
                              <img
                                src={iconEditDark}
                                alt=""
                                className="plusicon"
                              />
                            </button>
                            <button
                              onClick={() =>
                                this.onOpenConditionModal(
                                  false,
                                  true,
                                  typeValue,
                                  field,
                                  condition,
                                  j,
                                  k
                                )
                              }
                              className="mappingiconbtn fieldmappingIcon w-inline-block"
                            >
                              <img
                                src={iconDeleteDark}
                                alt=""
                                className="plusicon"
                              />
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              {!isPublished && this.renderAddCondition(typeValue, field, j)}
            </div>
          </div>
        );
      })
    );
  }

  renderAddField(type) {
    return (
      <div>
        <button
          onClick={() => this.onOpenFieldModal(true, false, false, type, {}, 0)}
          className="addconditionlinkdiv w-inline-block w-clearfix plusbutton"
        >
          <img src={iconAddCircleDark} alt="" className="plusicon" />
          <div className="plusconditiontext">Field</div>
        </button>
      </div>
    );
  }

  renderAddCondition(type, field, fieldIndex) {
    return (
      <div>
        <button
          onClick={() =>
            this.onOpenConditionModal(
              true,
              false,
              type,
              field,
              {},
              fieldIndex,
              0
            )
          }
          className="addconditionlinkdiv w-inline-block w-clearfix plusbutton"
        >
          <img src={iconAddCircleDark} alt="" className="plusicon" />
          <div className="plusconditiontext">Condition</div>
        </button>
      </div>
    );
  }

  renderAddType() {
    return (
      <div>
        <button
          onClick={() => this.onOpenTypeModal(true, false, false, "")}
          className="addconditionlinkdiv w-inline-block w-clearfix plusbutton"
        >
          <img src={iconAddCircleDark} alt="" className="plusicon" />
          <div className="plusconditiontext">Type</div>
        </button>
      </div>
    );
  }

  renderModals() {
    let {
      typeInsert,
      typeDelete,
      typeModalOpen,
      fieldInsert,
      fieldDelete,
      fieldModalOpen,
      conditionInsert,
      conditionDelete,
      conditionModalOpen,
      selectedType,
      selectedField,
      selectedFieldIndex,
      selectedCondition,
      selectedConditionIndex,
      displayFieldsWarning,
      displayConditionsWarning
    } = this.state;
    return (
      <div>
        <Modal
          open={typeModalOpen}
          onClose={this.onCloseTypeModal}
          classNames={{ modal: "custom-modal" }}
          closeOnOverlayClick={false}
          showCloseIcon={false}
        >
          <MappingEditTypeForm
            onClose={this.onCloseTypeModal}
            title="Add type"
            onSave={this.onCloseTypeModal}
            operation={typeInsert ? "insert" : "update"}
            insert={typeInsert}
            initialSelectedType={selectedType}
            initialMapping={this.props.mapping}
            displayFieldsWarning={displayFieldsWarning}
            isDelete={typeDelete}
          />
        </Modal>
        <Modal
          open={fieldModalOpen}
          onClose={this.onCloseFieldModal}
          classNames={{ modal: "custom-modal" }}
          closeOnOverlayClick={false}
          showCloseIcon={false}
        >
          <MappingEditFieldForm
            title="Add field"
            onClose={this.onCloseFieldModal}
            onSave={this.onCloseFieldModal}
            operation={fieldInsert ? "insert" : "update"}
            insert={fieldInsert}
            isDelete={fieldDelete}
            initialSelectedField={selectedField}
            selectedFieldIndex={selectedFieldIndex}
            selectedType={selectedType}
            initialMapping={this.props.mapping}
            displayConditionsWarning={displayConditionsWarning}
          />
        </Modal>
        <Modal
          open={conditionModalOpen}
          onClose={this.onCloseConditionModal}
          classNames={{ modal: "custom-modal" }}
          closeOnOverlayClick={false}
          showCloseIcon={false}
        >
          <MappingEditConditionForm
            title="Add condition"
            onClose={this.onCloseConditionModal}
            onSave={this.onCloseConditionModal}
            operation={conditionInsert ? "insert" : "update"}
            insert={conditionInsert}
            isDelete={conditionDelete}
            selectedType={selectedType}
            initialSelectedField={selectedField}
            selectedFieldIndex={selectedFieldIndex}
            initialSelectedCondition={selectedCondition}
            selectedConditionIndex={selectedConditionIndex}
            initialMapping={this.props.mapping}
          />
        </Modal>
        <ConfirmationModal
          open={this.state.deleteMappingConfirmationOpen}
          header="Delete Mapping?"
          text="Are you sure you want to delete this mapping?"
          leftLabel="DELETE"
          leftStyle="btn-delete"
          onLeft={this.onDelete}
          rightLabel="CANCEL"
          onClose={() =>
            this.setState({ deleteMappingConfirmationOpen: false })
          }
        />
      </div>
    );
  }

  render() {
    let { mapping } = this.props;
    let mappings = mapping.mappings;
    const isPublished = mapping.status.toString() === "1";
    let jsonMappings =
      mappings !== null &&
      mappings !== undefined &&
      (jsonParser(mappings) || mappings);
    let displayFieldAdditionText =
      Object.keys(jsonMappings).length > 0 &&
      jsonMappings.types &&
      jsonMappings.types.length > 0;
    return (
      <div className="listdiv">
        <div className="listtitle listtitlereportspage">
          {this.props.mapping.name}
        </div>
        <div className="batchcarddiv">
          {displayFieldAdditionText && (
            <div className="helpertext forfieldaddition">
              You can add an existing field again if the existing field has at
              least one condition.
            </div>
          )}
          {jsonMappings &&
            Object.keys(jsonMappings).length > 0 &&
            jsonMappings.types.map((type, i) => {
              let { fields } = type;
              return (
                <div className="conditiondiv2 typeSection" key={i}>
                  <div className="mappinginteriordiv">
                    <div className="conditiontitle bottomtitle">
                      <strong>Type: {type.type}</strong>
                    </div>
                    {!isPublished && (
                      <div className="mappingiconsdiv typeIcons">
                        <button
                          onClick={() =>
                            this.onOpenTypeModal(
                              false,
                              false,
                              fields && Object.keys(fields).length > 0,
                              type.type
                            )
                          }
                          className="mappingiconbtn w-inline-block"
                        >
                          <img src={iconEditDark} alt="" className="plusicon" />
                        </button>
                        <button
                          onClick={() =>
                            this.onOpenTypeModal(
                              false,
                              true,
                              fields && Object.keys(fields).length > 0,
                              type.type
                            )
                          }
                          className="mappingiconbtn w-inline-block"
                        >
                          <img
                            src={iconDeleteDark}
                            alt=""
                            className="plusicon"
                          />
                        </button>
                      </div>
                    )}
                  </div>
                  {fields && this.renderFields(type.type, fields, isPublished)}
                  {!isPublished && this.renderAddField(type.type)}
                </div>
              );
            })}
          {!isPublished && this.renderAddType()}
        </div>
        <div className="form-group">{!isPublished && this.renderButtons()}</div>
        {this.renderModals()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    sidebar: state.sidebar,
    mapping: state.mapping,
    permissions: state.activeUser.role_permissions
  };
};

export default connect(mapStateToProps, {
  updateMapping,
  setNotification
})(MappingDetails);
