import { request } from "lib/data/api";
import types from "types/organizations";
import global from "types/global";

const sortOrganization = organization => {
  let sorted = organization.sort((a, b) => {
    if (a.partner_name.toLowerCase() > b.partner_name.toLowerCase()) return 1;
    else if (a.partner_name.toLowerCase() < b.partner_name.toLowerCase())
      return -1;
    else {
      if (a.last_mod_timestamp > b.last_mod_timestamp) return -1;
      else if (a.last_mod_timestamp < b.last_mod_timestamp) return 1;
      else return 0;
    }
  });
  return sorted;
};

export const getOrganizations = () => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "GET",
        state: state,
        route: "/v1/organizations/all"
      });
      let sortedOrg = sortOrganization(response.payload);
      dispatch({
        type: types.GET_ORGANIZATIONS,
        payload: sortedOrg
      });
      return sortedOrg;
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.events.getOrganizations failed"
      });
    }
  };
};

export const getOrganization = fuzion_organization_id => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "GET",
        state: state,
        route: `/v1/organizations/${fuzion_organization_id}`
      });
      dispatch({
        type: types.GET_ORGANIZATION,
        payload: response.payload
      });
      return response.payload;
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.organizations.getOrganization failed"
      });
    }
  };
};

export const filterOrganizations = (filter_string, organizations) => {
  let filter_string_lower = filter_string.toLowerCase();

  if (!filter_string_lower) return [];

  return organizations.filter(
    organization =>
      organization.partner_name &&
      organization.partner_name.toLowerCase().includes(filter_string_lower)
  );
};

export const createOrganization = () => {
  return dispatch => {
    dispatch({
      type: types.CREATE_ORGANIZATION,
      payload: null
    });
  };
};

export const refreshOrganization = fuzion_organization_id => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "GET",
        state: state,
        route: `/v1/organizations/${fuzion_organization_id}`
      });
      return dispatch({
        type: types.REFRESH_ORGANIZATION,
        payload: response.payload
      });
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.organizations.refreshOrganization failed"
      });
    }
  };
};

export const saveOrganization = organization => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "POST",
        state: state,
        route: "/v1/organizations",
        data: organization
      });
      return dispatch({
        type: types.SAVE_ORGANIZATION,
        payload: response.payload
      });
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.organizations.saveOrganization failed"
      });
    }
  };
};

export const deleteOrganization = organization => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "DELETE",
        state: state,
        route: `/v1/organizations/${organization.fuzion_organization_id}`,
        data: organization
      });
      return dispatch({
        type: types.DELETE_ORGANIZATION,
        payload: response.payload
      });
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.organizations.deleteOrganization failed"
      });
    }
  };
};

export const updateOrganization = organization => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "PUT",
        state: state,
        route: `/v1/organizations/${organization.fuzion_organization_id}`,
        data: organization
      });
      dispatch({
        type: types.UPDATE_ORGANIZATION,
        payload: response.payload
      });
      return response.payload;
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.organizations.updateOrganization failed"
      });
    }
  };
};

// //GET:/organization-apps/:fuzion_organization_app_id/event-ids
// export const getOrganizationAppEventIds = fuzion_organization_app_id => {
//     return async (dispatch, getState) => {
//         try{
//             let state = getState();
//         let response = (fuzion_organization_app_id === null) ? null : await request({
//             method: "GET",
//             state: state,
//             route: `/organization-apps/${fuzion_organization_app_id}/event-ids`
//         });
//         dispatch({
//             type: types.GET_ORGANIZATION_APP_EVENT_IDS,
//             payload: response.payload
//         });
//         return response.payload;
//     } catch(e) {
//         dispatch({
//             type: global.ERROR,
//             error: "ERROR: actions.organizations.getOrganizationAppEventIds failed"
//         });
//     }
//     };
// };

export const getOrganizationAppEventId = organizationAppEventId => {
  return {
    type: types.GET_ORGANIZATION_APP_EVENT_ID,
    payload: organizationAppEventId
  };
};

//POST:/organization-apps/:fuzion_organization_app_id/event-ids
export const saveOrganizationAppEventId = organizationAppEventId => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "POST",
        state: state,
        route: `/organization-apps/${organizationAppEventId.fuzion_organization_app_id}/event-ids`,
        data: organizationAppEventId
      });
      return dispatch({
        type: types.SAVE_ORGANIZATION_APP_EVENT_ID,
        payload: response.payload
      });
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error: "ERROR: actions.organizations.saveOrganizationAppEventId failed"
      });
    }
  };
};

//PUT:/organization-apps/:fuzion_organization_app_id/event-ids/:fuzion_organization_app_event_rel_id
export const updateOrganizationAppEventId = organizationAppEventId => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "PUT",
        state: state,
        route: `/organization-apps/${organizationAppEventId.fuzion_organization_app_id}/event-ids/${organizationAppEventId.fuzion_organization_app_event_rel_id}`,
        data: organizationAppEventId
      });
      return dispatch({
        type: types.UPDATE_ORGANIZATION_APP_EVENT_ID,
        payload: response.payload
      });
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error:
          "ERROR: actions.organizations.updateOrganizationAppEventId failed"
      });
    }
  };
};

//DELETE:/organization-apps/:fuzion_organization_app_id/event-ids/:fuzion_organization_app_
export const deleteOrganizationAppEventId = organizationAppEventId => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const response = await request({
        method: "DELETE",
        state: state,
        route: `/organization-apps/${organizationAppEventId.fuzion_partner_app_id}/event-ids/${organizationAppEventId.fuzion_partner_app_event_rel_id}`
      });
      return dispatch({
        type: types.DELETE_ORGANIZATION_APP_EVENT_ID,
        payload: response.payload
      });
    } catch (e) {
      dispatch({
        type: global.ERROR,
        error:
          "ERROR: actions.organizations.deleteOrganizationAppEventId failed"
      });
    }
  };
};
