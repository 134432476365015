import React, { Component } from "react";
import Spinner from "./Spinner.svg";

export class Loading extends Component {
  render() {
    return (
      <div>
        <Spinner />
        <div> Loading ... </div>
      </div>
    );
  }
}
