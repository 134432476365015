import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Select from "../../../lib/ui/form/select_non_redux";
import ConfirmationModal from "../../../lib/ui/confirmationModal";
import { setNotification } from "../../../actions/notifications";
import {
  required,
  maxLength256
} from "../../../lib/validation/form_validation_rules";
import {
  getMapping,
  createMapping,
  updateMapping,
  deleteMapping,
  validateMappings
} from "../../../actions/mappings";
import { getOrganizations } from "../../../actions/organizations";
import { getEvents } from "../../../actions/events";
import { getEventApplications } from "../../../actions/applications";
import { getApplicationKeys } from "../../../actions/application_keys";
import changes from "../../hoc/unsaved_changes";
import { Loading } from "../../loading/loading";
import formHelper from "../../../lib/ui/form/form";
import renderClassNames from "../../../lib/ui/render_class_names";
import MappingDetails from "./mapping_details";
const { jsonParser } = require("fuzion-core-lib");

class MappingEditForm extends Component {
  constructor(props) {
    super(props);
    let { initialValues, insert } = this.props;
    this.state = {
      appHasData: false,
      deleteConfirmationOpen: false,
      uniqueValidationErrorOpen: false,
      updateFormValues: {},
      isInsert: insert,
      isPublished:
        initialValues &&
        initialValues.status &&
        initialValues.status.toString() === "1",
      validationError: "",
      fuzion_mappings_id: {
        key: "fuzion_mappings_id",
        value: (initialValues && initialValues.fuzion_mappings_id) || "",
        valid: true,
        errorMessage: ""
      },
      status: {
        key: "status",
        value:
          initialValues && initialValues.status
            ? initialValues.status.toString()
            : "0",
        valid: true,
        errorMessage: "",
        validation: [required]
      },
      name: {
        key: "name",
        value: (initialValues && initialValues.name) || "",
        valid: true,
        errorMessage: "",
        validation: [required, maxLength256]
      },
      fuzion_event_id: {
        key: "fuzion_event_id",
        value: (initialValues && initialValues.fuzion_event_id) || "",
        valid: true,
        errorMessage: "",
        validation: [required]
      },
      fuzion_application_id: {
        key: "fuzion_application_id",
        value: (initialValues && initialValues.fuzion_application_id) || "",
        valid: true,
        errorMessage: "",
        validation: [required]
      },
      fuzion_api_category: {
        key: "fuzion_api_category",
        value: (initialValues && initialValues.fuzion_api_category) || "",
        valid: true,
        errorMessage: "",
        validation: [required]
      }
    };
  }

  componentDidMount() {
    let {
      getEvents,
      getOrganizations,
      getEventApplications,
      initialValues
    } = this.props;
    getOrganizations();
    getEvents();
    !this.state.isInsert && getEventApplications(initialValues.fuzion_event_id);
  }

  getEventOptions = () => {
    let options = {};
    this.props.events.forEach(event => {
      let fuzionEventId = event.fuzion_event_id.toUpperCase();
      options[fuzionEventId] = event.event_name;
    });

    return options;
  };

  getEventApplicationOptions = () => {
    let options = {};
    this.props.eventApplications.forEach(eventApp => {
      let fuzion_application_id = eventApp.fuzion_application_id.toUpperCase();
      options[fuzion_application_id] = eventApp.application_name;
    });

    return options;
  };

  getAPICategoryOptions = () => {
    let options = {};
    options["abstract"] = "Abstract Management";
    options["event"] = "Event";
    options["exhibitor"] = "Exhibitor";
    options["registration"] = "Registration";
    options["floorplan"] = "Floorplan";

    return options;
  };

  onChange = e => {
    const {
      target: { name, value }
    } = e;
    this.setState(formHelper.mergeNewState(this.state, name, { value }));
  };

  onEventChange = async e => {
    this.onChange(e);
    const {
      target: { value }
    } = e;
    await this.props.getEventApplications(value);
    this.setState(
      formHelper.mergeNewState(this.state, "fuzion_application_id", {
        value: ""
      })
    );
  };

  onBlur = e => {
    const {
      target: { name, value }
    } = e;
    const validation = formHelper.validate(this.state, name, value);
    this.setState(formHelper.mergeNewState(this.state, name, validation));
  };

  onDelete = async () => {
    let { history, deleteMapping, applicationKeys } = this.props;
    let formValues = formHelper.getFormValues(this.state);
    await getApplicationKeys(formValues.fuzion_application_id);
    let appKeysArr = [];
    applicationKeys.forEach(appEvent =>
      appKeysArr.push(appEvent.fuzion_application_key_id)
    );
    formValues.fuzion_application_key_id =
      appKeysArr && appKeysArr.length > 0 ? appKeysArr : null;
    formValues.status = parseInt(formValues.status);
    formValues.mappings = JSON.stringify({});
    await deleteMapping(formValues);
    history.push("/mappings");
    setNotification("good", "Mapping Deleted");
  };

  onSave = async () => {
    let {
      operation,
      organizations,
      eventApplications,
      initialValues,
      events,
      getApplicationKeys,
      mappings
    } = this.props;
    const isFormValid = formHelper.isFormValid(this.state);
    if (!isFormValid.valid) {
      this.setState(isFormValid.formState);
      return;
    }
    let formValues = formHelper.getFormValues(this.state);
    let currentEvent = events.filter(
      event => formValues.fuzion_event_id === event.fuzion_event_id
    );
    let currentEventApp = eventApplications.filter(
      eventApp =>
        formValues.fuzion_application_id === eventApp.fuzion_application_id
    );
    let currentOrg = organizations.filter(
      org =>
        org.fuzion_organization_id === currentEventApp[0].fuzion_organization_id
    );
    const mappingAlreadyExists = mappings.some(mapping => {
      return (
        mapping.fuzion_event_id.toLowerCase() ===
          formValues.fuzion_event_id.toLowerCase().trim() &&
        mapping.fuzion_application_id.toLowerCase() ===
          formValues.fuzion_application_id.toLowerCase() &&
        mapping.fuzion_api_category.toLowerCase() ===
          formValues.fuzion_api_category.toLowerCase() &&
        mapping.fuzion_mappings_id.toLowerCase() !==
          formValues.fuzion_mappings_id.toLowerCase()
      );
    });
    if (mappingAlreadyExists) {
      this.setState({ uniqueValidationErrorOpen: true });
      return;
    }
    await getApplicationKeys(formValues.fuzion_application_id);
    let appKeysArr = [];
    let {
      setNotification,
      updateMapping,
      createMapping,
      validateMappings
    } = this.props;
    formValues.status = Number(formValues.status);
    formValues.partner_name = currentOrg[0].partner_name;
    formValues.application_name = currentEventApp[0].application_name;
    formValues.event_name = currentEvent[0].event_name;
    formValues.fuzion_organization_id =
      currentEventApp[0].fuzion_organization_id;
    this.props.applicationKeys.forEach(appEvent =>
      appKeysArr.push(appEvent.fuzion_application_key_id)
    );
    formValues.fuzion_application_key_id =
      appKeysArr && appKeysArr.length > 0 ? appKeysArr : null;
    let result = null;
    let validateFormValues = { ...formValues };
    switch (operation) {
      case "insert":
        delete formValues.fuzion_mappings_id;
        formValues.mappings = JSON.stringify({});
        result = await createMapping(formValues);
        this.setState({ isPublished: formValues.status.toString() === "1" });
        this.onClose();
        setNotification("good", "Mapping Added");
        this.props.history.push("/mappings/" + result.fuzion_mappings_id);
        break;
      case "update":
        formValues.mappings = initialValues.mappings;
        formValues.fuzion_mappings_id = initialValues.fuzion_mappings_id;
        validateFormValues = { ...formValues };
        if (validateFormValues.status.toString() === "1") {
          const publishMappingAlreadyExists = mappings.some(mapping => {
            return (
              mapping.fuzion_application_id.toLowerCase() ===
                formValues.fuzion_application_id.toLowerCase() &&
              mapping.fuzion_api_category.toLowerCase() ===
                formValues.fuzion_api_category.toLowerCase() &&
              mapping.fuzion_mappings_id.toLowerCase() !==
                formValues.fuzion_mappings_id.toLowerCase() &&
              mapping.status.toString() === "1"
            );
          });
          if (publishMappingAlreadyExists) {
            this.setState({
              validationError:
                "Only one mapping per application can be published. Mapping with the same application and api category has already been published."
            });
            break;
          }
          let validateFormValueMappings = validateFormValues.mappings;
          validateFormValueMappings =
            jsonParser(validateFormValueMappings) || validateFormValueMappings;
          validateFormValues.mappings = validateFormValueMappings;
          if (
            validateFormValueMappings &&
            validateFormValueMappings.types &&
            validateFormValueMappings.types.length === 0
          ) {
            this.setState({
              validationError:
                "Publish mapping failed. Mapping should contain at least one type with at least one field. Please correct errors and try again."
            });
            break;
          }
          let validateResult = await validateMappings(validateFormValues);
          if (validateResult.error) {
            this.setState({
              validationError:
                "Publish mapping failed. Please correct errors and try again. " +
                validateResult.errorMessage
            });
            break;
          }
        }
        this.setState({ validationError: "" });
        await updateMapping(formValues);
        this.setState({ isPublished: formValues.status.toString() === "1" });
        setNotification("good", "Mapping Updated");
        break;
      default:
        break;
    }
  };

  onClose = () => {
    this.props.onClose();
  };

  getStyle = isInsert => {
    const statusErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !this.state.status.valid
    });
    const nameErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !this.state.name.valid
    });
    const fuzionEventIdErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !this.state.fuzion_event_id.valid
    });
    const fuzionApplicationIdErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !this.state.fuzion_application_id.valid
    });
    const fuzionAPICategoryErrStyle = renderClassNames({
      "form-control": true,
      "custom-danger-border": !this.state.fuzion_api_category.valid
    });
    return {
      otherStyle: isInsert ? "btn btn-default pull-right" : "btn btn-default",
      submitStyle: isInsert ? "btn btn-primary pull-right" : "btn btn-primary",
      submitText: isInsert ? "ADD" : "SAVE",
      btnContainer: isInsert
        ? "formAddButtonContainer"
        : "formMappingEditButtonContainer",
      requiredLabel: isInsert ? "required-label-jpom" : "required-label",
      statusErrStyle: statusErrStyle,
      nameErrStyle: nameErrStyle,
      fuzionEventIdErrStyle: fuzionEventIdErrStyle,
      fuzionApplicationIdErrStyle: fuzionApplicationIdErrStyle,
      fuzionAPICategoryErrStyle: fuzionAPICategoryErrStyle
    };
  };

  renderSaveButton(options) {
    let { permissions } = this.props;
    let canUpdate =
      permissions.filter(p => p.permission_name === "SHOW_UPDATE").length > 0;
    if (canUpdate) {
      return (
        <button
          type="button"
          onClick={this.onSave}
          name="saveMappingButton"
          className={options.submitStyle}
        >
          {options.submitText}
        </button>
      );
    } else {
      return "";
    }
  }

  renderDeleteButton(options) {
    const { appHasData } = this.state;
    const deleteConfirmState = appHasData
      ? { cantDeleteConfirmationOpen: true }
      : { deleteConfirmationOpen: true };
    let { permissions } = this.props;
    let canDelete =
      permissions.filter(p => p.permission_name === "SHOW_DELETE").length > 0;
    if (canDelete) {
      return (
        <button
          type="button"
          onClick={() => this.setState(deleteConfirmState)}
          name="deleteMappingButton"
          className={options.otherStyle}
        >
          DELETE MAPPING
        </button>
      );
    } else {
      return "";
    }
  }

  renderCancelButton() {
    return (
      <button
        type="button"
        onClick={() => this.onClose(null)}
        name="cancelAddMappingButton"
        className="btn btn-default"
      >
        CANCEL
      </button>
    );
  }

  renderButtons = (isInsert, isPublished) => {
    const style = this.getStyle(isInsert);
    return (
      <div className={style.btnContainer}>
        {isInsert && this.renderCancelButton()}
        {this.renderSaveButton({
          submitStyle: style.submitStyle,
          submitText: style.submitText
        })}
        {!isInsert &&
          !isPublished &&
          this.renderDeleteButton({
            otherStyle: style.otherStyle
          })}
      </div>
    );
  };

  render() {
    const { initialValues } = this.props;
    const { isPublished, isInsert } = this.state;
    const deleteConfirmModalHeaderText = isInsert
      ? "Delete Mapping?"
      : "Delete " + this.state.name.value + "?";
    let style = this.getStyle(isInsert);
    return (
      <div>
        <div className={!isInsert ? "formdiv multipleforms" : ""}>
          {!isInsert && (
            <h1 className="h1 headerdiv withnosubtext">
              {this.state.name.value}
            </h1>
          )}
          {isInsert && (
            <h4 className="modal-title">Add Mapping - General Settings</h4>
          )}
          <form className="modal-form">
            {!isInsert && (
              <div className="listtitle listtitlereportspage">
                General Settings
              </div>
            )}
            <br />
            {this.state.validationError && !isPublished && (
              <div className="helpertext forfieldaddition">
                {this.state.validationError}
              </div>
            )}
            {!isInsert && initialValues.length < 1 && (
              <div>
                <Loading />
              </div>
            )}
            <div className="form-group">
              <div className="field-label">Status *</div>
              <div>
                <Select
                  name={this.state.status.key}
                  value={this.state.status.value}
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                  disabled={isInsert}
                  options={{
                    "1": "Published",
                    "0": "Draft"
                  }}
                  className={style.statusErrStyle}
                ></Select>
              </div>
              {!this.state.status.valid && (
                <div className="custom-text-danger">
                  {this.state.status.errorMessage}
                </div>
              )}
              {!isInsert && (
                <div className="helpertext fordragdrop">
                  Change Status to &quot;Published&quot; to update your changes
                  to the API in Fuzion.
                </div>
              )}
            </div>
            <div className="form-group">
              <div className="field-label">Name *</div>
              <div>
                <input
                  name={this.state.name.key}
                  type="text"
                  value={this.state.name.value}
                  placeholder="Mapping Name"
                  className={style.nameErrStyle}
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                  disabled={isPublished}
                />
                {!this.state.name.valid && (
                  <div className="custom-text-danger">
                    {this.state.name.errorMessage}
                  </div>
                )}
              </div>
            </div>
            <div className="form-group">
              <div className="field-label">Event *</div>
              <Select
                name={this.state.fuzion_event_id.key}
                value={this.state.fuzion_event_id.value}
                onChange={this.onEventChange}
                onBlur={this.onBlur}
                placeholder="Select an event..."
                options={this.getEventOptions()}
                className={style.fuzionEventIdErrStyle}
                disabled={isPublished}
              ></Select>
              {!this.state.fuzion_event_id.valid && (
                <div className="custom-text-danger">
                  {this.state.fuzion_event_id.errorMessage}
                </div>
              )}
            </div>
            <div className="form-group">
              <div className="field-label">Application *</div>
              <Select
                name={this.state.fuzion_application_id.key}
                value={this.state.fuzion_application_id.value}
                onChange={this.onChange}
                onBlur={this.onBlur}
                placeholder="Select an application..."
                options={this.getEventApplicationOptions()}
                className={style.fuzionApplicationIdErrStyle}
                disabled={isPublished}
              ></Select>
              {!this.state.fuzion_application_id.valid && (
                <div className="custom-text-danger">
                  {this.state.fuzion_application_id.errorMessage}
                </div>
              )}
            </div>
            <div className="form-group">
              <div className="field-label">API Category *</div>
              <Select
                name={this.state.fuzion_api_category.key}
                value={this.state.fuzion_api_category.value}
                onChange={this.onChange}
                onBlur={this.onBlur}
                placeholder="Select an api..."
                options={this.getAPICategoryOptions()}
                className={style.fuzion_api_category}
                disabled={isPublished}
              ></Select>
              {!this.state.fuzion_api_category.valid && (
                <div className="custom-text-danger">
                  {this.state.fuzion_api_category.errorMessage}
                </div>
              )}
            </div>
            <div className="form-group">
              {this.renderButtons(isInsert, isPublished)}
              <div className={style.requiredLabel}>* Required</div>
            </div>
            <ConfirmationModal
              open={this.state.uniqueValidationErrorOpen}
              header="Mapping Validation Error!"
              text="Mapping already exists for the same event, application and api category."
              rightLabel="GOT IT"
              onClose={() =>
                this.setState({ uniqueValidationErrorOpen: false })
              }
            />
            <ConfirmationModal
              open={this.state.deleteConfirmationOpen}
              header={deleteConfirmModalHeaderText}
              text="Are you sure you want to delete this Mapping?"
              leftLabel="DELETE"
              leftStyle="btn-delete"
              onLeft={this.onDelete}
              rightLabel="CANCEL"
              onClose={() => this.setState({ deleteConfirmationOpen: false })}
            />
          </form>
        </div>
        {!isInsert && <MappingDetails onSave={() => this.onSave()} />}
      </div>
    );
  }
}

const mapStateToProps = state => {
  let values = {
    permissions: state.activeUser.role_permissions,
    organizations: state.organizations,
    events: state.events,
    eventApplications: state.eventApplications,
    mapping: state.mapping,
    mappings: state.mappings,
    applicationKeys: state.applicationKeys
  };
  return values;
};

export default connect(mapStateToProps, {
  getMapping,
  createMapping,
  updateMapping,
  validateMappings,
  deleteMapping,
  setNotification,
  getOrganizations,
  getEvents,
  getEventApplications,
  getApplicationKeys
})(withRouter(changes(MappingEditForm)));
