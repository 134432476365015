import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import withAuth from "components/hoc/with_auth";
import OrganizationsList from "./organizations_list";
import Organization from "./organization/organization";
import OrganizationApp from "./organization/apps/organization_app";
import OgranizationUser from "./organization/users/organization_user_detail";

class Organizations extends Component {
  render() {
    return (
      <span className="fuzion-nested-router">
        <Switch>
          <Route exact path="/organizations" component={OrganizationsList} />
          <Route
            path="/organizations/:fuzion_organization_id/app/:fuzion_organization_app_id/:tab?"
            component={OrganizationApp}
          />
          <Route
            path="/organizations/:fuzion_organization_id/users/:fuzion_user_profile_id/:tab?"
            component={OgranizationUser}
          />
          <Route
            path="/organizations/:fuzion_organization_id/:tab?"
            component={Organization}
          />
        </Switch>
      </span>
    );
  }
}

export default withAuth(Organizations);
