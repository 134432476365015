import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { connect } from "react-redux";

import Apis from "./apis";
import Settings from "./settings";
import Applications from "./applications";
import Header from "components/header/header";
import { getCategory } from "actions/categories";

class AppCategoryDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: this.tabs.settings //default tab
    };
  }

  tabs = {
    apis: 0,
    settings: 1,
    applications: 2
  };

  indexes = ["apis", "settings", "applications"];

  selectTab = () => {
    const { tab } = this.props.match.params;
    if (tab) {
      const index = this.tabs[tab];
      if (index !== this.state.index) {
        this.setState({ index: index });
      }
    }
  };

  componentDidMount() {
    this.selectTab();
    let { fuzion_app_category_id } = this.props.match.params;
    if (fuzion_app_category_id) {
      this.props.getCategory(fuzion_app_category_id);
    }
  }

  componentDidUpdate = this.selectTab;

  changeTab = index => {
    let { fuzion_app_category_id } = this.props.match.params;
    const target = `/admin-app-category/${fuzion_app_category_id}/${this.indexes[index]}`;
    this.props.history.push(target);
  };

  render() {
    const tabListClass = `nav nav-tabs admin-tab-subheader ${this.props
      .sidebar && "sidebar-spacer"}`;
    return (
      <>
        <Header detailView={true} to="/admin" />
        <div>
          <Tabs selectedIndex={this.state.index} onSelect={this.changeTab}>
            <TabList className={tabListClass}>
              <div className="tab-inner-container">
                <Tab className="tab">APIs</Tab>
                <Tab className="tab">SETTINGS</Tab>
                <Tab className="tab">APPLICATIONS</Tab>
              </div>
            </TabList>
            <TabPanel>
              <Apis />
            </TabPanel>
            <TabPanel>
              {this.props.category &&
                Object.keys(this.props.category).length > 0 && (
                  <Settings key={this.props.category.fuzion_app_category_id} />
                )}
            </TabPanel>
            <TabPanel>
              <Applications />
            </TabPanel>
          </Tabs>
        </div>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    sidebar: state.sidebar,
    category: state.category
  };
};

export default connect(mapStateToProps, {
  getCategory
})(AppCategoryDetails);
